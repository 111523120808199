import { IonButton, IonButtons, IonFooter, IonGrid, IonIcon, IonImg, IonProgressBar, IonRow, IonSkeletonText, IonTitle, IonToolbar } from '@ionic/react';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { closeOutline, cloudOfflineOutline, cloudUploadOutline } from 'ionicons/icons';
import React, { createRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import LoremIpsum from '../../../assets/icons/LoremIpsum.svg';
import SerachImage from '../../../assets/icons/SearchImage.svg';
import freelinedrawn from '../../../assets/icons/drawingpencil.svg';
import voiceOn from '../../../assets/icons/speechdictionary.svg';
import { auth, storage } from '../../../firebaseConfig';
import { ttsServices } from '../../tts/store/services';
import { getTTS } from '../common/ttsServices';
import Dictionary from './Dictionary';
import './WordActions.css';
import trashIcon from '../../../assets/icons/trashBin.svg'

type Props = {
    selectedText: string | null,
    history: any,
    images: any[],
    drawingUrl: string | null,
    isLoadingImages: boolean,
    textForLn: string | null,
    onDictionaryClicked?: (text: string | null) => void,
    onHighlightClicked?: (text: string | null) => void,
    uploadImageClicked: (text: string | null) => void,
    deleteCustomImage: (url: string, text: string,) => void,
    removeDeletedDrawing: (url: string, text: string) => void,
    /* onSketchClicked?: (word: SentenceWord) => void, */
    showDrawImage?: boolean,
    customImageUrl: string | null
};

type State = {
    imageSearchResponse: any | undefined,
    wordPicture: string | null,
    ttsButtonDisabled: boolean,
    imageTab: boolean,
    dictionaryTab: boolean,
    customImageUrl: string | null,
};


class WordActions extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            imageSearchResponse: undefined,
            wordPicture: null,
            ttsButtonDisabled: false,
            imageTab: true,
            dictionaryTab: false,
            customImageUrl: null

        }
    }
    dictionaryRef = createRef()

    /*   componentDidMount() {
          let _this = this
  
          document.addEventListener("mouseup", function aaa(event) {
              let target = event.target as HTMLElement;
              if (window.getSelection() && target.className === "fileViewerParagraph")
                  _this.searchImages()
          }, false);
          document.addEventListener('dblclick', (event) => {
              let target = event.target as HTMLElement;
              if (window.getSelection() && target.className === "fileViewerParagraph")
                  _this.searchImages()
          }, false); 
  
          //TODO DESTROY EVENT LISTENER ON UNMOUNT
      }
   */

    /* async componentDidUpdate(prev: Props) {
        if ((prev.selectedText !== this.props.selectedText) && this.props.selectedText) {
            this.setState({
                imageSearchResponse: [],
                isLoadingImages: true,
                wordPicture: '',
            });

            const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", this.props.selectedText.trim()));
            imagesSearch(this.props.selectedText)!
                .then(images => {
                    this.setState({ imageSearchResponse: images }, async () => {
                        const querySnapshot = await getDocs(q);
                        console.log("[WordActions] query snapshot: ", querySnapshot);

                        if (!querySnapshot.empty)
                            querySnapshot.forEach((doc) => {
                                console.log("[WordActions] snapshot: ", doc.data());

                                this.state.imageSearchResponse.push(
                                    this.fromGSUrltoUrl(doc.data().p),
                                )
                            })
                        else {
                            this.setState({ isLoadingImages: false });
                        }
                    });
                })
                .catch(err => {
                    console.error("[WordActions] error calling imageSearch:", err);
                });
        }
    } */

    fromGSUrltoUrl(gsUrl: string) {
        return new Promise<string>((resolve, reject) => {
            getDownloadURL(storageRef(storage, gsUrl))
                .then(url => {
                    this.setState({ wordPicture: url })
                    resolve(url);
                })
                .catch(err => {
                    //console.log('[fromGSUrltoUrl] service error:', err);
                    reject(err);
                })
        });
    }
    componentDidMount(): void {
        let drawingUrl = this.props.drawingUrl;
        this.setState({ wordPicture: drawingUrl, customImageUrl: this.props.customImageUrl })
    }


    eraseDictionaryMeanings() {
        (this.dictionaryRef.current as any).eraseDictionaryMeanings()
    }



    render() {
        return (

            <div style={{ width: "100%" }}>
                {!isMobile &&
                    <div style={{ width: '100%' }}>

                        <IonGrid >

                            <IonRow>
                                <Dictionary
                                    ref={this.dictionaryRef as React.LegacyRef<Dictionary>}

                                    text={this.props.selectedText}
                                />
                            </IonRow>
                            <IonRow>
                                {
                                    (this.props.images /*  && (this.state.imageSearchResponse.hits.length > 0) */) &&
                                    <div className='wordActionImagesRow' >
                                        {
                                            this.props.isLoadingImages &&
                                            <>
                                                <IonSkeletonText animated />
                                            </>
                                        }

                                        {/* {
                                            this.state.wordPicture && !this.props.isLoadingImages &&
                                            <>
                                                {
                                                    this.state.wordPicture &&
                                                    <IonImg
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: '/sketch',
                                                                state: { word: this.props.selectedText?.trim(), drawing: this.state.wordPicture, }
                                                            })
                                                        }}
                                                        key={this.state.wordPicture}
                                                        className='wordActionsImage'
                                                        src={this.state.wordPicture}
                                                    />
                                                }
                                            </>
                                        } */}
                                        {
                                            this.props.customImageUrl &&
                                            <div className="imageContainer">
                                                <IonImg
                                                    onClick={() => {
                                                        // console.log("images: ", this.props.images)
                                                        // console.log(image)
                                                        // if (image.includes('firebasestorage'))
                                                        //     this.props.history.push({
                                                        //         pathname: '/sketch',
                                                        //         state: { word: this.props.selectedText?.trim(), drawing: image, }
                                                        //     })

                                                    }}
                                                    className='wordActionsImage'
                                                    // src={`data:image/png;base64,${image}`}
                                                    src={this.props.customImageUrl}
                                                //alt={hit.tags}
                                                />
                                            </div>
                                        }

                                        {
                                            Array.isArray(this.props.images) && this.props.images.length > 0 && !this.props.isLoadingImages && this.props.images.map((image: string, key: number) => {
                                                console.log(key)
                                                return (
                                                    <div className={image.includes('firebasestorage') ? 'imageContainer drawCursor' : 'imageContainer'}>
                                                        <IonImg
                                                            key={key}
                                                            onClick={() => {
                                                                // console.log("images: ", this.props.images)
                                                                // console.log(image)
                                                                if (image.includes('firebasestorage'))
                                                                    this.props.history.push({
                                                                        pathname: '/sketch',
                                                                        state: { word: this.props.selectedText?.trim(), drawing: image, }
                                                                    })
                                                            }}
                                                            className='wordActionsImage'
                                                            // src={`data:image/png;base64,${image}`}
                                                            src={image}
                                                        //alt={hit.tags}
                                                        />
                                                        {image.includes('firebasestorage') &&
                                                            <IonIcon
                                                                className='deleteImageIcon'
                                                                icon={closeOutline}
                                                                onMouseDown={(e) => {
                                                                    this.props.removeDeletedDrawing(image, this.props.selectedText!.trim())
                                                                    //delete image from db and storage
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                )
                                            })
                                        }



                                        {
                                            this.props.images.length === 0 && !this.props.isLoadingImages &&
                                            <>
                                                <p><Trans>Non ci sono immagini</Trans></p> {/* TO LOCALIZE THIS */}
                                                <IonButton
                                                    fill='clear'

                                                    onClick={() => this.props.history.push({
                                                        pathname: '/sketch',
                                                        state: { word: this.props.selectedText?.trim(), drawing: this.state.wordPicture }
                                                    })}
                                                    style={{ textTransform: 'none' }}><Trans>Disegnane una</Trans></IonButton>
                                            </>
                                        }
                                    </div>
                                }
                            </IonRow>
                            {
                                this.props.selectedText && !this.props.isLoadingImages &&
                                ((this.props.showDrawImage === undefined) || (this.props.showDrawImage === true)) &&
                                <IonRow>
                                    <IonButton
                                        className='wordActionsActionButton'
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname: '/sketch',
                                                state: { word: this.props.selectedText?.trim(), drawing: this.props.drawingUrl }
                                            })
                                        }
                                        }
                                    >
                                        <IonIcon
                                            className='invert'
                                            icon={freelinedrawn}
                                            slot='icon-only'
                                        />
                                    </IonButton>
                                    <IonButton
                                        className='wordActionsActionButton'
                                        onClick={() => {
                                            if (this.props.selectedText && !this.props.customImageUrl)
                                                this.props.uploadImageClicked(this.props.selectedText?.trim())
                                            else if (this.props.selectedText && this.props.customImageUrl)
                                                this.props.deleteCustomImage(this.props.customImageUrl, this.props.selectedText)
                                        }}
                                    >
                                        <IonIcon
                                            className='invert'
                                            icon={this.props.customImageUrl ? cloudOfflineOutline : cloudUploadOutline}
                                            slot='icon-only'
                                        />
                                    </IonButton>


                                </IonRow>
                            }
                        </IonGrid>
                    </div>}

                {isMobile &&
                    <div>
                        <IonGrid className={!isMobile ? 'spostamento' : ''}>
                            <IonRow>
                                <p className='dictionaryWord' style={{ fontSize: '25px', userSelect: "none" }}>
                                    {this.props.selectedText ? <b style={{}}>{this.props.selectedText.toUpperCase()}</b> : ""}
                                </p>
                            </IonRow>
                            <IonRow style={{ heigth: '50%', minWidth: '150px', scrollX: 'true', overFlowX: 'hidden' }} >
                                {this.state.imageTab &&
                                    <>

                                        {
                                            (this.props.images || this.props.customImageUrl /*  && (this.state.imageSearchResponse.hits.length > 0) */) &&
                                            <div className='wordActionImagesRow' style={{ height: this.props.isLoadingImages || this.props.images.length === 0 ? '100px' : '', flexDirection: this.props.images.length === 0 && !this.props.isLoadingImages ? 'column' : 'row' }}>
                                                {
                                                    this.props.isLoadingImages &&
                                                    <IonProgressBar type="indeterminate"></IonProgressBar>
                                                }
                                                {
                                                    this.props.customImageUrl &&
                                                    <div className='imageContainer'>

                                                        <IonImg
                                                            onClick={() => {
                                                                // console.log("images: ", this.props.images)
                                                                // console.log(image)
                                                                // if (image.includes('firebasestorage'))
                                                                //     this.props.history.push({
                                                                //         pathname: '/sketch',
                                                                //         state: { word: this.props.selectedText?.trim(), drawing: image, }
                                                                //     })

                                                            }}
                                                            className='wordActionsImage'
                                                            // src={`data:image/png;base64,${image}`}
                                                            src={this.props.customImageUrl}
                                                        //alt={hit.tags}
                                                        /></div>
                                                }
                                                {
                                                    this.state.wordPicture && !this.props.isLoadingImages &&
                                                    <>
                                                        {
                                                            this.state.wordPicture &&
                                                            <IonImg
                                                                onClick={() => this.props.history.push({
                                                                    pathname: '/sketch',
                                                                    state: { word: this.props.selectedText?.trim(), drawing: this.state.wordPicture, }
                                                                })}
                                                                key={this.state.wordPicture}
                                                                className='wordActionsImage'
                                                                src={this.state.wordPicture}
                                                            />
                                                        }
                                                    </>
                                                }
                                                {
                                                    this.props.images.length > 0 && !this.props.isLoadingImages &&
                                                    /* this.state.imageSearchResponse.hits.length > 0 && */
                                                    <>
                                                        {
                                                            this.props.images.map((image: string, key: number) => {
                                                                return (
                                                                    <div key={key} className={image.includes('firebasestorage') ? 'imageContainer drawCursor' : 'imageContainer'}>
                                                                        <IonImg

                                                                            key={key}
                                                                            onClick={() => {
                                                                                console.log("images: ", this.props.images)
                                                                                if (image.includes('firebasestorage'))
                                                                                    this.props.history.push({
                                                                                        pathname: '/sketch',
                                                                                        state: { word: this.props.selectedText?.trim(), drawing: image, }
                                                                                    })
                                                                            }}
                                                                            className='wordActionsImage'
                                                                            // src={`data:image/png;base64,${image}`}
                                                                            src={image}
                                                                        //alt={hit.tags}
                                                                        />
                                                                        {image.includes('firebasestorage') &&
                                                                            <IonIcon
                                                                                className='deleteImageIcon'
                                                                                icon={closeOutline}
                                                                                onClick={() => {
                                                                                    this.props.removeDeletedDrawing(image, this.props.selectedText!.trim())
                                                                                    //delete image from db and storage
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </>
                                                }
                                                {
                                                    this.props.images.length === 0 && !this.props.isLoadingImages &&
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <p><Trans>Non ci sono immagini</Trans></p> {/* TO LOCALIZE THIS */}
                                                        <IonButton
                                                            fill='clear'

                                                            onClick={() => this.props.history.push({
                                                                pathname: '/sketch',
                                                                state: { word: this.props.selectedText?.trim(), drawing: this.props.drawingUrl }
                                                            })}
                                                            style={{ textTransform: 'none' }}><Trans>Disegnane una</Trans></IonButton>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                                {this.state.dictionaryTab &&
                                    <div style={{ height: '100%', width: '100%', overflowX: 'hidden' }}>
                                        <Dictionary
                                            text={this.props.selectedText}
                                        />
                                    </div>
                                }
                            </IonRow>

                            <IonFooter>
                                <IonRow>
                                    <IonToolbar>
                                        <IonTitle style={{ justifyContent: 'space-between', minWidth: '150px' }}>
                                            <IonButtons style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <IonButton onClick={() => { this.setState({ imageTab: true, dictionaryTab: false }) }}>
                                                    <IonIcon icon={SerachImage} size='large'></IonIcon>
                                                </IonButton>
                                                <IonButton onClick={() => { this.setState({ imageTab: false, dictionaryTab: true }) }}>
                                                    <IonIcon icon={LoremIpsum} size='medium'></IonIcon>
                                                </IonButton>
                                                <IonButton onClick={() => {
                                                    if (this.props.selectedText) {
                                                        //DISABLE BUTTON
                                                        this.setState({ ttsButtonDisabled: true })
                                                        ttsServices.getTTSSettings(auth.currentUser!.uid)
                                                            .then((ttsSettings) => {
                                                                // console.log("TTS SETTINGS", ttsSettings);
                                                                getTTS([], this.props.selectedText!.trim(), ttsSettings)
                                                                    .then((base64Audio: any) => {
                                                                        let elemAudio = document.createElement('audio');
                                                                        document.body.appendChild(elemAudio);
                                                                        elemAudio.controls = true;
                                                                        elemAudio.src = base64Audio.audio;
                                                                        elemAudio.hidden = true;
                                                                        elemAudio.play();
                                                                        this.setState({ ttsButtonDisabled: false })
                                                                    })
                                                                    .catch((err: any) => {
                                                                        console.error("[WordActions] error getting tts:", err);
                                                                    });
                                                            })
                                                    }
                                                }}>
                                                    <IonIcon icon={voiceOn}></IonIcon>
                                                </IonButton>
                                                <IonButton
                                                    onClick={() => {
                                                        this.props.history.push({
                                                            pathname: '/sketch',
                                                            state: { word: this.props.selectedText?.trim(), drawing: this.props.images[0].includes('firebasestorage') ? this.props.images[0] : null }
                                                        })
                                                    }}

                                                >
                                                    <IonIcon icon={freelinedrawn} size='large'></IonIcon>
                                                </IonButton>

                                                <IonButton
                                                    className='wordActionsActionButton'
                                                    onClick={() => {
                                                        if (this.props.selectedText && !this.props.customImageUrl)
                                                            this.props.uploadImageClicked(this.props.selectedText?.trim())
                                                        else if (this.props.selectedText && this.props.customImageUrl)
                                                            this.props.deleteCustomImage(this.props.customImageUrl, this.props.selectedText)
                                                    }}
                                                >
                                                    <IonIcon
                                                        // className='invert'
                                                        icon={this.props.customImageUrl ? cloudOfflineOutline : cloudUploadOutline}
                                                        slot='icon-only'
                                                    />
                                                </IonButton>
                                            </IonButtons>
                                        </IonTitle>
                                    </IonToolbar>
                                </IonRow>
                            </IonFooter>
                        </IonGrid>
                    </div>
                }
            </div >
        );
    }
}

export default WordActions;