import { IonButton, IonIcon, IonPopover } from '@ionic/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { Trans } from 'react-i18next';
import { fromGSUrltoUrl } from '../../common/firebaseUtils';
import { auth, firestore } from '../../firebaseConfig';
import { DocumentElement } from '../files/store/reducers';
import { DocumentFontSettings, fromBackendFontNameToCssFontFamily } from '../settings/store/services';
import "./ClickPopover.css";
import { imagesSearch } from './common/imagesSearch';
import WordActions from './components/WordActions';

type Props = {
    history: any,
    keyWordStatus: boolean,
    isOpen: boolean,
    documentElement: DocumentElement | null,
    fontSettings: DocumentFontSettings | undefined,

    uploadImageClicked: (text: string | null) => void
    deleteCustomImage: (url: string, text: string) => void
    removeDeletedDrawing: (url: string, text: string) => void
    onDidDismiss?: () => void,
}

type State = {
    navigationStack: string[],
    imageSearchResponse: any[],
    selectedText: string | null,
    isLoadingImages: boolean,
    drawingUrl: string | null,
    customImageUrl: string | null,


};


class ClickPopover extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            navigationStack: [],
            imageSearchResponse: [],
            isLoadingImages: false,
            drawingUrl: null,
            customImageUrl: null,


            selectedText: null,
        }
    }

    navigateBack() {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.pop();
        this.setState({
            navigationStack: navStackCopy,
        });
    }
    navigateTo(path: string) {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.push(path);
        this.setState({
            navigationStack: navStackCopy
        });
    }

    dismissPopover() {
        this.setState({
            navigationStack: [],
            selectedText: null,
        });
    }
    removeDeletedImage(url: string) {
        this.setState({ customImageUrl: null })
    }
    removeDeletedDrawing(url: string) {
        let newImages = this.state.imageSearchResponse
        newImages.splice(newImages.indexOf(url), 1)
        this.setState({ drawingUrl: null, imageSearchResponse: newImages })
    }

    addCustomImage(image: string) {
        this.setState({ /* imageSearchResponse: images, */ customImageUrl: image })
    }

    colors = ["#c7eae4", "#a7e8bd", "#fcbcb8", "#efa7a7", "#ffd972"]

    render() {
        return (
            <IonPopover
                className="clickPopoverMainClass"
                mode="md"
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                        this.dismissPopover();

                    }
                }}
            >
                {
                    this.state.navigationStack.length > 0 &&
                    <IonButton
                        className='selectionPopoverNavigateBackDiv'
                        fill="clear"
                        onClick={() => {
                            this.navigateBack();
                        }}
                    >
                        <IonIcon
                            className='selectionPopoverNavigateBackIcon'
                            icon={chevronBack}
                        />
                    </IonButton>
                }

                {
                    this.state.navigationStack.length === 0 &&
                    <div
                        className="clickPopoverInnerDiv"
                        style={{ fontFamily: this.props.fontSettings ? fromBackendFontNameToCssFontFamily(this.props.fontSettings.font) : "" }}
                    >
                        <p className="clickPopoverHeaderParagraph">
                            <Trans>Seleziona una parola</Trans> {/* TO BE LOCALIZED */}
                        </p>
                        <div className="clickPopoverTextDiv">
                            {!this.props.keyWordStatus &&
                                this.props.documentElement &&
                                this.props.documentElement.text
                                    .replace(/[-,";]/g, '')
                                    .split(" ")
                                    .map((text, i) => {
                                        if (text)
                                            return (
                                                <div
                                                    key={i}
                                                    className="clickPopoverTextElement"
                                                    style={{ backgroundColor: this.colors[i % this.colors.length] }}
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedText: text,
                                                        }, () => {
                                                            if (this.state.selectedText) {
                                                                const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", this.state.selectedText.trim()));
                                                                const customImageQuery = query(collection(firestore, `i/${auth.currentUser?.uid}/i`), where("w", "==", this.state.selectedText.trim().toLowerCase()));
                                                                this.setState({ isLoadingImages: true });
                                                                imagesSearch(this.state.selectedText)
                                                                    .then(images => {
                                                                        this.setState({ imageSearchResponse: images }, async () => {
                                                                            const querySnapshot = await getDocs(q);
                                                                            const customImageQuerySnapshot = await getDocs(customImageQuery);
                                                                            console.log("[WordActions] query snapshot: ", querySnapshot);

                                                                            if (!querySnapshot.empty) {
                                                                                querySnapshot.forEach(async (doc) => {
                                                                                    console.log("[WordActions] snapshot: ", doc.data());
                                                                                    let drawing = await fromGSUrltoUrl(doc.data().p)
                                                                                    let oldImages = this.state.imageSearchResponse;
                                                                                    console.log(oldImages)
                                                                                    oldImages.unshift(drawing); //place drawing in first position
                                                                                    this.setState({ imageSearchResponse: oldImages })
                                                                                })
                                                                                this.setState({ isLoadingImages: false });
                                                                            }
                                                                            else {
                                                                                this.setState({ isLoadingImages: false });
                                                                            }
                                                                            if (!customImageQuerySnapshot.empty) {
                                                                                customImageQuerySnapshot.forEach(async (doc: any) => {
                                                                                    await fromGSUrltoUrl(doc.data().i)
                                                                                        .then((url: string) => {
                                                                                            console.log("URL: ", url);
                                                                                            // let oldImages = this.state.imageSearchResponse;
                                                                                            // oldImages.unshift(url); //place custom image in first position
                                                                                            this.setState({ /* imageSearchResponse: oldImages, */ customImageUrl: url })
                                                                                        });
                                                                                });
                                                                            }
                                                                        });
                                                                    })
                                                                    .catch(err => {
                                                                        console.error("[WordActions] error calling imageSearch:", err);
                                                                    });
                                                            }
                                                            this.navigateTo("wordActions");
                                                        })
                                                    }}
                                                >
                                                    {text.trim()}
                                                </div>
                                            )
                                    })
                            }{this.props.keyWordStatus &&
                                this.props.documentElement &&
                                (this.props.documentElement.keyWord || "")
                                    .replace(/[-";]/g, '')
                                    .split(",")
                                    .map((text, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="clickPopoverTextElement"
                                                style={{ backgroundColor: this.colors[i % this.colors.length] }}
                                                onClick={() => {
                                                    this.setState({
                                                        selectedText: text,
                                                    }, () => {
                                                        if (this.state.selectedText) {
                                                            const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", this.state.selectedText.trim()));
                                                            const customImageQuery = query(collection(firestore, `i/${auth.currentUser?.uid}/i`), where("w", "==", this.state.selectedText.trim().toLowerCase()));
                                                            this.setState({ isLoadingImages: true });
                                                            imagesSearch(this.state.selectedText)
                                                                .then(images => {
                                                                    this.setState({ imageSearchResponse: images }, async () => {
                                                                        const querySnapshot = await getDocs(q);
                                                                        const customImageQuerySnapshot = await getDocs(customImageQuery);
                                                                        console.log("[WordActions] query snapshot: ", querySnapshot);

                                                                        if (!querySnapshot.empty) {
                                                                            querySnapshot.forEach(async (doc) => {
                                                                                console.log("[WordActions] snapshot: ", doc.data());
                                                                                let drawing = await fromGSUrltoUrl(doc.data().p)
                                                                                let oldImages = this.state.imageSearchResponse;
                                                                                console.log(oldImages)
                                                                                oldImages.unshift(drawing); //place drawing in first position
                                                                                this.setState({ imageSearchResponse: oldImages })
                                                                            })
                                                                            this.setState({ isLoadingImages: false });
                                                                        }
                                                                        else {
                                                                            this.setState({ isLoadingImages: false });
                                                                        }
                                                                        if (!customImageQuerySnapshot.empty) {
                                                                            customImageQuerySnapshot.forEach(async (doc: any) => {
                                                                                await fromGSUrltoUrl(doc.data().i)
                                                                                    .then((url: string) => {
                                                                                        console.log("URL: ", url);
                                                                                        // let oldImages = this.state.imageSearchResponse;
                                                                                        // oldImages.unshift(url); //place custom image in first position
                                                                                        this.setState({ /* imageSearchResponse: oldImages, */ customImageUrl: url })
                                                                                    });
                                                                            });
                                                                        }
                                                                    });
                                                                })
                                                                .catch(err => {
                                                                    console.error("[WordActions] error calling imageSearch:", err);
                                                                });
                                                        }
                                                        this.navigateTo("wordActions");
                                                    })
                                                }}
                                            >
                                                {text.trim()}
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                }
                {
                    (this.state.navigationStack.length > 0) &&
                    this.state.navigationStack[this.state.navigationStack.length - 1] === "wordActions" &&
                    this.state.selectedText &&
                    <div className="clickPopoverWordActionsDiv">
                        <WordActions
                            isLoadingImages={this.state.isLoadingImages}
                            history={this.props.history}
                            selectedText={this.state.selectedText}
                            images={this.state.imageSearchResponse}
                            customImageUrl={this.state.customImageUrl}
                            drawingUrl={this.state.drawingUrl}
                            deleteCustomImage={(url: string, text: string) => {
                                this.props.deleteCustomImage(url, text)
                            }}
                            uploadImageClicked={(text: string | null) => {
                                this.props.uploadImageClicked(text)

                            }}
                            removeDeletedDrawing={(url, text) => {
                                this.props.removeDeletedDrawing(url, text)
                            }}
                            textForLn={""}
                            showDrawImage={true}
                            onDictionaryClicked={() => {
                                this.navigateTo("wordDictionary");
                            }}
                            onHighlightClicked={() => {
                                this.navigateTo("lettersSelector");
                            }}
                        />
                    </div>
                }
            </IonPopover >
        );
    }
}

export default ClickPopover;                   
