import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { deleteDirectoryContents, makeId } from "../../files/store/services";
import { generateErrorToast } from "../../files/utils/utils";
import { firestore, storage } from "../../../firebaseConfig";
import { store } from "../../../common/store";
import { sendEvent } from "../../../common/amplitudeUtils";
import i18n from "../../../i18n";
import { appVersion } from "../../../appVersion";
import { isPlatform } from "@ionic/react";
import { Argument, Resource } from "./reducers";
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { filesServices } from "../../files/store/services";
import { getTTS } from "../../popover/common/ttsServices";
import { ttsServices } from "../../tts/store/services";
import imageCompression from "browser-image-compression";

export const argumentServices = {
	getSessionArguments,
	uploadFile,
	createArgumentFromString,
	getUserArguments,
};
let timeoutId: any;
let counter: number = 0;

interface LoadingStates {
	isLoadingAudiobook?: boolean;
	isLoadingMap?: boolean;
	isLoadingSummary?: boolean;
	isLoadingKeywords?: boolean;
	isLoadingFlashcards?: boolean;
}
const wsUrl = "wss://5yjzrkduyh.execute-api.eu-central-1.amazonaws.com/testing/"; //for testing
// const wsUrl = 'wss://m4z1pdns93.execute-api.eu-central-1.amazonaws.com/production/'; //for production
const promisesMap = new Map<string, { resolve: Function; reject: Function }>();
let results: any[] = [];
let heartbeatInterval: NodeJS.Timeout;
let finalMessageSent = false;
let dataSets: DataSet[] = [];

// New variables to track task completion
let tasksCompleted = {
	map: false,
	summary: false,
	keywords: false,
	tts: false,
	flashcards: false,
};
const messageQueue: MessageEvent[] = [];
let processing = false;

function createArgumentFromString(this: any, userArgument: string, userUuid: string, token: string, specificResource?: string, existingArgument?: Argument) {
	return new Promise<any>(async (resolve, reject) => {
		let fromArgument_data = await getSessionArguments.call(
			this,
			"",
			token,
			userUuid,
			existingArgument ? existingArgument.title : userArgument,
			userArgument,
			specificResource,
			existingArgument
		);
		let argument: Argument = {
			uuid: "",
			title: "",
			created_at: 0,
			resources: [],
		};
		// console.log("RESULTS MAP", fromArgument_data);
		if (fromArgument_data) {
			let data = JSON.parse(fromArgument_data[0].docID);
			argument = {
				uuid: data.uuid,
				title: data.title,
				created_at: data.created_at,
				resources: data.resources.map((resource: any) => ({
					uuid: resource.uuid,
					type: resource.type,
					is_present: resource.is_present,
				})),
			};
		}
		// console.log(argument);
		if (argument) {
			results = []
			sendEvent({
				"user_id": this.state.userUuid,
				"event_type": "Argument generated from string",
				"event_properties": {
					"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
					// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
					"resources": argument.resources,
					"argument_title": argument.title,
				},
				"language": i18n.language,
				"app_version": appVersion,
				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				"time": Date.now(),
			});
			resolve(argument);
		} else resolve(false);
	});
}

const compressFile = async (file: File) => {
	try {
		// Check the file type
		const fileType = file instanceof File ? file.type : "";

		// Only compress if the file is an image
		if (fileType.startsWith("image/")) {
			const options = {
				maxSizeMB: 1, // Maximum size in MB
				maxWidthOrHeight: 1920, // Maximum width or height
			};

			const compressedFile = await imageCompression(file, options);
			return compressedFile;
		} else {
			return file; // Return the original file if it's not an image
		}
	} catch (error) {
		console.error("Compression error:", error);
		return file; // If compression fails, return the original file
	}
};

function uploadFile(
	this: any,
	file: File | Blob,
	userUuid: string,
	token: string,
	chooserFileName?: string,
	specificResource?: string,
	existingArgument?: Argument
) {
	return new Promise(async (resolve, reject) => {
		let fileName: string | "" = "";
		let fileToUpload: File | Blob = file;
		if (file instanceof File) {
			// console.log("FILE NAME", file.name); // TO REMOVE
			// console.log("extension:", (file as File).name.split(".").pop()); // TO REMOVE
			fileToUpload = await compressFile(file);
			fileName = file.name.trim();
		} else if (file instanceof Blob && chooserFileName) {
			fileName = chooserFileName;
		}
		let filePath: string;
		filePath = `tf/${userUuid}/file_for_maps/${makeId(16)}${fileName ? `/${fileName}` : ""}`;
		// filePath = `f/${userUuid}/${makeId(16)}${fileName ? `/${fileName}` : ""}`
		// console.log("[FILE PATHS]", filePath);

		const storageRef = ref(storage, filePath);
		// const metadata = {
		//     customMetadata: {
		//         'actionType': actionType, // custom metadata
		//     },
		// };
		uploadBytes(storageRef, fileToUpload)
			.then(async (data) => {
				console.log("[File services] upload success:", data.ref);
				sendEvent({
					"user_id": userUuid,
					"event_type": "Argument Document Uploaded",
					"event_properties": {
						"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
						// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
						"document_name": fileName,
					},
					"language": i18n.language,
					"app_version": appVersion,
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"time": Date.now(),
				});
				const fileRef = ref(storage, filePath);
				const url = await getDownloadURL(fileRef);
				let fromPDF_data;
				if (specificResource && existingArgument)
					fromPDF_data = await getSessionArguments.call(this, url, token, userUuid, existingArgument.title, undefined, specificResource, existingArgument);
				else fromPDF_data = await getSessionArguments.call(this, url, token, userUuid, fileName);

				let argument: Argument = {
					uuid: "",
					title: "",
					created_at: 0,
					resources: [],
				};
				if (fromPDF_data) {
					let data = JSON.parse(fromPDF_data[0].docID);
					argument = {
						uuid: data.uuid,
						title: data.title,
						created_at: data.created_at,
						resources: data.resources.map((resource: any) => ({
							uuid: resource.uuid,
							type: resource.type,
							is_present: resource.is_present,
						})),
					};
					deleteDirectoryContents(`tf/${userUuid}/file_for_maps/`);
				}
				console.log("ARG RETURNED FROM UPLOAD: ", argument);

				// console.log(argument);
				if (argument) resolve(argument);
				else resolve(false);
				results = []

				/* fromGSUrltoUrl(data.ref.fullPath)
					.then(url => {
						this.setState({ imgUrl: url })
					})
					.catch(err => {
						console.error("[File services] error getting public url:");
					    
					}) */
			})
			.catch((err) => {
				console.log("[Files services] error uploading file", err);
				reject(err);
			});
	});
}
function openWebSocket(ws: WebSocket, message: any, userUuid: string, promisesMap: Map<string, { resolve: Function; reject: Function }>): void {
	const messageID = message.data.docID;

	promisesMap.set(messageID, {
		resolve: (data: any) => { },
		reject: (error: Error) => { },
	});

	ws.send(JSON.stringify(message));
}

function getSessionArguments(
	this: any,
	url: string,
	token: string,
	userUuid: string,
	fileName: string,
	userArgument?: string,
	specificResource?: string,
	existingArgument?: Argument
): Promise<any[]> {
	// const wsUrl = //'wss://5yjzrkduyh.execute-api.eu-central-1.amazonaws.com/production/'//for testing
	// 'wss://m4z1pdns93.execute-api.eu-central-1.amazonaws.com/production/';
	// console.log("AM I HERE? ", specificResource, existingArgument);
	let ttsSettings = {
		prosody_rate: this.state.ttsSettings.prosodyRate,
		voice_gender: this.state.ttsSettings.voiceType,
	};
	let argID = specificResource && existingArgument ? existingArgument.uuid : generateRandomCode("argument", 19);
	let messages: any[] = [];
	if (!specificResource) {
		messages = [
			{
				action: "startGenerationSummary",
				token: `Bearer ${token}`,
				data: {
					pdf_url: url,
					userID: userUuid,
					file_name: fileName,
					docID: generateRandomCode("summary", 19),
					userArgument: userArgument,
					ttsSettings: ttsSettings,
					lang: i18n.language,
				},
			},
			{
				action: "startGenerationKeywords",
				token: `Bearer ${token}`,
				data: { pdf_url: url, userID: userUuid, file_name: fileName, docID: generateRandomCode("keywords", 19), userArgument: userArgument, lang: i18n.language },
			},
			{
				action: "startGenerationMap",
				token: `Bearer ${token}`,
				data: { pdf_url: url, userID: userUuid, file_name: fileName, docID: generateRandomCode("maps", 19), userArgument: userArgument, lang: i18n.language },
			},
			{
				action: "startGenerationFlashCard",
				token: `Bearer ${token}`,
				data: { pdf_url: url, userID: userUuid, file_name: fileName, docID: generateRandomCode("flashcards", 19), userArgument: userArgument, lang: i18n.language },
			},
		];
	} else {
		if (specificResource === "audio") {
			let finalText: string = "";
			const documentDataQuerySummary = query(collection(firestore, `s/${this.state.summarydocID}/s`), where("d", "==", this.state.summarydocID));
			onSnapshot(documentDataQuerySummary, async (querySnapshot) => {
				try {
					let summaryDocumentElements = filesServices.fromDocumentDataToDocumentElements(querySnapshot);
					finalText = summaryDocumentElements.map((element) => element.text).join(" ");
					console.log(finalText)
					messages = [
						{
							action: "startGenerationAudio",
							token: `Bearer ${token}`,
							data: { text: finalText, ttsSettings: ttsSettings, userID: userUuid, file_name: fileName, lang: i18n.language },
						},
					];
				} catch (error) {
					console.error("Error:", error);
				}
			});
		} else
			messages = [
				{
					action:
						specificResource === "map"
							? "startGenerationMap"
							: specificResource === "keywords"
								? "startGenerationKeywords"
								: specificResource === "summary"
									? "startGenerationSummary"
									: specificResource === "flashCards"
										? "startGenerationFlashCard"
										: "",
					token: `Bearer ${token}`,
					data: {
						pdf_url: url,
						userID: userUuid,
						file_name: fileName,
						docID: generateRandomCode(
							specificResource === "map"
								? "maps"
								: specificResource === "keywords"
									? "keywords"
									: specificResource === "summary"
										? "summary"
										: specificResource === "flashCards"
											? "flashcards"
											: "",
							19
						),
						userArgument: userArgument,
						ttsSettings: ttsSettings,
						onlySummary: this.state.missingAudio ? false : true,
						lang: i18n.language
					},
				},
			];
	}

	return new Promise((resolve, reject) => {
		const ws = new WebSocket(wsUrl);
		ws.onopen = function () {
			counter = 0;
			console.log("Connection open");
			messages.forEach((message) => openWebSocket(ws, message, userUuid, promisesMap));
		};

		ws.onmessage = (event: any) => {
			onCreationResponse.call(this, ws, event, fileName, token, userUuid, argID, specificResource, existingArgument)
		};



		ws.onerror = (event: any) => {
			console.log("Error from server:", event);
			this.setState({
				isLoadingAudiobook: false,
				isLoadingKeywords: false,
				isLoadingMap: false,
				isLoadingSummary: false,
			});
			promisesMap.forEach((handlers, messageID) => {
				handlers.reject(new Error("WebSocket error"));
			});
			promisesMap.clear();
			clearInterval(heartbeatInterval);
			reject(new Error("WebSocket error"));
		};

		ws.onclose = (event) => {
			console.log(`WebSocket connection closed`);
			// console.log("WebSocket closed. Code:", event.code, "Reason:", event.reason);
			if (promisesMap.size === 0 && finalMessageSent) {
				finalMessageSent = false
				dataSets = []
				// console.log(results);
				resolve(results);
			} else {
				reject(new Error("WebSocket connection closed prematurely"));
			}
			this.setState({
				isLoadingAudiobook: false,
				isLoadingKeywords: false,
				isLoadingMap: false,
				isLoadingSummary: false,
				isLoadingFlashcards: false,
			});
			clearInterval(heartbeatInterval);
		};
	});
}
async function processNextMessage(this: any) {
	if (messageQueue.length === 0) {
		processing = false;
		return;
	}

	processing = true;
	const message = messageQueue.shift();
	onDeleteMessage.call(this, message);
	processNextMessage.call(this); // Process the next message
}
function onCreationResponse(this: any, ws: WebSocket, event: any, fileName: string, token: string, userUuid: string, argID: string, specificResource?: string, existingArgument?: Argument) {
	const data = JSON.parse(event.data);
	const messageID = data.docID;
	const promiseHandlers = promisesMap.get(messageID);
	// function checkAllTasksComplete() {
	// 	if (Object.values(tasksCompleted).every((val) => val)) {
	// 		sendFinalMessage();
	// 	}
	// }
	if (data.message === "Keywords generation started") {
		console.log("generating keywords");
	} else if (data.message === "Summary generation started") {
		console.log("generating summary");
	} else if (data.message === "Maps generation started") {
		console.log("generating map");
	} else if (data.message === "Flashcards generation started") {
		console.log("generating flashcards");
	} else if (data.message === "Audio generation started") {
		console.log("generating audio");
	} else if (data.message === "Saving session started") {
		console.log("saving session");
	} else if (data.message === "Fail") {
		if (data.action === "error map") {
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			tasksCompleted.map = true;
			this.setState({ isLoadingMap: false });
			finalMessageSent = argsCounter(ws);
		} else if (data.action === "error summary") {
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			finalMessageSent = argsCounter(ws, true);
			tasksCompleted.summary = true;
			tasksCompleted.tts = true;
			this.setState({ isLoadingSummary: false, isLoadingAudiobook: false });
		} else if (data.action === "error keywords") {
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			tasksCompleted.keywords = true;
			this.setState({ isLoadingKeywords: false });
			finalMessageSent = argsCounter(ws);
		} else if (data.action === "error creating flash cards") {
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			tasksCompleted.flashcards = true;
			this.setState({ isLoadingFlashcards: false });
			finalMessageSent = argsCounter(ws);
		} else if (data.action === "error creating audio") {
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			tasksCompleted.tts = true;
			this.setState({ isLoadingAudiobook: false });
			finalMessageSent = argsCounter(ws);
		}
	} else if (data.action) {
		if (data.action === "done") {
			if (data.docID.includes("KEYWORDSPDF")) {
				tasksCompleted.keywords = true;
				// _this.setState({ isLoadingKeywords: false });
			} else {
				tasksCompleted.map = true;
				// _this.setState({ isLoadingMap: false });
			}
			dataSets.push({
				fileName: fileName,
				type: data.docID.includes("KEYWORDSPDF") ? "keywords" : "map",
				docID: data.docID,
			});
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			sendFinalMessage();
		} else if (data.action === "cards created") {
			tasksCompleted.flashcards = true;
			dataSets.push({
				fileName: fileName,
				type: "flashCards",
				docID: data.docID,
			});
			promiseHandlers?.resolve(data);
			promisesMap.delete(messageID);
			sendFinalMessage();
		} else if (data.action === "done summary") {
			tasksCompleted.summary = true;
			// _this.setState({ isLoadingSummary: false });
			dataSets.push({
				fileName: fileName,
				type: "summary",
				docID: data.docID,
			});
			// console.log("SUMMARY DATA", data);
			promiseHandlers?.resolve(data);
			// console.log("IS THIS THE WRONG MESSAGEID", messageID);
			promisesMap.delete(messageID);
			sendFinalMessage();
		} else if (data.action === "done audio") {
			tasksCompleted.tts = true;
			// _this.setState({ isLoadingSummary: false });
			console.log("AUDIO DONE", dataSets)
			dataSets.push({
				fileName: fileName,
				type: "audio",
				docID: data.docID,
			});
			console.log("AUDIO DONE DATA SETS AFTER", dataSets)

			// console.log("SUMMARY DATA", data);
			promiseHandlers?.resolve(data);
			// console.log("IS THIS THE WRONG MESSAGEID", messageID);
			promisesMap.delete(messageID);
			sendFinalMessage();
		} else if (data.action === "data saved") {
			console.log("data saved");
			results = [data];
			clearTimeout(timeoutId); // Clear the previous timeout

			let dataArg = JSON.parse(results[0].docID);
			console.log("DATA ARG: ", dataArg);
			if (existingArgument)
				this.watchForResources(this.state.userUuid, dataArg.uuid)

			// Helper function to determine loading states
			const getLoadingState = (type: string) => {
				switch (type) {
					case "audio":
						return { isLoadingAudiobook: false };
					case "map":
						return { isLoadingMap: false };
					case "summary":
						return { isLoadingSummary: false };
					case "keywords":
						return { isLoadingKeywords: false };
					case "flashCards":
						return { isLoadingFlashcards: false };
					default:
						return {};
				}
			};

			// Set loading states
			const newLoadingStates = dataArg.resources.reduce((acc: LoadingStates, res: Resource) => {
				return { ...acc, ...getLoadingState(res.type) };
			}, {});

			this.setState(newLoadingStates);

			let argument = {
				uuid: dataArg.uuid,
				title: dataArg.title,
				created_at: dataArg.created_at,
				resources: dataArg.resources.map((resource: Resource) => ({
					uuid: resource.uuid,
					type: resource.type,
					is_present: resource.is_present,
				})),
			};

			this.setState({ generatedArgument: argument });
			if (specificResource) {
				if (this.state.missingAudio && this.state.missingSummary)
					dataArg.resources.forEach((el: Resource) => {
						specificResource = el.type === "audio" ? "audio" : el.type === "summary" ? "summary" : specificResource
					})
				let missingRes = this.state.missingResources;
				missingRes.splice(missingRes.indexOf(specificResource), 1);
				this.setState({ argument: argument, missingResources: missingRes }, () => {
					console.log("RESOURCE NAME:", specificResource)
					setLocalStorageSessionState(false, dataArg.uuid, specificResource ? specificResource : "", "creation", fileName)
				});
			}

			// Update counter and check condition
			finalMessageSent = argsCounter(ws);

		} else {
			if (existingArgument)
				setLocalStorageSessionState(false, this.state.argument!.uuid, specificResource ? specificResource : "", "creation", fileName)
			generateErrorToast.call(this);
			promiseHandlers?.resolve(data);
			// console.log(messageID);
			promisesMap.delete(messageID);
		}
	}
	function sendFinalMessage() {
		console.log("MESSAGGIO FINALE", finalMessageSent)
		if (!finalMessageSent) {
			console.log("Task completed. Sending save request.", dataSets);
			try {
				ws.send(
					JSON.stringify({
						action: "startStudySessionSaving",
						token: `Bearer ${token}`,
						data: { dataSets: dataSets, userID: userUuid, argID: argID },
					})
				);

				// finalMessageSent = true;
			} catch (error) {
				console.error("Error in ws.send:", error);
			}
		}
	}
}
export function deleteSessionElement(this: any) {
	const ws = new WebSocket("wss://pq655vc37d.execute-api.eu-central-1.amazonaws.com/production/");
	ws.onopen = (event) => {
		console.log("Connection open");
		// Send a message to the server once the connection is open
		let action = "";
		let docID = "";
		dataSets = []
		console.log(this.state.resource.type);
		switch (this.state.resource.type) {
			case "map":
				action = "deleteMap";
				docID = this.state.resource.uuid;
				break;
			case "summary":
				action = "deleteSummary";
				docID = this.state.resource.uuid;
				break;
			case "keywords":
				action = "deleteKeywords";
				docID = this.state.resource.uuid;
				break;
			case "flashCards":
				action = "deleteFlashcards";
				docID = this.state.resource.uuid;
				break;
			case "audio":
				action = "deleteAudio";
				this.setState({ audioLink: this.state.resource.uuid });
				// const matches = resource.uuid.match(/au%2F[^%]+%2F([^?]+)/);
				// docID = matches ? matches[1] : resource.uuid;
				docID = this.state.resource.uuid;
				console.log(docID);
				break;
			default:
				action = "defaultAction";
		}

		ws.send(
			JSON.stringify({
				action: action,
				token: `Bearer ${this.state.bearerToken}`,
				data: { argID: this.state.argument?.uuid, docID: docID, userID: this.state.userUuid },
			})
		);
	};

	ws.onmessage = (event) => {
		messageQueue.push(event);
		if (!processing) {
			processNextMessage.call(this);
		}
	};
	ws.onerror = function (event) {
		let state = false;
		localStorage.setItem("mapState", JSON.stringify(state));
		console.log("Error from server:", event);
		ws.close();
		console.log("error");
	};
}
function onDeleteMessage(this: any, event: any) {
	var data = JSON.parse(event.data);
	if (data.message == "Process started") {
		console.log("processing");
	} else if (data.message == "Success") {
		console.log(data);
		console.log("Resource delete with success", this.state.argument.uuid);
		this.setState({ deleting: false });
		data.type === "map"
			? this.setState({ isLoadingMap: false })
			: data.type === "summary"
				? this.setState({ isLoadingSummary: false })
				: data.type === "keywords"
					? this.setState({ isLoadingKeywords: false })
					: data.type === "flashcards"
						? this.setState({ isLoadingFlashcards: false })
						: this.setState({ isLoadingAudiobook: false });
		this.setState((prevState: { argument: { resources: any[] }; missingResources: any }) => {
			if (prevState.argument) {
				const updatedResources = prevState.argument.resources.filter(
					(res: any) => res.type !== data.type
				);

				// Add the new resource type to missingResources if it's not already present
				const updatedMissingResources = Array.from(new Set([...prevState.missingResources, this.state.resource.type]));
				console.log("UPDATED RESOURCES:", updatedResources)
				console.log("MISSING RES:", this.state.missingResources)
				console.log("UPDATED MISSING RES:", updatedMissingResources)

				return {
					argument: {
						...prevState.argument,
						resources: updatedResources,
					},
					missingResources: updatedMissingResources,
				};
			}
			return null;
		}, () => {
			this.searchForMissingResources(this.state.argument.resources)
		});

		// this.watchForResources(this.state.userUuid!,this.props.history.location.state.argument)
		setLocalStorageSessionState(false, this.state.argument!.uuid, data.type, "")

	} else if (data.message === "Fail") {
		this.setState({ deleting: false });
		data.type === "map"
			? this.setState({ isLoadingMap: false })
			: data.type === "summary"
				? this.setState({ isLoadingSummary: false })
				: data.type === "keywords"
					? this.setState({ isLoadingKeywords: false })
					: data.type === "flashcards"
						? this.setState({ isLoadingFlashcards: false })
						: this.setState({ isLoadingAudiobook: false });
	}
}

export function restoreWsSession(this: any) {
	const wsDeletion = new WebSocket("wss://pq655vc37d.execute-api.eu-central-1.amazonaws.com/production/");
	wsDeletion.onopen = (event) => {
		console.log(event);
		// Send a message to the server oncex the connection is open
		wsDeletion.send(
			JSON.stringify({
				action: "restoreSession",
				token: `Bearer ${this.state.bearerToken}`,
				data: { userID: this.state.userUuid },
			})
		);
	};
	wsDeletion.onmessage = (event) => {
		messageQueue.push(event);
		if (!processing) {
			processNextMessage.call(this);
		}
	};
	wsDeletion.onerror = (event) => {
		console.log("Error from server:", event);
		wsDeletion.close();
	};
}
export function restoreCreationWsSession(this: any, fileName: string, token: string, userUuid: string, argID: string, specificResource: string) {
	const wsCreation = new WebSocket(wsUrl);
	wsCreation.onopen = (event) => {
		console.log(event);
		// Send a message to the server oncex the connection is open
		wsCreation.send(
			JSON.stringify({
				action: "restoreSession",
				token: `Bearer ${this.state.bearerToken}`,
				data: { userID: this.state.userUuid },
			})
		);
	};
	wsCreation.onmessage = (event) => {
		onCreationResponse.call(this, wsCreation, event, fileName, this.state.bearerToken, userUuid, argID, specificResource)
	};
	wsCreation.onerror = (event) => {
		console.log("Error from server:", event);
		wsCreation.close();
	};
}



interface DataSet {
	fileName: string;
	type: string;
	docID: string;
}

function generateRandomCode(resource: string, codeLength: number) {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let code = "";
	if (resource === "summary") code = "SUMMARYPDF";
	if (resource === "maps") code = "MAPSPDF";
	if (resource === "keywords") code = "KEYWORDSPDF";
	if (resource === "audio") code = "AUDIO";
	if (resource === "argument") code = "ARGUMENT";
	if (resource === "flashcards") code = "FLASHCARDS";

	for (let i = 0; i < codeLength; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		code += characters.charAt(randomIndex);
	}

	return code;
}

function getUserArguments(userUuid: string, specificArgumentUuid?: string) {
	return new Promise<Argument[]>(async (resolve, reject) => {
		let userArguments: Argument[] = [];
		if (userUuid) {
			if (specificArgumentUuid) {
				let docSnapshot = await getDoc(doc(firestore, `a/${specificArgumentUuid}`));
				if (docSnapshot.exists()) {
					userArguments.push({
						uuid: docSnapshot.id,
						title: docSnapshot.data().t,
						created_at: docSnapshot.data().c,
						resources: docSnapshot.data().r.map((r: any) => ({
							uuid: r.d, // access properties using interface definition
							type: r.t,
							is_present: r.p,
						})),
					});
					resolve(userArguments);
				}
			} else {
				const argumentQuery = query(collection(firestore, `a`), where("u", "==", userUuid));
				getDocs(argumentQuery).then((argumentSnapshot) => {
					if (!argumentSnapshot.empty)
						argumentSnapshot.forEach((doc) => {
							userArguments.push({
								uuid: doc.id,
								title: doc.data().t,
								created_at: doc.data().c,
								resources: doc.data().r.map((r: any) => ({
									uuid: r.d, // access properties using interface definition
									type: r.t,
									is_present: r.p,
								})),
							});
							resolve(userArguments);
						});
					else resolve([]);
				});
			}
		} else {
			reject("ERROR: [getUserArguments] No user id present");
		}
	});
}
function argsCounter(ws: WebSocket, myVar?: boolean) {
	console.log(counter)
	if (myVar) {
		counter += 2;
	} else {
		counter++;
	}
	if (counter >= 5) {
		console.log(counter);
		ws.close();
		return true;
	}
	return false;
}

export function setLocalStorageSessionState(state: boolean, argID: string, docType: any, sessionType: string, filename?: string) {
	// Retrieve the current array of states from localStorage, or initialize it as an empty array if it doesn't exist
	let session = sessionType === "creation" ? "creationSessionState" : "sessionState";
	let storedStates = localStorage.getItem(session);
	let statesArray: any = [];
	try {
		statesArray = storedStates ? JSON.parse(storedStates) : [];
		if (!Array.isArray(statesArray)) {
			statesArray = []; // Ensure statesArray is an array
		}
	} catch (e) {
		statesArray = []; // Fallback to an empty array in case of error
	}

	// Function to check if an entry matches the docType and argID
	const isMatch = (item: any, docType: string, argID: string) => {
		return item[2] === docType.toLowerCase() && item[1] === argID;
	};

	// Function to add or update the state for a given docType
	const updateStateForDocType = (docType: any) => {
		let newState = [];
		if (sessionType === "creation") {
			newState = [state, argID, docType.toLowerCase(), filename];
		} else {
			newState = [state, argID, docType.toLowerCase()];
		}

		const index = statesArray.findIndex((item: any) => isMatch(item, docType, argID));

		if (index !== -1) {
			// If the entry exists, update only the state value
			statesArray[index][0] = newState[0];
		} else {
			// If the entry does not exist, add the new state to the array of states
			statesArray.push(newState);
		}
	};
	if (docType instanceof Set) {
		docType = Array.from(docType);
	}
	// Handle single string or array of strings for docType
	if (Array.isArray(docType)) {
		docType.forEach(dt => updateStateForDocType(dt));
	} else {
		updateStateForDocType(docType);
	}

	// Store the updated array back in localStorage
	localStorage.setItem(session, JSON.stringify(statesArray));
}


export function getLocalStorageSessionState(sessionType: string, argID?: string) {
	// Retrieve the array of states from localStorage
	let docTypes = ["map", "summary", "keywords", "audio", "flashcards"];
	let session = sessionType === "creation" ? "creationSessionState" : "sessionState";
	let storedStates = localStorage.getItem(session);
	let statesArray;

	try {
		statesArray = storedStates ? JSON.parse(storedStates) : [];
		if (!Array.isArray(statesArray)) {
			statesArray = []; // Ensure statesArray is an array
		}
	} catch (e) {
		statesArray = []; // Fallback to an empty array in case of error
	}

	// Array to hold matching states
	let matchingStates = [];

	// Check for true state with the specified argID
	for (let state of statesArray) {
		if (state[0] === true && state[1] === argID && docTypes.includes(state[2])) {
			if (sessionType === "creation") {
				matchingStates.push([state[0], state[1], state[2], state[3]]);
			} else {
				matchingStates.push(state[2]);
			}
		}
	}

	return matchingStates;
}

