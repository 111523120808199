import { get, push, ref, remove, update } from "firebase/database";
import { database } from "../../../firebaseConfig";

export interface Highlight {
	uuid: string,
	target: string,
	color: string,
}

export interface SettingsStore {
	highlightings: Highlight[],
	document: DocumentFontSettings,
}

export interface DocumentFontSettings {
	font: string,
	fontSize: number,
	lineLeading: number,
}
export interface DocumentPreferredPalette {
	preferredPalette: string
}
export interface DocumentSampleColors {
	sampleColors: string[],
}

export const settingsServices = {
	getHighlightings,
	addHighlighting,
	deleteHighlighting,
	setDefaultHightlightingActive,
	getHighlightingsActive,
	toggleHighlightings,

	getDocumentViewingSettings,
	editFont,
	editFontSize,
	editLineLeading,

	fromBackendFontNameToCssFontFamily,

	getPreferredPalette,
	getColorSamples,
	getUserTutorialChoice,
	getUserTutorialDocumentChoice,
	setUserTutorialDocumentChoice,

};

function getHighlightings(userUuid: string) {
	return new Promise<Highlight[]>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/h`))
			.then(snapshot => {
				const highlightingsArray: Highlight[] = [];
				if (snapshot.exists()) {
					const highlightingKeys = Object.keys(snapshot.val());
					for (let i = 0; i < highlightingKeys.length; i++) {
						if (highlightingKeys[i] === 'a') {
							break;
						}
						highlightingsArray.push({
							uuid: highlightingKeys[i],
							color: snapshot.val()[highlightingKeys[i]].c as string,
							target: snapshot.val()[highlightingKeys[i]].t as string,
						});
					}
				}
				resolve(highlightingsArray);
			})
			.catch(err => {
				console.error("[Settings services getHighlightings] error:", err);
				reject(err);
			});
	})
}

function addHighlighting(userUuid: string, target: string, color: string) {
	return new Promise<Highlight>((resolve, reject) => {
		push(ref(database, `a/${userUuid}/h`), {
			c: color,
			t: target,
		})
			.then(dbRef => {
				if (dbRef.key) {
					resolve({
						uuid: dbRef.key,
						color: color,
						target: target.trim(),
					})
				}
				else {
					reject("Db ref key is null" + dbRef);
				}
			})
			.catch(err => {
				console.error("[Settings services] error pushing highlighting:", err);
				reject(err);
			})
	})
}
function setDefaultHightlightingActive(userUuid: string,) {
	return new Promise<boolean>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/h`), {
			a: true,
		})
			.then(() => {
				resolve(true)
			})
			.catch(err => {
				console.error("[Settings services] error setting default hightlighting:", err);
				reject(err);
			})
	})
}

function getHighlightingsActive(userUuid: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/h`))
			.then(snapshot => {
				if (snapshot.exists()) {
					if (snapshot.val().a !== undefined) {
						resolve(snapshot.val().a)
					}
					else
						setDefaultHightlightingActive(userUuid)
							.then(result => {
								// console.log('Set default to ', result);
								resolve(result)

							})
				}
				// resolve(highlightingsArray);
			})
			.catch(err => {
				console.error("[Settings services getHighlightings] error:", err);
				reject(err);
			});
	})
}
function toggleHighlightings(userUuid: string, active: boolean) {
	return new Promise<any>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/h`), {
			a: active,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then(documentFontSettings => {
						resolve(documentFontSettings);
					})
					.catch(err => {
						reject(err);
					})
			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}
function deleteHighlighting(userUuid: string, highlightingUuid: string) {
	return new Promise((resolve, reject) => {
		remove(ref(database, `a/${userUuid}/h/${highlightingUuid}`))
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				console.error('[Setting services] deleteHighlighting error:', err);
				reject(err);
			});
	})
}

function getDocumentViewingSettings(userUuid: string) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/f`))
			.then(snapshot => {
				if (snapshot.exists()) {
					resolve({
						font: snapshot.val().f ? snapshot.val().f : "open_dyslexic",
						lineLeading: snapshot.val().l ? snapshot.val().l : 0,
						fontSize: snapshot.val().s ? snapshot.val().s : 30,
					});
				}
				else {
					resolve({
						font: "arial",
						lineLeading: 50,
						fontSize: 30,
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}

function editFontSize(userUuid: string, fontSize: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			s: fontSize,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then(documentFontSettings => {
						resolve(documentFontSettings);
					})
					.catch(err => {
						reject(err);
					})
			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}

function editLineLeading(userUuid: string, lineLeading: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			l: lineLeading,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then(documentFontSettings => {
						resolve(documentFontSettings);
					})
					.catch(err => {
						reject(err);
					})
			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}

function editFont(userUuid: string, font: "arial" | 'tahoma' | 'serif' | 'sans_serif' | "verdana" | "open_dyslexic") {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			f: font,
		})
			.then(() => {
				getDocumentViewingSettings(userUuid)
					.then(documentFontSettings => {
						resolve(documentFontSettings);
					})
					.catch(err => {
						reject(err);
					})
			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}
function getPreferredPalette(userUuid: string) {
	return new Promise<DocumentPreferredPalette>((resolve, reject) => {
		get(ref(database, `u/${userUuid}`))
			.then(snapshot => {
				if (snapshot.exists()) {
					resolve({
						preferredPalette: snapshot.val().t ? snapshot.val().t : "default"
					});
				}
				else {
					resolve({
						preferredPalette: "default",
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}

function getColorSamples(userUuid: string) {
	return new Promise<DocumentSampleColors>((resolve, reject) => {

		get(ref(database, `u/${userUuid}`))
			.then(snapshot => {
				if (snapshot.exists()) {
					resolve({
						sampleColors: snapshot.val().cp ? snapshot.val().cp : []
					});
				}
				else {
					resolve({
						sampleColors: [],
					});
				}
				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}
function getUserTutorialChoice(userUuid: string, page: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `u/${userUuid}/uc/t`))
			.then(snapshot => {
				if (snapshot.exists()) {
					if (snapshot.val().st !== undefined && snapshot.val().st == false) {

						resolve(snapshot.val().st)
					}
					else if (snapshot.val()[page] !== undefined) {

						resolve(snapshot.val()[page])
					} else {

						resolve(true);
					}
				}
				else {
					resolve(true)
				}

				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}

function getUserTutorialDocumentChoice(userUuid: string) {
	return new Promise<boolean>((resolve, reject) => {
		get(ref(database, `u/${userUuid}/uc`))
			.then(snapshot => {

				if (snapshot.exists()) {

					if (snapshot.val() !== null) {
						resolve(snapshot.val().dt)
					}
					else {
						resolve(false);
					}
				} else {
					resolve(false)
				}

				//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

			})
			.catch(err => {
				console.error('[getDocumentViewingSettings] error:', err);
				reject(err);
			});
	})
}
function setUserTutorialDocumentChoice(userUuid: string, state: boolean) {
	update(
		ref(
			database,
			`u/${userUuid}/uc`
		),
		{
			dt: state,
		}
	).catch((err) => {
		console.error(
			"[Profile] error updating user choice on tutorial:",
			err
		);
	});
}

export function fromBackendFontNameToCssFontFamily(fontName: string) {
	switch (fontName) {
		case 'arial':
			return 'Arial, Helvetica, sans-serif';

		case 'georgia':
			return `Georgia, Times, Times New Roman, serif`;

		case 'tahoma':
			return `Tahoma, Verdana, Segoe, sans-serif`;

		case 'verdana':
			return `Verdana, Geneva, sans-serif`;

		case 'easy_reading':
			return `OpenDyslexic, Arial, sans-serif`; /* SINCE CONTRACT IS EXPIRED, EXISTING FONTS SELECTED AS EASYREADING WILL FALLBACK TO THIS */

		case 'open_dyslexic':
			return `OpenDyslexic, Arial, sans-serif`;

		default:
			return 'Arial, Helvetica, sans-serif';
	}
}

