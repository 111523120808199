import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonButton, IonGrid
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { purchasesActions } from '../store/actions';
import { functions } from '../../../firebaseConfig';
import './PurchaseV2.css';


type Props = {
    history: any,
};

type State = {

    isLoadingStripe: boolean,
};



class PurchaseV2 extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingStripe: false,
        }

    }

    componentDidMount(): void {
    }

    render() {
        return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonBackButton defaultHref="/" />
                </IonButtons>
                <IonTitle>Acquista</IonTitle> {/* TO BE LOCALIZED */}
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonGrid className="purchaseMainGrid">

                <p>

                </p>

                <IonButton
                  onClick={() => {
                    const createCheckoutSession = httpsCallable(functions, "createCheckoutSession-createCheckoutSession")
                    createCheckoutSession({})
                    .then(res => {
                      console.log("[Purchase] checkout session res:", res);
                      if (res.data && (res.data as any).sessionUrl) {
                        window.location.replace((res.data as any).sessionUrl);
                      }
                    })
                    .catch(err => {
                      console.log("[Purchase] error creating checkout session:", err);
                    })
                  }}
                >
                    Acquista {/* TO BE LOCALIZED */}
                </IonButton>

              </IonGrid>
            </IonContent>

          </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        purchaseError: () => {
            dispatch(purchasesActions.error());
        },

        paymentIsLoading: (isLoading: boolean) => {
            dispatch(purchasesActions.loading(isLoading));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseV2);