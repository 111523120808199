import {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonLoading,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonPage,
    IonSearchbar,
    IonSpinner,
    IonSplitPane,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { chevronForward, cloudUploadOutline, personCircleOutline } from "ionicons/icons";
import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import createSession from "../../../assets/icons/createSessionIcon.svg";
import { auth, database, functions, remoteConfig } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { Argument } from "../store/reducers";
import { argumentServices, getLocalStorageSessionState, restoreCreationWsSession, restoreWsSession } from "../store/services";
import "./SessionsHome.css";

import { onValue, ref, update } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { isIOS, isMobile } from "react-device-detect";
import { appVersion } from "../../../appVersion";
import closeSideMenu from "../../../assets/icons/closeSideMenu.svg";
import openSideMenu from "../../../assets/icons/openSideMenu.svg";
import reasyBook from "../../../assets/icons/reasy_logo_book.svg";
import historyIcon from "../../../assets/icons/history_icon.png";
import geoIcon from "../../../assets/icons/geo_icon.png";
import scienceIcon from "../../../assets/icons/science_icon.png";
import telescope from "../../../assets/icons/telescope.png";

import { sendEvent } from "../../../common/amplitudeUtils";
import { getActiveProducts, Product } from "../../../common/Paywall/store/paywallUtils";
import { store } from "../../../common/store";
import GetPremiumPopover from "../../purchase/components/GetPremiumPopover";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import { Organization } from "../../user/store/reducers";
import { fromProductsDbDataToProducts } from "../../user/store/services";
import { checkStatusBar, getStudySession, isImageValid, uploadFile } from "../utils/utils";
import "./LoadingMaterial.css";
import { Trans } from "react-i18next";


type Props = {
    history: any;
    orgData: Organization | null | "Private";
};

type State = {
    userUuid: string | null;
    userArguments: Argument[];
    // userArguments: any[],
    remoteConfig: boolean;
    newArgument: string;
    isProcessingResources: boolean;
    bearerToken: string;
    fileType: string | undefined;
    isUploadingFile: boolean;
    generatedArgument: Argument | null;

    isLoadingArguments: boolean;
    isLoadingAudiobook: boolean;
    isLoadingKeywords: boolean;
    isLoadingMap: boolean;
    isLoadingSummary: boolean;
    isLoadingFlashcards: boolean,
    showPremiumPopover: boolean,
    userProducts: Product[]
    isloadingPaymentSession: boolean,
    ttsSettings: TTSSettings,
    searchArgument: string,
    filteredArguments: Argument[],
    availableSessions: number,
};

class SessionsHome extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userUuid: null,
            userArguments: [],
            remoteConfig: false,
            newArgument: "",
            bearerToken: "",
            fileType: undefined,
            isUploadingFile: false,
            isLoadingArguments: true,

            isProcessingResources: false,
            isLoadingAudiobook: false,
            isLoadingKeywords: false,
            isLoadingMap: false,
            isLoadingSummary: false,
            isLoadingFlashcards: false,
            generatedArgument: null,
            showPremiumPopover: false,
            userProducts: [],
            isloadingPaymentSession: false,
            ttsSettings: {
                prosodyRate: "",
                voiceType: ""
            },
            searchArgument: '',
            filteredArguments: [],
            availableSessions: 75, //just the default number
        };
    }

    componentDidMount() {
        console.log(checkStatusBar())

        // this.setState({ isLoadingArguments: true });
        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (activated) console.log("Fetched and activated configs!");
                else {
                    const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
                    this.setState({ remoteConfig: remoteConfigValue });
                }
            })
            .catch((err) => console.error("Failed to fetch and activate configs", err));
        auth.onAuthStateChanged(async (userData) => {
            if (userData) {
                if (this.props.orgData !== "Private") {
                    this.props.history.push("/files");
                }
                ttsServices.getTTSSettings(userData.uid).then((settings) => {
                    this.setState({ ttsSettings: settings });
                });

                const purchasesDbRef = ref(database, `u/${userData.uid}/p`);
                onValue(
                    purchasesDbRef,
                    (data) => {
                        // console.log("[Firebase] on purchases data red:", data.val())
                        const purchasedProducts = fromProductsDbDataToProducts(data.val());
                        if (purchasedProducts.length != 0) {
                            getActiveProducts(purchasedProducts)
                                .then(response => {
                                    console.log("Active products: ", response);
                                    this.setState({ userProducts: response });
                                })
                        }
                    })
                // Usage
                this.handleSessionState("");
                this.handleSessionState("creation");
                setTimeout(() => {
                    userData.getIdToken().then((response) => {
                        const textValue = Cookies.get("topicCookie");
                        if (textValue) this.setState({ newArgument: textValue });
                        this.setState({ userUuid: userData.uid, bearerToken: response });
                        argumentServices
                            .getUserArguments(userData.uid)
                            .then((response: Argument[]) => {
                                response.sort((a: any, b: any) => b.created_at - a.created_at) // Sort by created_at
                                .filter((item: any, index: number, self: any[]) => 
                                  index === self.findIndex((t) => t.id === item.id) // Exclude duplicates based on 'id'
                                );
                                this.setState({ userArguments: response, filteredArguments: response, isLoadingArguments: false });
                                document.getElementById("newArgumentInput")?.focus();
                                this.setState({ availableSessions:this.state.userProducts.length === 0 ? 3 - response.length : 75 - response.filter(arg => arg.created_at >= ((Date.now() / 1000) - 30 * 24 * 60 * 60)).length })
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                }, 100);
                
            } else {
                this.setState({
                    userUuid: null,
                });
            }
        });
        setTimeout(() => {
            this.checkOverflow();
        }, 500);
        window.addEventListener('resize', this.checkOverflow);
    }
    handleSessionState(sessionType: string) {
        let sessionKey = sessionType === "creation" ? "creationSessionState" : "sessionState";
        let storedState = localStorage.getItem(sessionKey);
        let localStorageState = storedState ? JSON.parse(storedState) : [false, null];

        if (localStorageState) {
            let sessionTypes = getLocalStorageSessionState(sessionType);
            console.log(`${sessionType === "creation" ? "CREATION " : ""}SESSION TYPES:`, sessionTypes);

            if (sessionTypes.length > 0) {
                sessionTypes.forEach(session => {
                    let sessionResourceType = sessionType === "creation" ? session[2] : session;
                    let additionalData = sessionType === "creation" ? session.slice(3) : [];

                    switch (sessionResourceType) {
                        case "map":
                            this.setState({ isLoadingMap: true });
                            break;
                        case "summary":
                            this.setState({ isLoadingSummary: true });
                            break;
                        case "keywords":
                            this.setState({ isLoadingKeywords: true });
                            break;
                        case "flashcards":
                            this.setState({ isLoadingFlashcards: true });
                            break;
                        default:
                            this.setState({ isLoadingAudiobook: true });
                    }

                    if (sessionType === "creation") {
                        console.log(additionalData[0]); // Assuming session[3] contains some identifier or additional data
                        restoreCreationWsSession.call(
                            this,
                            additionalData[0], // session[3] in creation session
                            this.state.bearerToken,
                            store.getState().auth!.userData!.uid,
                            session[1], // Assuming argID is at index 1
                            sessionResourceType
                        );
                    }
                });

                if (sessionType !== "creation") {
                    restoreWsSession.call(this);
                }
                console.log(store.getState().auth!.userData!);
            }
        }
    }




    sendStringFromExample(argument: string) {
        if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
            this.setState({
                isLoadingAudiobook: true,
                isLoadingKeywords: true,
                isLoadingMap: true,
                isLoadingSummary: true,
                isLoadingFlashcards: true,
                isProcessingResources: true,
                newArgument: argument
            }, () => {
                sendEvent({
                    "user_id": this.state.userUuid,
                    "event_type": "Argument example clicked",
                    "event_properties": {
                        "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                        // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                        "argument_title": this.state.newArgument,
                    },
                    "language": i18n.language,
                    "app_version": appVersion,
                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                    "time": Date.now()
                })
                getStudySession.call(this)
            })
        }
        else {
            /* SHOW PAYWALL */
            this.setState({ showPremiumPopover: true })
        }
    }


    checkOverflow = () => {
        const element = document.querySelector(".sessionsHomeArgumentList") as HTMLElement;
        const icon = document.getElementById("wobbleIcon");
        if (element && icon) {
            if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
                icon.classList.remove("hidden");
            } else {
                icon.classList.add("hidden");
            }
        }
    };

    getDaysPassed = (date: number) => {
        const createdAt = new Date(date * 1000).getTime();
        const now = new Date().getTime();
        const timeDiff = now - createdAt;
        return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    };

    getDayGroupLabel = (daysPassed: number) => {
        if (daysPassed === 0) {
            return i18n.t('Oggi');
        } else if (daysPassed === 1) {
            return i18n.t('Ieri');
        } else if (daysPassed <= 7) {
            return i18n.t('Questa settimana');
        } else if (daysPassed <= 14) {
            return i18n.t('Settimana scorsa');
        } else if (daysPassed <= 30) {
            return i18n.t('Questo mese');
        } else {
            return i18n.t('Sessioni più vecchie');
        }
    };


    render() {
        let lastGroupLabel = "";

        return (
            <>
                {/* <IonLoading isOpen={this.state.isLoadingArguments} onDidDismiss={()=>this.setState({isLoadingArguments:false})} message={"Sto cercando le tue sessioni..."} /> */}

                <IonSplitPane contentId="sessionsMenu" className="sessionsSplitPane" when="(min-width: 1000px)">
                    <IonMenu
                        hidden={this.state.isProcessingResources}
                        onIonWillClose={() => {
                            if (document.getElementById("sessionsMenubutton")) document.getElementById("sessionsMenubutton")!.hidden = false;
                        }}
                        type="reveal"
                        contentId="sessionsMenu"
                        id="sessionsSideMenu"
                        swipeGesture={false}
                    >
                        {isMobile && isPlatform("capacitor") && isIOS && <IonToolbar className="toolbarNotchSideMenu" style={{ height: checkStatusBar() }}></IonToolbar>}
                        {/* <IonHeader className="sessionsMenuHeader">
						</IonHeader> */}
                        <div className="sessionsSideMenuTitleContainer">
                            <IonMenuToggle id="menuBtnNoSplit" className="sessionSideMenuBtn">
                                <IonImg className="sideMenuButtonImg" src={closeSideMenu} />
                            </IonMenuToggle>
                            <IonMenuButton
                                id="menuBtnSplit"
                                className="sessionSideMenuBtn"
                                onClick={(e) => {
                                    let sideMenuElement = (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
                                        ? (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
                                        : null;
                                    let menuButton = (document.getElementById("openSideMenuSessions") as HTMLIonButtonElement)
                                        ? (document.getElementById("openSideMenuSessions") as HTMLIonMenuElement)
                                        : null;
                                    if (sideMenuElement) {
                                        if (sideMenuElement.style.display === "none") {
                                            document.getElementById("sessionsMenubutton")!.hidden = false;
                                            sideMenuElement.style.display = "flex";
                                        } else sideMenuElement.style.display = "none";
                                        if (menuButton) menuButton.hidden = !menuButton.hidden;
                                    }
                                    if (document.getElementById("sessionsMenubutton")) document.getElementById("sessionsMenubutton")!.hidden = false;
                                    e.currentTarget.hidden = true;
                                    document.getElementById("openSideMenuSessions")!.hidden = false;
                                }}
                            >
                                <IonImg className="sideMenuButtonImg" src={closeSideMenu} />
                            </IonMenuButton>
                            <div className="sessionsMenuHeaderTitle">
                                <Trans>
                                    Le tue sessioni
                                </Trans>
                                <IonButton
                                    mode="ios"
                                    slot="end"
                                    className="sessionsMenuLanguageButton"
                                    onClick={() => {
                                        i18n.language === 'it' ?
                                            i18n.changeLanguage("en") :
                                            i18n.changeLanguage("it")
                                        if (this.state.userUuid)
                                            update(ref(database, `u/${this.state.userUuid}`), {
                                                l: i18n.language,
                                            })
                                        //JUST A RANDOM SETSTATE TO RE-RENDER THE COMPONENT TO LIVE UPDATE THE LANGUAGE
                                        this.setState({ fileType: this.state.fileType })
                                    }}
                                >
                                    <p className="flagParagraph">
                                        {i18n.language === 'en' ?
                                            "🇬🇧"
                                            :
                                            "🇮🇹"
                                        }
                                    </p>
                                </IonButton>
                            </div>
                        </div>
                        <IonContent className="ion-padding sideMenuContent">
                            <IonSearchbar
                                mode="ios"
                                placeholder={i18n.t("Cerca per titolo")}
                                className="sessionsHomeSearchbar"
                                onInput={(e) =>
                                    this.setState({ searchArgument: e.currentTarget.value!.toString() }, () => {
                                        let filteredArguments = this.state.userArguments.filter((arg) => arg.title.toLowerCase().includes(this.state.searchArgument.toLowerCase()));
                                        this.setState({ filteredArguments: filteredArguments });
                                    })
                                }
                            />
                            {this.state.filteredArguments.map((argument) => {
                                const daysPassed = this.getDaysPassed(argument.created_at);
                                const currentGroupLabel = this.getDayGroupLabel(daysPassed);
                                const showDivider = currentGroupLabel !== lastGroupLabel;
                                // console.log("SADSADSADSAD: ", showDivider);

                                lastGroupLabel = currentGroupLabel;
                                // console.log("SADSADSADSAD: ", currentGroupLabel);
                                return (
                                    <React.Fragment key={argument.uuid}>
                                        {showDivider && (
                                            <div key={`${argument.uuid}-divider`} className="sessionsHomeDayDivider">
                                                <p>{currentGroupLabel}</p>
                                            </div>
                                        )}
                                        <div
                                            key={argument.uuid}
                                            className={"sessionsHomeArgumentItem hoverEffect"}
                                            onClick={() => {
                                                sendEvent({
                                                    "user_id": this.state.userUuid,
                                                    "event_type": "Argument opened from list",
                                                    "event_properties": {
                                                        "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
                                                        // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                        "argument_title": argument.title,
                                                    },
                                                    "language": i18n.language,
                                                    "app_version": appVersion,
                                                    "platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
                                                    "time": Date.now(),
                                                });
                                                this.props.history.push({
                                                    pathname: "/session-details",
                                                    state: { argument: argument, availableSessions:this.state.availableSessions },
                                                });
                                            }}
                                        >
                                            <p className="sessionsHomeArgumentTitle">{argument.title}</p>
                                            <IonIcon icon={chevronForward} className="sessionsHomeArgumentArrow" />
                                            <div className="gradientDiv" />
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </IonContent>
                        {auth.currentUser && (
                            <div className="sessionsSideMenuBottomDiv" onClick={() => this.props.history.push("/profile")}>
                                <p>{auth.currentUser?.displayName ? auth.currentUser.displayName : auth.currentUser.email}</p>
                                <IonIcon className="sessionsSideMenuProfileIcon" icon={personCircleOutline} />
                            </div>
                        )}
                    </IonMenu>

                    <IonPage id="sessionsMenu" className="sessionsHomePage body">
                        {isMobile && isPlatform("capacitor") && isIOS && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}></IonToolbar>}
                        <div className="sessionsHomeHeaderDiv" hidden={this.state.isProcessingResources}>
                            <IonMenuToggle id="sessionsMenubutton" className="sideMenuButtonImg" onClick={(e) => {
                                (e.currentTarget.hidden = true)
                                let sideMenuElement = (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
                                sideMenuElement.style.display = "flex";
                            }}>
                                <IonImg src={openSideMenu} />
                            </IonMenuToggle>
                            <IonButton
                                id="openSideMenuSessions"
                                fill="clear"
                                onClick={(e) => {
                                    let sideMenuElement = (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
                                        ? (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
                                        : null;
                                    if (sideMenuElement) {
                                        if (sideMenuElement.style.display === "none") sideMenuElement.style.display = "flex";
                                        else sideMenuElement.style.display = "none";
                                    }
                                    e.currentTarget.hidden = true;
                                    document.getElementById("menuBtnSplit")!.hidden = false;
                                }}
                                hidden={isMobile || document.getElementById("sessionsSideMenu")?.style.display !== "none"}
                            >
                                <IonImg className="sideMenuButtonImg" src={openSideMenu} />
                            </IonButton>
                            <div className="sessionsHomeCounterDiv">
                                {this.state.userArguments && this.state.userProducts.length === 0 && (
                                    <p className="sessionsHomeCounterPar" slot="end">
                                        <Trans>Hai ancora</Trans> <b>{3 - this.state.userArguments.length} / 3 </b> <Trans>Sessioni disponibili ✨</Trans>
                                    </p>
                                )}
                                {3 - this.state.userArguments.length === 0 && this.state.userProducts.length === 0 && (
                                    <IonButton
                                        className="sessionsHomeUnlockNowBtn"
                                        slot="end"
                                        onClick={() => {
                                            this.setState({ showPremiumPopover: true });
                                        }}
                                    >
                                        Sblocca ora
                                    </IonButton>
                                )}
                                {this.state.userArguments && this.state.userProducts.length > 0 && (
                                    <p className="sessionsHomeCounterPar" slot="end">
                                        <Trans>Hai ancora</Trans> <b>{this.state.availableSessions} / 75 </b> <Trans>Sessioni disponibili questo mese ✨</Trans>
                                    </p>
                                )}
                            </div>
                        </div>

                        {/* </IonToolbar>
                        </IonHeader> */}
                        {
                            !this.state.isProcessingResources && !this.state.isLoadingArguments &&
                            <div className="sessionsHomeContent">
                                {/* TO REMOVE */}
                                {this.state.userArguments.length === 0 && false &&
                                    <div className="sessionsHomeOnboardingDiv">
                                        <div className="sessionsHomeOnboardingTitle">
                                            <p>
                                                <Trans>
                                                    Che argomento stai studiando?
                                                </Trans>
                                            </p>
                                        </div>
                                        <div className="sessionsHomeOnboardingContent">
                                            <div className="sessionsHomeArgumentInputContainer">
                                                {/* <p style={{ fontSize: '22px' }}>Che nuovo argomento vuoi studiare?</p> */}
                                                <IonInput
                                                    id="newArgumentInput"
                                                    className="sessionsHomeArgumentInput"
                                                    placeholder={i18n.t('Esempio "Il Rinascimento italiano"')}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            this.setState({
                                                                isLoadingAudiobook: true,
                                                                isLoadingKeywords: true,
                                                                isLoadingMap: true,
                                                                isLoadingSummary: true,
                                                                isLoadingFlashcards: true,
                                                                isProcessingResources: true,
                                                            })
                                                            sendEvent({
                                                                "user_id": this.state.userUuid,
                                                                "event_type": "Argument string sent",
                                                                "event_properties": {
                                                                    "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                                    // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                                    "argument_title": this.state.newArgument,
                                                                },
                                                                "language": i18n.language,
                                                                "app_version": appVersion,
                                                                "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                                "time": Date.now()
                                                            })
                                                            argumentServices.createArgumentFromString.call(this, this.state.newArgument, this.state.userUuid!, this.state.bearerToken)
                                                                .then(response => {
                                                                    this.setState({ isProcessingResources: false, generatedArgument: response })
                                                                    this.props.history.push({
                                                                        pathname: '/session-details',
                                                                        state: { argument: response }
                                                                    })
                                                                })
                                                        }
                                                    }}
                                                    onInput={(e) => {
                                                        if (e.currentTarget.value)
                                                            this.setState({ newArgument: e.currentTarget.value.toString() })
                                                        else
                                                            this.setState({ newArgument: '' })
                                                    }}
                                                    debounce={200}
                                                    value={this.state.newArgument}
                                                />
                                                <p className="sessionsHomeArgumentValuesText">
                                                    <Trans>
                                                        Digita l'argomento per ottenere:
                                                    </Trans>
                                                </p>
                                                <ul className="sessionsHomeArgumentValuesList">
                                                    <li><b><Trans>mappa concettuale</Trans></b></li>
                                                    <li><b><Trans>riassunto</Trans></b></li>
                                                    <li><b><Trans>parole chiave</Trans></b></li>
                                                    <li><b><Trans>audiolibro</Trans></b></li>
                                                </ul>
                                            </div>
                                            <div className="sessionsHomeBottomDiv">
                                                <div
                                                    className={this.state.newArgument.trim().length < 3 ? "sessionsHomeCTAButton disabledCTA" : "sessionsHomeCTAButton"}
                                                    onClick={() => {
                                                        if (this.state.newArgument.trim().length < 3)
                                                            return
                                                        this.setState({
                                                            isLoadingAudiobook: true,
                                                            isLoadingKeywords: true,
                                                            isLoadingMap: true,
                                                            isLoadingSummary: true,
                                                            isLoadingFlashcards: true,
                                                            isProcessingResources: true
                                                        })
                                                        /* CALL THE CLOUD FUNCTION TO GENERATE RESOURCES WITH STRING ARGUMENT */
                                                        Cookies.remove('textCookie');
                                                        sendEvent({
                                                            "user_id": this.state.userUuid,
                                                            "event_type": "Argument string sent",
                                                            "event_properties": {
                                                                "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                                // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                                "argument_title": this.state.newArgument,
                                                            },
                                                            "language": i18n.language,
                                                            "app_version": appVersion,
                                                            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                            "time": Date.now()
                                                        })
                                                        getStudySession.call(this)
                                                    }}>
                                                    Inizia ora
                                                    <IonIcon icon={createSession} />
                                                </div>
                                                <p
                                                    onClick={() => {
                                                        /* HANDLE FILE UPLOAD */
                                                        document.getElementById('argumentFile')?.click()

                                                    }}
                                                    className="sessionsHomeUploadParagraph">
                                                    <Trans>
                                                        oppure carica 📤 un documento
                                                    </Trans>
                                                </p>
                                                {/* <IonLoading isOpen={this.state.isUploadingFile} /> */}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!this.state.isLoadingArguments &&
                                    <div className="sessionsHomeMainDiv">
                                        {/* <div className="sessionsHomeOnboardingTitle">
                                            <p style={{ marginBottom: "25px", marginTop: '25px' }}>Sessioni di studio</p>
                                        </div> */}
                                        <div className="sessionsHomeLogoAndCtaDiv">
                                            <p className="sessionsHomeTitle">
                                                <Trans>
                                                    Che nuovo argomento vuoi studiare?
                                                </Trans>
                                            </p>
                                            <IonImg className='sessionsHomeLogo' src={reasyBook} />
                                        </div>
                                        <div className="sessionsHomeExamplesDiv">
                                            <div
                                                className="sessionsHomeExampleDiv"
                                                onClick={() => {
                                                    this.sendStringFromExample(i18n.t("L'Impero romano"))
                                                }}
                                            >
                                                <IonImg className="sessionsHomeExampleIcon" src={historyIcon} />
                                                <p>
                                                    <Trans>
                                                        L'Impero romano
                                                    </Trans>
                                                </p>
                                            </div>
                                            <div
                                                className="sessionsHomeExampleDiv"
                                                onClick={() => {
                                                    this.sendStringFromExample(i18n.t("La mitosi cellulare"))
                                                }}
                                            >
                                                <IonImg className="sessionsHomeExampleIcon" src={scienceIcon} />
                                                <p>
                                                    <Trans>
                                                        La mitosi cellulare
                                                    </Trans>
                                                </p>
                                            </div>
                                            <div
                                                className="sessionsHomeExampleDiv"
                                                onClick={() => {
                                                    this.sendStringFromExample(i18n.t("La geografia dell'Italia"))
                                                }}
                                            >
                                                <IonImg className="sessionsHomeExampleIcon" src={geoIcon} />
                                                <p>
                                                    <Trans>
                                                        La geografia dell'Italia
                                                    </Trans>
                                                </p>
                                            </div>
                                            <div
                                                className="sessionsHomeExampleDiv"
                                                onClick={() => {
                                                    this.sendStringFromExample(i18n.t("Astrofisica: Buchi neri"))
                                                }}
                                            >
                                                <IonImg className="sessionsHomeExampleIcon" src={telescope} />
                                                <p>
                                                    <Trans>
                                                        Astrofisica: Buchi neri
                                                    </Trans>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="sessionsHomeInputDiv"
                                            style={{ marginBottom: '0px' }}
                                        >
                                            <IonInput
                                                className="sessionsHomeNewArgumentInput"
                                                placeholder={i18n.t('Esempio "Il Rinascimento italiano"')}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (this.state.newArgument.trim().length > 3)
                                                            if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
                                                                this.setState({
                                                                    isLoadingAudiobook: true,
                                                                    isLoadingKeywords: true,
                                                                    isLoadingMap: true,
                                                                    isLoadingSummary: true,
                                                                    isLoadingFlashcards: true,
                                                                    isProcessingResources: true,
                                                                })
                                                                sendEvent({
                                                                    "user_id": this.state.userUuid,
                                                                    "event_type": "Argument string sent",
                                                                    "event_properties": {
                                                                        "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                                        // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                                        "argument_title": this.state.newArgument,
                                                                    },
                                                                    "language": i18n.language,
                                                                    "app_version": appVersion,
                                                                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                                    "time": Date.now()
                                                                })
                                                                getStudySession.call(this)

                                                            }
                                                            else {
                                                                /* SHOW PAYWALL */
                                                                this.setState({ showPremiumPopover: true })
                                                            }
                                                    }
                                                }}
                                                onInput={(e) => {
                                                    if (e.currentTarget.value)
                                                        this.setState({ newArgument: e.currentTarget.value.toString() })
                                                    else
                                                        this.setState({ newArgument: '' })
                                                }}
                                                debounce={200}
                                                value={this.state.newArgument}
                                            >

                                                <IonIcon
                                                    className="newArgumentUploadIcon"
                                                    slot="start"
                                                    icon={cloudUploadOutline}
                                                    onClick={() => {
                                                        if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
                                                            document.getElementById('argumentFile')?.click()
                                                        }
                                                        else {
                                                            /* SHOW PAYWALL */
                                                            this.setState({ showPremiumPopover: true })
                                                        }
                                                    }}
                                                />

                                                <IonIcon
                                                    slot="end"
                                                    icon={createSession}
                                                    className="newArgumentArrow"
                                                    hidden={this.state.newArgument.trim().length < 3}
                                                    onClick={() => {
                                                        if (this.state.newArgument.trim().length < 3)
                                                            return
                                                        if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
                                                            this.setState({
                                                                isLoadingAudiobook: true,
                                                                isLoadingKeywords: true,
                                                                isLoadingMap: true,
                                                                isLoadingSummary: true,
                                                                isLoadingFlashcards: true,
                                                                isProcessingResources: true
                                                            })
                                                            sendEvent({
                                                                "user_id": this.state.userUuid,
                                                                "event_type": "Argument string sent",
                                                                "event_properties": {
                                                                    "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                                    // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                                    "argument_title": this.state.newArgument,
                                                                },
                                                                "language": i18n.language,
                                                                "app_version": appVersion,
                                                                "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                                "time": Date.now()
                                                            })
                                                            getStudySession.call(this)
                                                        }
                                                        else {
                                                            /* SHOW PAYWALL */
                                                            this.setState({ showPremiumPopover: true })
                                                        }
                                                    }}
                                                />
                                            </IonInput>
                                        </div>
                                        {/* <p
                                                onClick={() => {
                                                    if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0) {
                                                        document.getElementById('argumentFile')?.click()
                                                    }
                                                    else {
                                                        this.setState({ showPremiumPopover: true })
                                                    }
                                                }}
                                                className="sessionsHomeUploadParagraph">
                                                oppure carica 📤 un documento
                                            </p> */}
                                        {/* <p hidden className="sessionsHomeArgumentDescription">
                                            Qui trovi tutte le tue sessioni di studio.
                                            <br />
                                            Selezionane una per visualizzare mappe, riassunti, parole chiave e audiolibri.
                                        </p>
                                        <div hidden className="sessionsHomeOnboardingContent">

                                            <div
                                                className="sessiofnsHomeArgumentList"
                                                onScroll={(e) => {
                                                    const element = e.target as HTMLElement;

                                                    const icon = document.getElementById("wobbleIcon");
                                                    if (icon) {
                                                        const isAtTop = element.scrollTop === 0;
                                                        const scrollThreshold = 10; // Define a small scroll threshold in pixels
                                                        const hasScrolled = element.scrollTop > scrollThreshold;
                                                        if (isAtTop) icon.classList.remove("hidden");
                                                        if (hasScrolled) icon.classList.add("hidden");
                                                    }
                                                }}
                                            >
                                                <IonInput
                                                    className="searchArgumentInput"
                                                    placeholder="Cerca per nome"
                                                    onInput={(e) => {
                                                        this.setState({ searchArgument: e.currentTarget.value!.toString() }, () => {
                                                            let filteredArguments = this.state.userArguments.filter(arg => arg.title.toLowerCase().includes(this.state.searchArgument.toLowerCase()))
                                                            this.setState({ filteredArguments: filteredArguments })
                                                        })
                                                    }}
                                                >

                                                </IonInput>
                                                {this.state.filteredArguments.map((argument) => {
                                                    return (

                                                        <div
                                                            key={argument.uuid}
                                                            className="sessionsHomeArgumentItem hoverEffect"
                                                            onClick={() => {
                                                                sendEvent({
                                                                    "user_id": this.state.userUuid,
                                                                    "event_type": "Argument opened from list",
                                                                    "event_properties": {
                                                                        "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                                        // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                                                        "argument_title": argument.title,
                                                                    },
                                                                    "language": i18n.language,
                                                                    "app_version": appVersion,
                                                                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                                    "time": Date.now()
                                                                })
                                                                this.props.history.push({
                                                                    pathname: "/session-details",
                                                                    state: { argument: argument },
                                                                });
                                                            }}
                                                        >
                                                            <p className="sessionsHomeArgumentTitle">{argument.title}</p>
                                                            <IonIcon icon={chevronForward} className="sessionsHomeArgumentArrow" />
                                                        </div>

                                                    );
                                                })}
                                                <IonIcon className="wobble-hor-bottom" id="wobbleIcon"
                                                    hidden={this.state.userArguments.length <= 3}
                                                    onClick={() => {
                                                        const element = document.querySelector('.sessionsHomeArgumentList') as HTMLElement;
                                                        if (element) {
                                                            element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' }); // Scroll to the bottom of the div
                                                        }
                                                        const icon = document.getElementById('wobbleIcon');
                                                        if (icon) {
                                                            icon.classList.remove('hidden'); // Ensure the icon is visible after the click
                                                        }
                                                    }}
                                                    icon={arrowDown}></IonIcon>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                                {/*   <IonFab hidden={this.state.userArguments.length === 0} style={{ position: "fixed" }} horizontal="start" vertical="bottom">
                                    <div
                                        className="profileButtonReasy"
                                        onClick={() => {
                                            this.props.history.push('/profile')
                                        }}
                                    >
                                        <IonIcon className="sessionsReasyLogoButton" icon={reasyRoundedLogo} />
                                        <p className="profileButtonReasyText">Vai al profilo</p>
                                    </div>
                                </IonFab> */}
                            </div>
                        }
                        {this.state.isProcessingResources &&
                            /*  <div className="sessionsHomeProcessingScreen">
                                 <IonProgressBar buffer={.25} value={.1} />
                                 <p className="sessionsHomeArgumentDescription">
                                     Attendi qualche secondo, stiamo generando la tua mappa concettuale, il riassunto, le parole chiave e l'audiolibro relativo all'argomento
                                 </p>
                                 <p className="sessionsHomeProcessingScreenArgument">"{this.state.newArgument}"</p>
                             </div> */
                            <IonGrid className="loadingMaterialMainDiv">
                                <div className="loadingMaterialHeaderDiv">
                                    <p className="loadingMaterialHeaderPar">
                                        <Trans>
                                            Stiamo preparando il tuo materiale a tema:
                                        </Trans>
                                    </p>
                                    <h2 className="loadingMaterialHeaderTitle">
                                        <b>{this.state.newArgument.trim()}</b>
                                    </h2>
                                </div>

                                <div className="loadingMaterialMaterialsContainer">
                                    <div className="loadingMaterialMaterialDiv">
                                        <div className="loadingMaterialStatusDiv">
                                            {this.state.isLoadingMap && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
                                            {!this.state.isLoadingMap && <p className="loadingMaterialCompletedPar">✔️</p>}
                                        </div>
                                        <p className="loadingMaterialLabelPar">
                                            <Trans>
                                                Mappa concettuale
                                            </Trans>
                                        </p>
                                    </div>

                                    <div className="loadingMaterialLine" />

                                    <div className="loadingMaterialMaterialDiv">
                                        <div className="loadingMaterialStatusDiv">
                                            {this.state.isLoadingAudiobook && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
                                            {!this.state.isLoadingAudiobook && <p className="loadingMaterialCompletedPar">✔️</p>}
                                        </div>
                                        <p className="loadingMaterialLabelPar">
                                            <Trans>
                                                Audiolibro
                                            </Trans>
                                        </p>
                                    </div>

                                    <div className="loadingMaterialLine" />

                                    <div className="loadingMaterialMaterialDiv">
                                        <div className="loadingMaterialStatusDiv">
                                            {this.state.isLoadingKeywords && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
                                            {!this.state.isLoadingKeywords && <p className="loadingMaterialCompletedPar">✔️</p>}
                                        </div>
                                        <p className="loadingMaterialLabelPar">
                                            <Trans>
                                                Parole chiave
                                            </Trans>
                                        </p>
                                    </div>

                                    <div className="loadingMaterialLine" />

                                    <div className="loadingMaterialMaterialDiv">
                                        <div className="loadingMaterialStatusDiv">
                                            {this.state.isLoadingSummary && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
                                            {!this.state.isLoadingSummary && <p className="loadingMaterialCompletedPar">✔️</p>}
                                        </div>
                                        <p className="loadingMaterialLabelPar">
                                            <Trans>
                                                Riassunto
                                            </Trans>
                                        </p>
                                    </div>
                                    <div className="loadingMaterialLine" />
                                    <div className="loadingMaterialMaterialDiv">
                                        <div className="loadingMaterialStatusDiv">
                                            {this.state.isLoadingFlashcards && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
                                            {!this.state.isLoadingFlashcards && <p className="loadingMaterialCompletedPar">✔️</p>}
                                        </div>
                                        <p className="loadingMaterialLabelPar">
                                            <Trans>
                                                Flashcards
                                            </Trans>
                                        </p>
                                    </div>
                                    {!this.state.isLoadingAudiobook && !this.state.isLoadingKeywords && !this.state.isLoadingMap && !this.state.isLoadingSummary && !this.state.isLoadingFlashcards && (
                                        <div className="loadingMaterialStartButtonDiv">
                                            <IonButton
                                                className="loadingMaterialStartButton"
                                                mode="ios"
                                                onClick={() => {
                                                    if (this.state.generatedArgument) {
                                                        this.props.history.push({
                                                            pathname: "/session-details",
                                                            state: { argument: this.state.generatedArgument },
                                                        });
                                                    }
                                                }}
                                            >
                                                <Trans>
                                                    Inizia a studiare
                                                </Trans>
                                            </IonButton>
                                        </div>
                                    )}
                                </div>
                            </IonGrid>
                        }
                        <input
                            type="file"
                            id="argumentFile"
                            style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
                            onChange={(e) => {
                                if (this.state.userArguments.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
                                    const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
                                    if (file) {
                                        if (file.size > 25 * 1024 * 1024) { /* FILE SIZE > 25MB */
                                            let errorMessage =
                                                i18n.language === "it"
                                                    ? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
                                                    : i18n.language === "en"
                                                        ? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
                                                        : i18n.language === "fr"
                                                            ? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
                                                            : i18n.language === "de"
                                                                ? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
                                                                : i18n.language === "ca"
                                                                    ? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
                                                                    : i18n.language === "es"
                                                                        ? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
                                                                        : "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
                                            alert(errorMessage);
                                            return
                                        }
                                        if (file.type.startsWith('image/')) {
                                            isImageValid.call(this, file).then(isValid => {
                                                if (isValid)
                                                    this.setState(
                                                        {
                                                            newArgument: file?.name,
                                                            isUploadingFile: true,
                                                            isProcessingResources: true,
                                                        },
                                                        () => uploadFile.call(this, file, this.state.userUuid)
                                                    );
                                                else {
                                                    let errorMessage =
                                                        i18n.language === "it"
                                                            ? "Immagine troppo grande. Le immagini non possono superare gli 8000 pixel di larghezza o altezza. Per favore ridimensiona la tua immagine e riprova"
                                                            : i18n.language === "en"
                                                                ? "Image too large. Images cannot exceed 8000 pixels in width or height. Please resize your image and try again."
                                                                : i18n.language === "fr"
                                                                    ? "Image trop grande. Les images ne peuvent pas dépasser 8000 pixels de largeur ou de hauteur. Veuillez redimensionner votre image et réessayer."
                                                                    : i18n.language === "de"
                                                                        ? "Bild zu groß. Bilder dürfen nicht breiter oder höher als 8000 Pixel sein. Bitte ändern Sie die Größe Ihres Bildes und versuchen Sie es erneut."
                                                                        : i18n.language === "ca"
                                                                            ? "Imatge massa gran. Les imatges no poden superar els 8000 píxels d'amplada o alçada. Si us plau, redimensiona la teva imatge i torna-ho a provar."
                                                                            : i18n.language === "es"
                                                                                ? "Imagen demasiado grande. Las imágenes no pueden exceder los 8000 píxeles de ancho o alto. Por favor, redimensiona tu imagen y vuelve a intentarlo."
                                                                                : i18n.language === "pt"
                                                                                    ? "Imagem muito grande. As imagens não podem exceder 8.000 pixels de largura ou altura. Por favor, redimensione sua imagem e tente novamente."
                                                                                    : i18n.language === "cz"
                                                                                        ? "Obrázek je příliš velký. Obrázky nesmí překročit 8000 pixelů na šířku nebo výšku. Změňte prosím velikost obrázku a zkuste to znovu."
                                                                                        : i18n.language === "nl"
                                                                                            ? "Afbeelding te groot. Afbeeldingen mogen niet groter zijn dan 8.000 pixels breed of hoog. Herdimensioneer uw afbeelding en probeer het opnieuw."
                                                                                            : ""

                                                    alert(errorMessage);
                                                    return
                                                }
                                            })
                                        }
                                        else
                                            this.setState(
                                                {
                                                    newArgument: file?.name,
                                                    isUploadingFile: true,
                                                    isProcessingResources: true,
                                                },
                                                () => uploadFile.call(this, file, this.state.userUuid)
                                            );
                                    }
                                }
                                else {
                                    this.setState({ showPremiumPopover: true })
                                }
                            }}

                            accept="image/png, image/jpg, application/pdf"
                        ></input>
                        <IonLoading
                            isOpen={this.state.isLoadingArguments}
                            message={i18n.t("Sto cercando le tue sessioni...")}
                        />
                        <GetPremiumPopover
                            userUuid={this.state.userUuid ? this.state.userUuid : ""}
                            isOpen={this.state.showPremiumPopover}
                            history={this.props.history}
                            onGetPremiumButtonPressed={() => {
                                this.setState({ showPremiumPopover: false, isloadingPaymentSession: true });
                                const createCheckoutSession = httpsCallable(functions, "createCheckoutSession-createCheckoutSession")
                                createCheckoutSession({})
                                    .then(res => {
                                        this.setState({ isloadingPaymentSession: false })
                                        console.log("[Purchase] checkout session res:", res);
                                        if (res.data && (res.data as any).sessionUrl) {
                                            window.location.replace((res.data as any).sessionUrl);
                                        }
                                    })
                                    .catch(err => {
                                        console.log("[Purchase] error creating checkout session:", err);
                                    })
                            }}
                            onDidDismiss={() => {
                                this.setState({ showPremiumPopover: false });
                            }}
                        />
                        <IonLoading isOpen={this.state.isloadingPaymentSession} message={"Caricamento..."} />{/* TO LOCALIZE THIS */}
                    </IonPage >
                </IonSplitPane >
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        orgData: state.user.organization,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsHome);
