import {
    IonButton,
    IonIcon,
    IonPage,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { pause, play, playBack, playForward, volumeMedium } from "ionicons/icons";
import React from "react";
import { connect } from "react-redux";
import { auth, remoteConfig } from "../../../firebaseConfig";
import { Argument } from "../../sessions/store/reducers";
import { Organization } from "../../user/store/reducers";
import "./Audiobooks.css";
import { isIOS, isIPad13, isMobile } from "react-device-detect";
import { checkStatusBar } from "../../sessions/utils/utils";
import { Trans } from "react-i18next";


type Props = {
    history: any;
    orgData: Organization | null

};

type State = {
    userUuid: string | null,
    userArguments: Argument[],
    // userArguments: any[],
    remoteConfig: boolean,
    newArgument: string,
    isProcessingResources: boolean,
    argument: Argument | null,
    audio: string,
    isPlayingAudio: boolean,
    audioElement: HTMLAudioElement | null,
};

class Audiobooks extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userUuid: null,
            userArguments: [],
            remoteConfig: false,
            newArgument: '',
            isProcessingResources: false,
            argument: null,
            audio: '',
            isPlayingAudio: false,
            audioElement: null,
        };
    }
    componentDidMount() {
        if (this.props.history.location.state.argument) {
            let argument = this.props.history.location.state.argument;
            /* ORDER THE ARRAY TO DISPLAY THE CARDS IN A CERTAIN ORDER */
            argument.resources.sort((a: any, b: any) => {
                const order = ['map', 'summary', 'keywords', 'audio'];
                return order.indexOf(a.type) - order.indexOf(b.type);
            });
            this.setState({
                argument: this.props.history.location.state.argument,
            })
        }
        if (this.props.history.location.state.audio) {
            let audio = this.props.history.location.state.audio;
            this.setState({
                audio: audio,
            })
            // (document.getElementById('audioRange') as HTMLIonRangeElement).max = this.audioElement.duration
        }
        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (activated) console.log("Fetched and activated configs!");
                else {
                    const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
                    this.setState({ remoteConfig: remoteConfigValue })
                }

            })
            .catch((err) => console.error("Failed to fetch and activate configs", err));
        auth.onAuthStateChanged((userData) => {
            if (userData) {
                this.setState({ userUuid: userData.uid });

            } else {
                this.setState({
                    userUuid: null,
                });
            }
        });
    }
    formatTime(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
    render() {
        return (
            <IonPage className="sessionDetailsPage body">
                {this.state.argument && this.state.audio &&
                    <div className="sessionDetailsMainDiv">
                        {(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }} ></IonToolbar>}

                        <IonButton
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/session-details`,
                                    state: { argument: this.state.argument }
                                });
                            }}
                            className="sessionsDetailsGoBackBtn"

                        >
                            <Trans>
                                Torna indietro
                            </Trans>
                        </IonButton>
                        <div className="sessionsHomeOnboardingTitle">
                            <p className="sessionDetailTitle">"{this.state.argument?.title}"</p>
                        </div>

                        <audio
                            controls
                            hidden
                            id='audioEl'
                            onDurationChange={(e) => {
                                this.setState({ audioElement: e.currentTarget })
                            }}
                            onPlay={() => {
                                this.setState({ isPlayingAudio: true })
                            }}
                            onPause={() => {
                                this.setState({ isPlayingAudio: false })
                            }}
                            onVolumeChange={(e) => console.log(e.currentTarget.volume)}
                            onTimeUpdate={(e) => {
                                (document.getElementById('audioRange') as HTMLIonRangeElement).value = e.currentTarget.currentTime
                                // this.audioElement.currentTime = e.currentTarget.currentTime
                                if (document.getElementById('current-time'))
                                    document.getElementById("current-time")!.innerText = this.formatTime(e.currentTarget.currentTime);

                            }}
                            src={this.state.audio}>
                        </audio>

                        {this.state.audioElement &&
                            <div className="sessionsAudioContainer">
                                <p className="sessionsAudioCTA">
                                    <Trans>
                                        Ascolta l'audiolibro!
                                    </Trans>
                                </p>
                                <IonRange
                                    onMouseDown={() => {
                                        this.state.audioElement?.pause()
                                    }}
                                    onMouseUp={() => {
                                        this.state.audioElement?.play()
                                    }}
                                    mode="ios"
                                    className={'sessionsAudioRange'}
                                    id='audioRange'
                                    value={this.state.audioElement ? (document.getElementById("audioEl") as HTMLAudioElement).currentTime : 0}
                                    max={this.state.audioElement ? (document.getElementById("audioEl") as HTMLAudioElement).duration : 0}
                                    onIonChange={(e) => {
                                        if (this.state.audioElement) {
                                            this.state.audioElement.currentTime = Number(e.detail.value)
                                        }
                                    }}
                                >
                                    {this.state.audioElement &&
                                        <span slot="start" id="current-time">00:00</span>
                                    }
                                    {this.state.audioElement && this.state.audioElement.duration &&
                                        <span slot="end" id="duration-time">
                                            {this.formatTime((document.getElementById("audioEl") as HTMLAudioElement).duration)}
                                        </span>
                                    }
                                </IonRange>
                                <div className="sessionsAudioControlsDiv">
                                    {/* <IonIcon
                                        onClick={() => {
                                            if (this.state.audioElement) {
                                                if (this.state.audioElement.playbackRate > 0.25) {
                                                    this.state.audioElement.playbackRate -= 0.25
                                                    this.setState({ audioElement: this.state.audioElement })
                                                }
                                                else
                                                    return
                                            }
                                        }}
                                        className="sessionsAudioPlayIcon"
                                        icon={playBack}
                                    /> */}
                                    <IonSelect
                                        interface="popover"
                                        interfaceOptions={{
                                            cssClass: 'sessionsAudioSpeedSelectPopover'
                                        }}
                                        className="sessionsAudioSpeedSelect"
                                        onIonChange={(e) => {
                                            if (this.state.audioElement) {
                                                this.state.audioElement.playbackRate = e.detail.value
                                                this.setState({ audioElement: this.state.audioElement })
                                            }
                                        }}
                                        value={this.state.audioElement.playbackRate}
                                        selectedText={this.state.audioElement.playbackRate + "x"}>
                                        <IonSelectOption value={0.25} >0.25</IonSelectOption>
                                        <IonSelectOption value={0.5} >0.5</IonSelectOption>
                                        <IonSelectOption value={0.75} >0.75</IonSelectOption>
                                        <IonSelectOption value={1} >1</IonSelectOption>
                                        <IonSelectOption value={1.25} >1.25</IonSelectOption>
                                        <IonSelectOption value={1.5} >1.5</IonSelectOption>
                                        <IonSelectOption value={1.75} >1.75</IonSelectOption>
                                        <IonSelectOption value={2} >2</IonSelectOption>
                                    </IonSelect>
                                    <IonIcon
                                        onClick={() => {
                                            this.state.isPlayingAudio ?
                                                this.state.audioElement!.pause() :
                                                this.state.audioElement!.play()
                                        }}
                                        className="sessionsAudioPlayIcon"
                                        icon={this.state.isPlayingAudio ? pause : play}
                                    />
                                    {/* <IonIcon
                                        onClick={() => {
                                            if (this.state.audioElement) {
                                                if (this.state.audioElement.playbackRate < 2) {
                                                    this.state.audioElement.playbackRate += 0.25
                                                    this.setState({ audioElement: this.state.audioElement })
                                                }
                                                else
                                                    return
                                            }
                                        }}
                                        className="sessionsAudioPlayIcon"
                                        icon={playForward}
                                    /> */}
                                    <div className="sessionsAudioVolumeDiv">
                                        <div className="sessionsAudioVolumeHoverArea"></div>
                                        <IonIcon
                                            className="sessionsAudioVolumeIcon"
                                            icon={volumeMedium}
                                        />
                                        <IonRange
                                            id="volumeControl"
                                            className="sessionsAudioVolumeControl"
                                            mode="ios"
                                            snaps={false}
                                            min={0}
                                            max={100}
                                            value={this.state.audioElement ? (document.getElementById("audioEl") as HTMLAudioElement).volume * 100 : 100}
                                            onIonInput={(e) => {
                                                if (this.state.audioElement) {
                                                    this.state.audioElement.volume = Number(e.detail.value) / 100
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </IonPage >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        orgData: state.user.organization,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audiobooks);
