import axios from 'axios';
import { DocumentData, QuerySnapshot, Timestamp, addDoc, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { deleteObject, getDownloadURL, listAll, ref, ref as storageRef, uploadBytes } from "firebase/storage";
import apiService from '../../../common/apiService';
import { firestore, functions, storage } from '../../../firebaseConfig';
import { Document, DocumentElement, SearchWordImagesResult, Sentence } from './reducers';
import { sendEvent } from '../../../common/amplitudeUtils';
import { store } from '../../../common/store';
import { appVersion } from '../../../appVersion';
import { isPlatform } from '@ionic/core';
import i18n from '../../../i18n';
import { generateConfirmationToast, generateErrorToast } from '../utils/utils';

export const filesServices = {
    getFile,
    fromDocumentDataToDocumentElements,
    getMainFileData,
    getOrderedDocuments,
    getMainFileDataSummary,

    uploadFile,
    deleteFile,
    deleteMap,
    addTagsToDocument,
    getImages,

    fromFilesSnapshotToArrayOfDocuments,

    getConceptualMap,
    getSummary,
    getTutorialDoc,

};

function fromFilesSnapshotToArrayOfDocuments(querySnapshot: QuerySnapshot): Document[] {
    const documentsToReturn: Document[] = [];
    for (let i = 0; i < querySnapshot.docs.length; i++) {
        documentsToReturn.push(fromDocumentDataToDocument(querySnapshot.docs[i]));
    }
    return documentsToReturn;
}

function fromDocumentDataToDocument(documentData: DocumentData, documentParagraphs?: DocumentData): Document {
    return {
        uuid: documentData.ref.id,
        created_at: documentData.data().c as number,
        last_access: documentData.data().e as number,
        name: documentData.data().t as string,
        owner: documentData.data().o as string,
        preview: "",
        sentences: documentParagraphs ? fromDocumentParagraphsToArrayOfSentences(documentParagraphs) : undefined,
        tipo: documentData.data().dt,
        isbn_code: documentData.data().b
    }
}
function fromSummaryDocumentDataToDocument(documentData: DocumentData, documentParagraphs?: DocumentData): Document {
    return {
        uuid: documentData.ref.id,
        created_at: documentData.data().c as number,
        last_access: documentData.data().e as number,
        name: documentData.data().t as string,
        preview: "",
        sentences: documentParagraphs ? fromDocumentParagraphsToArrayOfSentences(documentParagraphs) : undefined,
        owner: documentData.data().o as string,

    }
}

function fromDocumentParagraphsToArrayOfSentences(paragraphsObj: DocumentData): Sentence[] {
    const sentencesToReturn: Sentence[] = [];
    for (let i = 0; i < paragraphsObj.docs.length; i++) {
        sentencesToReturn.push({
            uuid: paragraphsObj.docs[i].ref.id,
            index: paragraphsObj.docs[i].data().i,
            text: paragraphsObj.docs[i].data().p,
            language: "it",
            sentence_words: [],
        })
    }
    sentencesToReturn.sort((a, b) => a.index - b.index)
    return sentencesToReturn;
}

function getMainFileData(fileId: string) {
    return new Promise<Document>((resolve, reject) => {
        getDoc(doc(firestore, `f/${fileId}`))
            .then(async documentSnapshot => {
                resolve(fromDocumentDataToDocument(documentSnapshot));
            })
            .catch(err => {
                console.error("[Files services] getting file:", err);
                reject(err);
            })
    })
}
function getMainFileDataSummary(fileId: string) {
    return new Promise<Document>((resolve, reject) => {
        getDoc(doc(firestore, `s/${fileId}`))
            .then(async documentSnapshot => {
                resolve(fromSummaryDocumentDataToDocument(documentSnapshot));
            })
            .catch(err => {
                console.error("[Files services] getting file:", err);
                reject(err);
            })
    })
}

function getFile(fileId: string) {
    return new Promise<Document>((resolve, reject) => {
        getDoc(doc(firestore, `f/${fileId}`))
            .then(async documentSnapshot => {
                let documentParagraphsData: DocumentData | undefined = undefined;
                try {
                    documentParagraphsData = (await getDocs(collection(firestore, `f/${fileId}/d`)));
                }
                catch (e) {
                    console.error("[files services - get file] error getting file doc data:", e);
                }
                resolve(fromDocumentDataToDocument(documentSnapshot, documentParagraphsData));
            })
            .catch(err => {
                console.error("[Files services] getting file:", err);
                reject(err);
            })
    })
}

function fromDocumentDataToDocumentElements(data: DocumentData): DocumentElement[] {
    const documentElements: DocumentElement[] = [];
    for (let i = 0; i < data.docs.length; i++) {
        const docData = data.docs[i].data();
        documentElements.push({
            uuid: data.docs[i].ref.id,
            documentId: docData.d,
            index: docData.i,
            text: docData.p,
            keyWord: docData.k,
        })
    }
    documentElements.sort((a, b) => a.index - b.index);
    return documentElements;
}

function uploadFile(file: File | Blob, userUuid: string, fromPDF: boolean, token: string, actionType: string, chooserFileName?: string) {
    return new Promise((resolve, reject) => {
        let fileName: string | "" = "";
        if (file instanceof File) {
            // console.log("FILE NAME", file.name); // TO REMOVE
            // console.log("extension:", (file as File).name.split(".").pop()); // TO REMOVE
            fileName = file.name.trim();
        }
        else if ((file instanceof Blob) && chooserFileName) {
            fileName = chooserFileName;
        }
        let filePath: string
        if (fromPDF)
            filePath = `tf/${userUuid}/file_for_maps/${makeId(16)}${fileName ? `/${fileName}` : ""}`
        else
            filePath = `f/${userUuid}/${makeId(16)}${fileName ? `/${fileName}` : ""}`
        console.log("[FILE PATHS]", filePath)

        const storageRef = ref(storage, filePath);
        const metadata = {
            customMetadata: {
                'actionType': actionType, // custom metadata
            },
        };
        uploadBytes(storageRef, file, metadata)
            .then(async data => {
                console.log("[File services] upload success:", data.ref);
                sendEvent({
                    "user_id": userUuid,
                    "event_type": "Document Uploaded",
                    "event_properties": {
                        "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                        // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                        "document_name": fileName,
                    },
                    "language": i18n.language,
                    "app_version": appVersion,
                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                    "time": Date.now()
                })
                const fileRef = ref(storage, filePath);
                const url = await getDownloadURL(fileRef)
                let mapFromPDF_data
                if (fromPDF) {
                    mapFromPDF_data = await getConceptualMapFromPDF(url, token, userUuid, fileName)
                }
                if (fromPDF)
                    resolve(mapFromPDF_data);
                else
                    resolve(data.ref)

                /* fromGSUrltoUrl(data.ref.fullPath)
                    .then(url => {
                        this.setState({ imgUrl: url })
                    })
                    .catch(err => {
                        console.error("[File services] error getting public url:");
                        
                    }) */
            })
            .catch(err => {
                console.log("[Files services] error uploading file", err);
                reject(err);
            })
    })
}
export const deleteDirectoryContents = async (dirPath: string) => {

    try {
        const dirRef = ref(storage, dirPath);
        const list = await listAll(dirRef);

        const fileDeletions = list.items.map((itemRef) => {
            return deleteObject(itemRef).then(() => {
            });
        });

        // Recurse into subdirectories
        const dirDeletions = list.prefixes.map((subDirRef) => {
            return deleteDirectoryContents(subDirRef.fullPath);
        });

        // Wait for all deletions to complete
        await Promise.all([...fileDeletions, ...dirDeletions]);
        // console.log(`All contents of ${dirPath} deleted.`);
    } catch (error) {
        console.error('Error deleting contents:', error);
    }
};

function deleteFile(fileUuid: string, summaryState: boolean, keyWordsState: boolean,argumentID?:string) {
    return new Promise((resolve, reject) => {
        const deleteFileFunction = httpsCallable(functions, "deleteFile-deleteFile");
        deleteFileFunction({
            fileUuid: fileUuid,
            summaryState: summaryState,
            keyWordsState: keyWordsState,
            argumentID: argumentID
        })
            .then(res => {
                if (!(res.data as any).error) {
                    // TO DO: edit this
                    resolve(fileUuid);
                }
                else {
                    console.error("[File services] error returned by deleteFile cloud function:", (res.data as any).error);
                    reject((res.data as any).error);
                }
            })
            .catch(err => {
                console.error("[File services] error deleting file:", err);
                reject(err);
            });
    })
}
function deleteMap(fileUuid: string, argumentID?:string) {
    return new Promise((resolve, reject) => {
        const deleteFileFunction = httpsCallable(functions, "deleteMap-deleteMap");
        deleteFileFunction({
            fileUuid: fileUuid,
            argumentID: argumentID
        })
            .then(res => {
                if (!(res.data as any).error) {
                    // TO DO: edit this
                    resolve(true);
                }
                else {
                    console.error("[File services] error returned by deleteMap cloud function:", (res.data as any).error);
                    reject((res.data as any).error);
                }
            })
            .catch(err => {
                console.error("[File services] error deleting file:", err);
                reject(err);
            });
    })
}


function getOrderedDocuments(ordering: 'ascending' | 'descending' = 'descending') {
    return new Promise<Document[]>((resolve, reject) => {
        apiService.get(`documents/?ordering=${ordering === 'descending' ? '-' : ''}created_at`)
            .then(response => {
                //console.log('[getOrderedDocuments] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[getOrderedDocuments] error:', error.response.data);
                reject(error.response.data);
            });
    })
}

function addTagsToDocument(documentUuid: string, tags: string[]) {
    return new Promise((resolve, reject) => {
        apiService.patch(`documents/${documentUuid}/`, {
            tags: tags,
        })
            .then(response => {
                //console.log('[addTagsToDocument] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[addTagsToDocument] error:', error.response.data);
                reject(error.response.data);
            });
    })
}

function getImages(wordUuid: string) {
    return new Promise<SearchWordImagesResult>((resolve, reject) => {
        // search if that word was already searched
        apiService.get(`word-image-search/?word__uuid=${wordUuid}`)
            .then(response0 => {
                //console.log('[getImage] response:', response0.data);
                if (response0.data && (response0.data.length > 0)) {
                    resolve(response0.data[0])
                }
                else {
                    apiService.post(`word-image-search/`, {
                        word__uuid: wordUuid,
                    })
                        .then(response1 => {
                            //console.log('[getImage] response to the word-image conversion:', response1.data); // for debugging
                            resolve(response1.data[0]);
                        })
                        .catch(err1 => {
                            //console.log('[getImage] error converting image:', err1);
                            reject(err1.response.data);
                        });
                }
            })
            // try to convert the image anyway
            .catch(err0 => {
                //console.log('[getImage] error searching word-image associations:', err0);
                apiService.post(`word-image-search/`, {
                    word__uuid: wordUuid,
                })
                    .then(response2 => {
                        //console.log(`[getImage] response: ${JSON.stringify(response2.data)}`); // for debugging
                        resolve(response2.data[0]);
                    })
                    .catch(err2 => {
                        //console.log('[getImage] error converting image:', err2);
                        reject(err2.response.data);
                    });
            })
    })
}

export function makeId(length: number): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function getConceptualMap(text: string, token: string, docID: string, userUuid: string) {
    return new Promise<any>((resolve, reject) => {
        const ws = new WebSocket('wss://wgm4e9m8g7.execute-api.eu-central-1.amazonaws.com/production/')
        ws.onopen = function (event) {
            console.log('Connection open');
            // Send a message to the server once the connection is open
            ws.send(JSON.stringify({
                action: "startGeneration",
                token: `Bearer ${token}`,
                data: { text: text, docID: docID, userID: userUuid }
            }));
        };
        ws.onmessage = function (event) {
            var data = JSON.parse(event.data);
            if (data.message == "Process started") {
                console.log("processing")
                setLocalStorageItem(true,docID)            
            }
            else if (data.action && data.action === "closeConnection") {
                if (data.message == "Success")
                    resolve("success");
                else
                    resolve("error")
                console.log("Closing connection as per client request.");
                ws.close();
            }
        };
        ws.onerror = function (event) {
        // Retrieve the current array of states from localStorage, or initialize it as an empty array if it doesn't exist
            setLocalStorageItem(false,docID)            
            console.log('Error from server:', event);
            ws.close();
            resolve("error");
        }
    })
}
export function setLocalStorageItem(state:boolean,docID:string){
		// Retrieve the current array of states from localStorage, or initialize it as an empty array if it doesn't exist
        let storedStates = localStorage.getItem("mapState");
        let statesArray;

        try {
            statesArray = storedStates ? JSON.parse(storedStates) : [];
            if (!Array.isArray(statesArray)) {
                statesArray = []; // Ensure statesArray is an array
            }
        } catch (e) {
            statesArray = []; // Fallback to an empty array in case of error
        }

        // Define the new state to be added or updated
        let newState = [state, docID];

        // Check if the docID is already present in the statesArray
        const index = statesArray.findIndex(state => state[1] === docID);

        if (index !== -1) {
            // If the docID exists and the state is different, update the existing state
            if (statesArray[index][0] !== newState[0]) {
                statesArray[index] = newState;
            }
        } else {
            // If the docID does not exist, add the new state to the array of states
            statesArray.push(newState);
        }

        // Store the updated array back in localStorage
        localStorage.setItem('mapState', JSON.stringify(statesArray));

}
export function getLocalStorageItem(){
        // Retrieve the array of states from localStorage
        let storedStates = localStorage.getItem("mapState"); // Make sure the key is consistent with the one you use to store the states
        let statesArray;
    
        try {
            statesArray = storedStates ? JSON.parse(storedStates) : [];
            if (!Array.isArray(statesArray)) {
                statesArray = []; // Ensure statesArray is an array
            }
        } catch (e) {
            statesArray = []; // Fallback to an empty array in case of error
        }
    
        // Iterate over each state and perform your logic
        for (let state of statesArray) {
            if (state[0] === true && state[1] && window.location.href.includes(state[1])) {
                return true;
            }
        }
    
        return false;
    
}
function getConceptualMapFromPDF(url: string, token: string, userUuid: string, fileName: string) {
    return new Promise<any>((resolve, reject) => {
        const wsMapFromPdf = new WebSocket('wss://9yp8atf781.execute-api.eu-central-1.amazonaws.com/production/')
        let docID = generateRandomCode(19)
        wsMapFromPdf.onopen = function (event) {
            console.log('Connection open');
            // Send a message to the server once the connection is open
            wsMapFromPdf.send(JSON.stringify({
                action: "startGeneration",
                token: `Bearer ${token}`,
                data: { pdf_url: url, userID: userUuid, file_name: fileName, docID: docID, fromPdf: true }
            }));
        };
        wsMapFromPdf.onmessage = function (event) {
            var data = JSON.parse(event.data);
            let state = true
            if (data.message == "Process started") {
                console.log("processing")
                localStorage.setItem('mapStateFromFile', JSON.stringify(state));
            }
            else if (data.action && data.action === "closeConnection") {
                state = false
                if (data.message == "Success") {
                    localStorage.setItem('mapStateFromFile', JSON.stringify(state));
                    deleteDirectoryContents(`tf/${userUuid}/file_for_maps/`)
                    resolve(data.docID);
                }
                else {
                    localStorage.setItem('mapStateFromFile', JSON.stringify(state));
                    generateErrorToast.call(this);
                    resolve("error")
                }
                console.log("Closing connection as per client request.");
                wsMapFromPdf.close();
            }
        };
        wsMapFromPdf.onerror = function (event) {
            let state = false
            localStorage.setItem('mapStateFromFile', JSON.stringify(state));
            console.log('Error from server:', event);
            wsMapFromPdf.close();
            resolve("error");
        }
    })
}

function getSummary(text: string, token: string, type: string, docID: string, uID: string) {
    return new Promise<any>((resolve, reject) => {
        console.log("asasa")
        const ws = new WebSocket('wss://k13okf4rog.execute-api.eu-central-1.amazonaws.com/production/')
        console.log("asasa", ws)

        ws.onopen = function (event) {
            console.log('Connection open');
            // Send a message to the server once the connection is open
            ws.send(JSON.stringify({
                action: "startGeneration",
                token: `Bearer ${token}`,
                data: { text: text, type: type, docID: docID, userID: uID }
            }));
            setTimeout(() => { resolve("success"); }, 30000)//TO REMOVE 
        };
        ws.onmessage = function (event) {
            var data = JSON.parse(event.data);
            if (data.message == "Process started") {
                console.log("processing")
            }
            else if (data.action && data.action === "closeConnection") {
                if (data.message == "Success")
                    resolve("success");
                else
                    resolve("error")
                console.log("Closing connection as per client request.");
                ws.close();
            }
        };
        ws.onerror = function (event) {
            console.log('Error from server:', event);
            ws.close();
            resolve("error");
        }
    })

    // return new Promise<any>((resolve, reject) => {
    //     axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/summary/`, {
    //         "Authorization": `Bearer ${token}`,
    //         text: text,
    //         type: type,
    //         docID: docID,
    //         uID: uID
    //     })
    //         .then((response) => {
    //             if (response.data.statusCode === 500 || response.data.statusCode === 401) {
    //                 reject(response.data.statusCode)
    //             }
    //             console.log('[Summary] response:', response.data); // for debugging
    //             resolve(response.data);
    //         })
    //         .catch(error => {
    //             console.error('[Summary] error returned by axios', error);
    //             reject(error.response.data);
    //         });
    // })
}

function getTutorialDoc(userUuid: string, ln: string) {
    return new Promise((resolve, reject) => {
        try {
            if (userUuid) {
                const currentTimestamp = Timestamp.now();
                const unixTimestamp = currentTimestamp.toMillis() / 1000; // Convert to Unix timestamp
                const documentDataQuery = query(
                    collection(firestore, `t`),
                    where("ln", "==", ln)
                );
                getDocs(documentDataQuery)
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // Access the document data here
                            const data = doc.data();
                            addDoc(collection(firestore, "f"), {
                                c: Math.floor(unixTimestamp), // Replace with the appropriate value
                                e: Math.floor(unixTimestamp), // Replace with the appropriate value
                                o: userUuid, // Use the 'o' field from the query data
                                t: data.t, // Use the 't' field from the query data
                                dt: "tutorial",
                            }).then((docRef) => {
                                const documentDataQuery = query(
                                    collection(firestore, `/t/${doc.id}/d`)
                                );
                                getDocs(documentDataQuery).then((querySnapshot) => {
                                    querySnapshot.forEach((doc) => {
                                        const data = doc.data();
                                        const newCollectionRef = collection(
                                            firestore,
                                            "f",
                                            docRef.id,
                                            "d"
                                        ); // Assuming 'doc.id' is the document ID
                                        const newData = {
                                            d: docRef.id,
                                            i: data.i, // Use the 'o' field from the first query data
                                            p: data.p, // Use the 't' field from the first query data
                                            // You can add fields from the second query data here if needed
                                        };

                                        addDoc(newCollectionRef, newData)
                                            .then((newDocRef) => {
                                                // console.log(
                                                //   "Data added to new collection with ID:",
                                                //   newDocRef.id
                                                // );
                                            })
                                            .catch((error) => {
                                                console.error(
                                                    "Error adding data to new collection:",
                                                    error
                                                );
                                            });
                                    });
                                });
                            });
                        });
                    })
                    .catch((error) => {
                        console.error("Error querying document:", error);
                        resolve(error);
                    });
            }
        } catch (error) {
            resolve(error);
        }
    });
}
function generateRandomCode(codeLength: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "FILEPDF";

    for (let i = 0; i < codeLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
    }

    return code;
}
