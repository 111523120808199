export const FlashcardsTranslationsEN = {
    "Clicca sulla carta per vedere la risposta": "Click on the card to read the answer",
    "di": "of",
    "Torna indietro": "Go back",
    "Stiamo preparando il tuo materiale a tema:": "We are preparing your material on the topic:"

}
export const FlashcardsTranslationsIT = {
    "Clicca sulla carta per vedere la risposta": "Clicca sulla carta per vedere la risposta",
    "di": "di",
    "Torna indietro": "Torna indietro",
    "Stiamo preparando il tuo materiale a tema:": "Stiamo preparando il tuo materiale a tema:"


}