import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';
import Profile from './modules/user/pages/Profile';
import FilesManager from './modules/files/pages/FilesManager';
import FileViewer from './modules/files/pages/FileViewer';
import Settings from './modules/settings/pages/Settings';
import CookiePolicy from './modules/legal/pages/CookiePolicy';
import Sketch from './modules/sketch/pages/Sketch';
import PrivacyPolicy from './modules/legal/pages/PrivacyPolicy';
import Support from './modules/user/pages/Support';
import TermsConditions from './modules/legal/pages/TermsConditions';
import Licenses from './modules/legal/pages/Licenses';
import AuthenticationGetStarted from './modules/authentication/pages/AuthenticationGetStarted';
import MindMap from './modules/maps/pages/MindMap';
import AuthenticationCompletion from './modules/authentication/pages/AuthenticationCompletion';
import MagicLinkRequest from './modules/authentication/pages/MagicLinkRequest';
import CredentialsLogin from './modules/authentication/pages/CredentialsLogin';
import CredentialsSignup from './modules/authentication/pages/CredentialsSignup';
import AuthenticationSignupOrSigninChoice from './modules/authentication/pages/AuthenticationSignupOrSigninChoice';
import ForgotPassword from './modules/authentication/pages/ForgotPassword';
import Purchase from './modules/purchase/pages/Purchase';
import PaymentCompleted from './modules/purchase/pages/PaymentCompleted';
import PaymentProcessing from './modules/purchase/pages/PaymentProcessing';
import SummaryOnlyViewer from './modules/files/pages/SummaryOnlyViewer';
import AuthComponent from './extensionLogin';
import SessionsHome from './modules/sessions/pages/SessionsHome';
import SessionDetails from './modules/sessions/pages/SessionDetails';
import Audiobooks from './modules/audiobooks/pages/Audiobooks';
import PurchaseV2 from './modules/purchase/pages/PurchaseV2';
import PaymentSucceded from './modules/purchase/pages/PaymentSucceded';
import Flashcards from './modules/flashcards/pages/Flashcards';

function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth!.userData ? true : false;
    //console.log('[Router] isAuthenticated:', isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <RedirectWithQuery to="/authentication/get-started" />}
        />
    );
}

function RedirectWithQuery({ to }: { to: string }) {
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.toString();
    const targetPath = queryString ? `${to}?${queryString}` : to;

    React.useEffect(() => {
        history.push(targetPath);
    }, [targetPath, history]);

    return null;
}




function Router() {
    return (

        <IonReactRouter>


            <IonRouterOutlet id="main">
                <Switch>

                    {/* <Route path="/signup" component={Registration} exact={true} />
                        <Route path="/login" component={Login} exact={true} /> */}

                    <Route
                        exact path="/authentication/get-started"
                        render={(routeProps: any) => <AuthenticationGetStarted  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/email-password-authentication"
                        render={(routeProps: any) => <AuthenticationSignupOrSigninChoice  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/login-old"
                        render={(routeProps: any) => <CredentialsLogin  {...routeProps} />}
                    />
                    <Route
                        exact path="/authentication/signup-old"
                        render={(routeProps: any) => <CredentialsSignup  {...routeProps} />}
                    />
                    <Route
                        exact path="/authentication/reset-password"
                        render={(routeProps: any) => <ForgotPassword  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/magic-link-request"
                        render={(routeProps: any) => <MagicLinkRequest  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/completion"
                        render={(routeProps: any) => <AuthenticationCompletion  {...routeProps} />}
                    />

                    <Route exact path="/support" component={Support} />

                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <Profile  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/files/my-files"
                        render={(routeProps: any) => <FilesManager  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/maps"
                        render={(routeProps: any) => <MindMap  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/maps/:fileId"
                        render={(routeProps: any) => <MindMap  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path='/file/:fileId'
                        render={(routeProps: any) => <FileViewer  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path='/summary/:fileId'
                        render={(routeProps: any) => <SummaryOnlyViewer  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path='/settings'
                        render={(routeProps: any) => <Settings  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path='/sketch'
                        render={(routeProps: any) => <Sketch  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/purchase"
                        render={(routeProps: any) => <Purchase  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/purchase-v2"
                        render={(routeProps: any) => <PurchaseV2  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/payment-succeded"
                        render={(routeProps: any) => <PaymentSucceded  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/payment-completed"
                        render={(routeProps: any) => <PaymentCompleted  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/processing-payment"
                        render={(routeProps: any) => <PaymentProcessing  {...routeProps} />}
                    />

                    {/* NEW UX EXPERIMENT */}
                    <PrivateRoute
                        exact path="/sessions"
                        render={(routeProps: any) => <SessionsHome  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/session-details"
                        render={(routeProps: any) => <SessionDetails  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/audiobook"
                        render={(routeProps: any) => <Audiobooks  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/flashcards"
                        render={(routeProps: any) => <Flashcards  {...routeProps} />}
                    />

                    <Route path='/legal/cookie-policy' component={CookiePolicy} exact={true} />
                    <Route path='/legal/privacy-policy' component={PrivacyPolicy} exact={true} />
                    <Route path='/legal/terms-conditions' component={TermsConditions} exact={true} />
                    <Route path='/legal/licenses' component={Licenses} exact={true} />

                    <Route path="/login-popup-chromeExtension" component={AuthComponent} />

                    <RedirectWithQuery to="/files/my-files" />
                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;