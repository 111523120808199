import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { FlashcardContent, Flashcards } from "../../sessions/store/reducers";
import { firestore } from "../../../firebaseConfig";

export const flashcardsServices = {
    getFlashcards
};

function getFlashcards(flashcardUuid: string) {
    return new Promise<Flashcards>((resolve, reject) => {
        let flashcards: Flashcards | null = null;
        let flashCardContent: FlashcardContent[] = []
        if (flashcardUuid) {
            const flashcardRef = doc(firestore, `fc/${flashcardUuid}`);
            if (flashcardRef) {
                getDoc(flashcardRef).then(flashcardSnapshot => {
                    if (flashcardSnapshot.exists()) {
                        console.log(flashcardSnapshot.data());

                        flashcards = {
                            uuid: flashcardSnapshot.id,
                            flashcard: flashcardSnapshot.data().f.map((f: any) => ({
                                question: f.q,
                                answer: f.a,
                            })),
                            created_at: flashcardSnapshot.data().c,
                        }
                        resolve(flashcards);
                    } else reject("Flashcard not found");
                });
            }
        } else {
            reject("ERROR: [getFlashcards] No flashcard id present");
        }
    });
}