import {
    IonButton, IonButtons,
    IonHeader, IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPopover,
    IonProgressBar,
    IonText, IonTitle, IonToolbar
} from '@ionic/react';
// import html2pdf from "html2pdf.js";
import { arrowBackOutline, cloudDownloadOutline, ellipsisVertical, helpCircleOutline, trashOutline } from 'ionicons/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';
import { Document, DocumentElement } from '../store/reducers';
import { filesServices } from '../store/services';
import { generatePdf } from '../utils/utils';
import './FileViewerHeader.css';
import { Organization } from '../../user/store/reducers';


type Props = {
    history: any,
    document: Document | null;
    isLoadingFile: boolean;
    documentElements: DocumentElement[]
    showPlayer: boolean;
    fileUID: string,
    argumentID?: string,
    summarySelected: boolean,
    fromSumKey?: boolean,
    keyWordsSelected: boolean,
    mapButtonDisabled: boolean,
    backButtonDisabled: boolean,
    isEditingMode: boolean,
    orgData: Organization | null,
    backButtonBehaviour: () => void;
    popoverButtonClicked: () => void;
    summaryDeleted: (chioice: string, onlySumKey?: boolean) => void;
    pdfReady: (url: string) => void;
    tutorialClicked: () => void;

}

type State = {
    showPopoverButton: boolean | null,
    showTitleLabelPopover: boolean,
    popoverEvent: any,
    isKebabMenu: boolean,
    isDeletingSummary: boolean,
    isLoading: boolean,
    loadingMessage: string,
    downloadUrl: string

}

class FileViewerHeader extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showPopoverButton: null,
            showTitleLabelPopover: false,
            popoverEvent: null,
            isKebabMenu: false,
            isDeletingSummary: false,
            isLoading: false,
            loadingMessage: '',
            downloadUrl: ''
        }
    }
    showPopoverButton(show: boolean) {
        this.setState({ showPopoverButton: show });
    }

    render() {
        const downloadPopoverMobile = (<IonItem
            className='dwPopItem'
            lines='none'
            button={true}
            hidden={((this.props.summarySelected || this.props.keyWordsSelected) && !isMobile)}
            onClick={() => { generatePdf(this, this.props.document) }} detail={false}>
            <IonIcon className='fileViewerButtonIcon' icon={cloudDownloadOutline} />
            <IonText>{this.props.summarySelected && isMobile && <Trans>Scarica riassunto pdf</Trans>}</IonText>
            <IonText>{this.props.keyWordsSelected && isMobile && <Trans>Scarica parole chiave pdf</Trans>}</IonText>
            <IonText>{(!this.props.keyWordsSelected && !this.props.summarySelected) && <Trans>Scarica pdf</Trans>}</IonText>
        </IonItem>)
        const kebabButton = (<IonButtons slot="end" className='kebabMenu' hidden={!this.props.keyWordsSelected && !this.props.summarySelected && !isMobile}>
            <IonButton onClick={() => { this.setState({ isKebabMenu: true }) }}>
                <IonIcon className='fileViewerBackButtonIcon' icon={ellipsisVertical} />
            </IonButton>
        </IonButtons>)
        return (
            <IonHeader id='header' mode='md'>
                <IonToolbar className='fileViewerToolbar'>
                    <IonTitle
                        className='titolo'
                        onClick={(e) => {
                            if (isMobile)
                                this.setState({ showTitleLabelPopover: true, popoverEvent: e.nativeEvent })
                        }}>
                        {this.props.document ? this.props.document.name : ''}
                    </IonTitle>

                    <IonButtons slot="start" className='BackBtn'>
                        <IonButton disabled={this.props.backButtonDisabled} onClick={() => this.props.backButtonBehaviour()}>
                            <IonIcon className='fileViewerBackButtonIcon' icon={arrowBackOutline} />
                        </IonButton>
                    </IonButtons>
                    {
                        /* MERITA users can only download summaries and keywords, not the original files */
                        // (!this.props.summarySelected && !this.props.keyWordsSelected && (this.props.orgData && this.props.orgData?.uuid !== '-NxNg7LxheY6VGbbEsXZ'))
                        // ||
                        // (this.props.orgData && this.props.orgData.uuid === '-NxNg7LxheY6VGbbEsXZ' && this.props.summarySelected || this.props.keyWordsSelected) &&
                        ((this.props.orgData != null && this.props.orgData?.uuid === '-NxNg7LxheY6VGbbEsXZ') && (this.props.summarySelected || this.props.keyWordsSelected)) ?
                            kebabButton : (this.props.orgData == null || this.props.orgData?.uuid !== '-NxNg7LxheY6VGbbEsXZ') ? kebabButton : null

                    }

                    {!isMobile && (
                        <IonIcon
                            slot='end'
                            className="fileViewerTutorialIcon"
                            onClick={() => {
                                this.props.tutorialClicked()
                            }}
                            icon={helpCircleOutline}
                        ></IonIcon>
                    )}
                    <IonLoading message={this.props.keyWordsSelected ? (
                        i18n.language === 'it' ?
                            'Sto cancellando le parole chiave' :
                            i18n.language === 'en' ?
                                'Deleting keywords' :
                                i18n.language === 'es' ?
                                    'Borrando palabras clave' :
                                    i18n.language === 'fr' ? 'Suppression de mots-clés' :
                                        i18n.language === 'ca' ? 'Estic suprimint paraules clau' :
                                            'Ich lösche Schlüsselwörter')
                        :
                        i18n.language === 'it' ?
                            'Sto cancellando il riassunto' :
                            i18n.language === 'en' ?
                                'Deleting summary' :
                                i18n.language === 'es' ?
                                    'Borrando el resumen' :
                                    i18n.language === 'fr' ? 'Suppression du résumé' :
                                        i18n.language === 'ca' ? 'Esborraré el resum' :
                                            'Ich lösche die Zusammenfassung'
                    }
                        isOpen={this.state.isDeletingSummary}
                    />
                    <IonLoading isOpen={this.state.isLoading} message={this.state.loadingMessage} />
                    <IonPopover className='popoverOptions' isOpen={this.state.isKebabMenu} onDidDismiss={() => this.setState({ isKebabMenu: false })}>
                        <IonList className='downloadPopover'>
                            {(this.props.summarySelected || this.props.keyWordsSelected) &&
                                <IonItem
                                    className='dwPopItem'
                                    button={true}
                                    onClick={() => {
                                        this.setState({ isDeletingSummary: true })

                                        console.log(this.props.fromSumKey)
                                        filesServices.deleteFile(this.props.fileUID, this.props.fromSumKey ? this.props.fromSumKey : this.props.summarySelected, this.props.fromSumKey ? this.props.fromSumKey : this.props.keyWordsSelected, this.props.argumentID)
                                            .then(() => {
                                                this.setState({ isDeletingSummary: false, isKebabMenu: false })
                                                this.props.summaryDeleted(this.props.summarySelected === true ? 'sum' : 'key')
                                                // window.location.reload()
                                            })
                                    }}
                                    detail={false}
                                >
                                    <IonIcon className='fileViewerButtonIcon' icon={trashOutline} />
                                    <IonText>{this.props.summarySelected && <Trans>Cancella riassunto</Trans>}</IonText>
                                    <IonText>{this.props.keyWordsSelected && <Trans>Cancella parole chiave</Trans>}</IonText>
                                </IonItem>}
                            {
                                ((this.props.orgData != null && this.props.orgData?.uuid === '-NxNg7LxheY6VGbbEsXZ') && (this.props.summarySelected || this.props.keyWordsSelected)) ?
                                    downloadPopoverMobile : (this.props.orgData == null || this.props.orgData?.uuid !== '-NxNg7LxheY6VGbbEsXZ') ? downloadPopoverMobile : null

                            }
                        </IonList>
                    </IonPopover>
                    {
                        this.props.isLoadingFile &&
                        <IonProgressBar
                            type='indeterminate'
                        />
                    }

                    {/*  <IonButtons slot="end" className='threepointbutton' >
                        <IonButton>
                            <IonIcon icon={ellipsisVerticalOutline} ></IonIcon>
                        </IonButton>
                    </IonButtons> */}

                </IonToolbar>
                <IonPopover
                    isOpen={this.state.showTitleLabelPopover}
                    showBackdrop={false}
                    event={this.state.popoverEvent}
                    onDidDismiss={() => {
                        this.setState({ showTitleLabelPopover: false, })
                    }}
                    onDidPresent={() => {
                        setTimeout(() => {
                            this.setState({ showTitleLabelPopover: false })
                        }, 2000);
                    }}>
                    <IonLabel>{this.props.document?.name}</IonLabel>
                </IonPopover>
            </IonHeader>
        );
    }
}

export default FileViewerHeader;