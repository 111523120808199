import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonButton,
    IonIcon,
    IonSpinner
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import successPayment from "../../../assets/animations/successGreenTick.json";
import loadingShapes from "../../../assets/animations/loadingShapes.json";
import { isPlatform } from '@ionic/core';
import { auth, functions } from '../../../firebaseConfig';
import { fromQueryToObjOfParams } from '../../../common/queryUtils';
import "./PaymentCompleted.css";
import { document, documentOutline, documentTextOutline } from 'ionicons/icons';

type Props = {
    history: any,
};

type State = {
    isProcessingPayment: boolean,
    isPaymentSucceded: boolean,
};

class PaymentSucceded extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isProcessingPayment: false,
            isPaymentSucceded: false,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const sessionId = urlParams.get("session_id");

                if (sessionId) {
                    this.setState({
                        isProcessingPayment: true,
                        isPaymentSucceded: false,
                    });
                    console.log("[Purchase] session id:", sessionId);
                    const retrieveSession = httpsCallable(functions, "retrieveSession-retrieveSession")
                    retrieveSession({
                        sessionId: sessionId,
                    })
                        .then(res => {

                            console.log("[Purchase] retrieve session res:", res.data);
                            if (res.data && (res.data as any).ok) {
                                window.history.pushState({}, 'Reasy', '/payment-succeded');
                                this.setState({
                                    isProcessingPayment: false,
                                    isPaymentSucceded: true,
                                });
                            }
                            else {
                                this.setState({
                                    isProcessingPayment: false,
                                    isPaymentSucceded: false,
                                });
                            }
                        })
                        .catch(err => {
                            this.setState({
                                isProcessingPayment: false,
                                isPaymentSucceded: false,
                            });
                            console.log("[Purchase] error retrieving session:", err);
                        })
                }

            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/sessions" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent className='paymentCompletedContent' fullscreen>
                    <IonGrid className="paymentCompletedMainGrid">
                        {
                            this.state.isProcessingPayment &&
                            <div className='paymentProcessingMainDiv'>
                                <p>Per favore, attendi qualche secondo</p>
                                <p>Stiamo processando il pagamento</p>
                                <IonSpinner style={{ width: "100px", height: "100px" }} name='bubbles'></IonSpinner>
                            </div>
                        }

                        {
                            this.state.isPaymentSucceded &&
                            <div className='paymentCompletedMainDiv'>
                                <div className="paymentCompletedSuccessDiv">
                                    <Player
                                        autoplay
                                        keepLastFrame
                                        src={successPayment}
                                    />
                                </div>
                                <div className='paymentCompletedHeaderDiv'>
                                    <p>Pagamento completato</p>
                                    <p>Grazie per aver scelto Reasy!</p>


                                </div>
                                <div className='paymentCompletedButtonsDiv'>
                                    <a
                                        className='paymentCompletedInvoiceText'
                                        onClick={() => {
                                            window.open('https://forms.gle/ysbDDin5DXPPYRwD9', '_blank')
                                        }}
                                    >
                                        Hai bisogno della fattura? 📄
                                    </a>
                                    <IonButton
                                        className='paymentCompletedCTAButton'
                                        onClick={() => {
                                            this.props.history.push('/sessions')
                                        }}
                                    >
                                        Inizia a studiare con Reasy!
                                    </IonButton>

                                </div>
                            </div>


                        }
                        {!this.state.isPaymentSucceded && !this.state.isProcessingPayment &&
                            <div className='paymentCompletedErrorDiv'>
                                <p>
                                    Errore durante l'assegnazione della licenza
                                </p>
                                <IonButton onClick={() => {
                                    this.props.history.push('/support')
                                }}>Contatta il supporto</IonButton>
                            </div>}

                    </IonGrid>
                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentSuccessful: state.purchases.paymentSuccess,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSucceded);