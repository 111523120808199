import { SettingsTranslationsPT } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsPT } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsPT } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsPT } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsPT } from '../../modules/files/FileTranslations';
import { PopoverTranslationsPT } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsPT } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsPT } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsPT } from '../../modules/maps/MapsTranslations';

const mergedObject = {
    ...SettingsTranslationsPT,
    ...ProfileTranslationsPT,
    ...MenuTranslationsPT,
    ...SketchTranslationsPT,
    ...FileTranslationsPT, /* TODO RENAME "IT" */
    ...PopoverTranslationsPT,
    ...AuthTranslationsPT,
    ...OnboardingTranslationsPT,
    ...MapsTranslationsPT,
};

export const pt = {
    mergedObject,
}