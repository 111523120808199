import { get, ref, update } from 'firebase/database';
import { database } from '../../../firebaseConfig';
import { TTSSettings } from './reducers';

export const ttsServices = {
    getTTSSettings,
    setProsodyRate,
    setVoiceType,
};

function getTTSSettings(userUuid: string) {
    return new Promise<TTSSettings>((resolve, reject) => {
        get(ref(database, `a/${userUuid}/s`))
            .then(snapshot => {
                const ttsSettings = {
                    prosodyRate: snapshot.val() && snapshot.val().r ? snapshot.val().r : "medium",
                    voiceType: snapshot.val() && snapshot.val().t ? snapshot.val().t : "M",
                };
                resolve(ttsSettings);
            })
            .catch(err => {
                console.error("[Settings services getTTSSettings] error:", err);
                reject(err);
            });
    })
}

function setProsodyRate(userUuid: string, prosodyRate: "slow" | "medium" | "fast") { //s -> slow | m -> medium | f -> fast
    return new Promise<TTSSettings>((resolve, reject) => {
        update(ref(database, `a/${userUuid}/s`), {
            r: prosodyRate,
        })
            .then(() => {
                getTTSSettings(userUuid)
                    .then(ttsSettings => {
                        resolve(ttsSettings);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
            .catch(err => {
                console.error('[setProsodyRate] error:', err);
                reject(err);
            });
    })
}

function setVoiceType(userUuid: string, voiceType: "F" | "M") {
    return new Promise<TTSSettings>((resolve, reject) => {
        update(ref(database, `a/${userUuid}/s`), {
            t: voiceType,
        })
            .then(() => {
                getTTSSettings(userUuid)
                    .then(ttsSettings => {
                        resolve(ttsSettings);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
            .catch(err => {
                console.error('[setVoiceType] error:', err);
                reject(err);
            });
    })
}