import { IonButton, IonGrid, IonIcon, IonRow, IonSkeletonText, IonSpinner } from '@ionic/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import Speak from '../../../assets/icons/speechdictionary.svg';
import { auth } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import { ttsServices } from '../../tts/store/services';
import { getTextMeanings } from '../common/dictionaryServices';
import { getTTS } from '../common/ttsServices';
import './Dictionary.css';


type Props = {
    text: string | null,
};

type State = {
    isLoadingTTS: boolean,
    isLoadingMeanings: boolean,

    dictionarySearchResult: string[] | null,
    ttsButtonDisabled: boolean,
    selectedLanguage: string,
};


class Dictionary extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingTTS: false,
            isLoadingMeanings: false,
            selectedLanguage: '',

            dictionarySearchResult: null,
            ttsButtonDisabled: false,


        }
    }
    componentWillUnmount(): void {
        this.setState({ dictionarySearchResult: [] })
    }
    UNSAFE_componentWillMount(): void {
        this.setState({ dictionarySearchResult: [] })
    }
    componentDidMount() {
        if (this.props.text) {
            this.searchWord(this.props.text, this.state.selectedLanguage)
        }
        this.setState({ selectedLanguage: i18n.language, })


    }

    componentDidUpdate(prev: Props) {
        if ((prev.text !== this.props.text) && this.props.text) {
            this.searchWord(this.props.text, this.state.selectedLanguage);
        }
    }

    searchWord(text: string, lang?: string) {
        this.setState({ isLoadingMeanings: true })
        let bearerToken = ""
        auth.currentUser?.getIdToken()
            .then(async token => {
                bearerToken = token
                await getTextMeanings(text, lang ? lang : i18n.language, bearerToken)
                    .then(dictionarySearchResult => {
                        console.log("RESSSS", dictionarySearchResult)
                        this.setState({ dictionarySearchResult: dictionarySearchResult, isLoadingMeanings: false })
                    })
                    .catch(err => {
                        //console.log('[Dictionary] error searching the word with uuid', word.uuid, 'in dictionary. Error:', err);
                    })
            })

    }

    languageChanged(lang: string) {
        this.setState({ selectedLanguage: lang, dictionarySearchResult: [], isLoadingMeanings: true })
        if (this.props.text)
            this.searchWord(this.props.text, lang)
    }
    eraseDictionaryMeanings() {
        this.setState({ dictionarySearchResult: [] })
    }

    render() {
        return (
            <div
                style={{ width: '100%' }}
            /* style={{
                fontFamily: this.state.fontSettings ? settingsServices.fromBackendFontNameToCssFontFamily(this.state.fontSettings.font) : '',
                fontSize: this.state.fontSettings ? this.state.fontSettings.fontSize : '',
            }} */
            >
                <IonGrid>
                    {!isMobile &&
                        <IonRow style={{ height: '50px' }}>
                            <div className='dictionaryWordAndTTSDiv'>
                                <>
                                    <p className='dictionaryWord' style={{ fontSize: '25px', userSelect: "none" }}>
                                        {this.props.text ? <b style={{}}>{this.props.text.toUpperCase()}</b> : ""}
                                    </p>
                                    <IonButton
                                        className='dictionaryWordTTSButton'
                                        fill='clear'
                                        style={{ marginLeft: '50px' }}
                                        onClick={() => {
                                            if (this.props.text) {
                                                this.setState({ isLoadingTTS: true });
                                                ttsServices.getTTSSettings(auth.currentUser!.uid)
                                                    .then(ttsSettings => {
                                                        // console.log("[VEDIAMO I PROPS]",this.props)
                                                        this.setState({ ttsButtonDisabled: true })
                                                        auth.currentUser?.getIdToken()
                                                            .then(async token => {
                                                                await getTTS([], this.props.text!.trim(), ttsSettings)
                                                                    .then((base64Audio) => {
                                                                        let elemAudio = document.createElement('audio');
                                                                        document.body.appendChild(elemAudio);
                                                                        elemAudio.controls = true;
                                                                        elemAudio.src = base64Audio.audio;
                                                                        elemAudio.hidden = true;
                                                                        elemAudio.play();
                                                                        this.setState({ isLoadingTTS: false, ttsButtonDisabled: false });
                                                                    })
                                                                    .catch(err => {
                                                                        console.error("[WordActions] error getting tts:", err);
                                                                        this.setState({ isLoadingTTS: false });
                                                                    });
                                                            })
                                                    })

                                            }
                                        }}
                                    >

                                        {
                                            !this.state.isLoadingTTS &&
                                            <IonIcon
                                                className='icona'
                                                icon={Speak}
                                            />
                                        }

                                        {
                                            this.state.isLoadingTTS &&
                                            <IonSpinner
                                                className="dictionaryLoadingTtsSpinner"
                                            />
                                        }
                                    </IonButton>
                                </>
                            </div>
                        </IonRow>
                    }
                    {/* {!isMobile &&
                        <IonRow>
                            <IonRadioGroup onIonChange={(e) => { this.languageChanged(e.detail.value) }} value={this.state.selectedLanguage}
                                style={{ display: 'inline-flex', maxWidth: '430px', backgroundColor: '#00000000', scale: '0.9', }}
                            >
                                <IonItem className='radiolanguage' lines="none">
                                    <img src='https://flagcdn.com/24x18/it.png' />
                                    <IonRadio slot="end" value="it"></IonRadio>
                                </IonItem>

                                <IonItem className='radiolanguage' lines="none">
                                    <img src='https://flagcdn.com/24x18/gb.png' />
                                    <IonRadio slot="end" value="en"></IonRadio>
                                </IonItem>

                                <IonItem className='radiolanguage' lines="none">
                                    <img src='https://flagcdn.com/24x18/es.png' />
                                    <IonRadio slot="end" value="es"></IonRadio>
                                </IonItem>

                                <IonItem className='radiolanguage' lines="none">
                                    <img src='https://flagcdn.com/24x18/fr.png' />
                                    <IonRadio slot="end" value="fr"></IonRadio>
                                </IonItem>
                            </IonRadioGroup>
                        </IonRow>
                    } */}

                    {/* {isMobile &&
                        <IonRow style={{ height: '70px' }}>
                            <IonRadioGroup onIonChange={(e) => { this.languageChanged(e.detail.value) }} value={this.state.selectedLanguage}
                                style={{ display: 'inline-flex', maxWidth: '430px', backgroundColor: '#00000000', scale: '0.8', heigth: '50px' }}
                            >
                                <IonRow>
                                    <IonItem className='radiolanguage' lines="none">
                                        <IonLabel>IT</IonLabel>
                                        <IonRadio slot="end" value="it"></IonRadio>
                                    </IonItem>

                                    <IonItem className='radiolanguage' lines="none">
                                        <IonLabel>EN</IonLabel>
                                        <IonRadio slot="end" value="en"></IonRadio>
                                    </IonItem>
                                </IonRow>
                                <IonRow>
                                    <IonItem className='radiolanguage' lines="none">
                                        <IonLabel>ES</IonLabel>
                                        <IonRadio slot="end" value="es"></IonRadio>
                                    </IonItem>

                                    <IonItem className='radiolanguage' lines="none">
                                        <IonLabel>FR</IonLabel>
                                        <IonRadio slot="end" value="fr"></IonRadio>
                                    </IonItem>
                                </IonRow>
                            </IonRadioGroup>
                        </IonRow>
                    } */}

                    <IonRow>
                        <div className='dictionaryMeaningsDiv'>
                            <div hidden={!this.state.isLoadingMeanings} className='dictionaryLoadingDiv'>
                                <IonSkeletonText animated className='dictionaryLoadingSpinner' hidden={!this.state.isLoadingMeanings} />
                                <IonSkeletonText animated className='dictionaryLoadingSpinner' hidden={!this.state.isLoadingMeanings} />
                                <IonSkeletonText animated className='dictionaryLoadingSpinner' hidden={!this.state.isLoadingMeanings} />
                            </div>
                            {this.state.dictionarySearchResult && this.state.dictionarySearchResult.length >= 1 &&
                                this.state.dictionarySearchResult.map((result, i) => {
                                    return (
                                        <div key={i}>
                                            {result &&
                                                <>
                                                    <p style={{ fontFamily: 'Nunito Bold' }}><Trans>Definizione</Trans>:</p>
                                                    <p key={this.state.dictionarySearchResult?.indexOf(result)}>{result}</p>
                                                </>
                                            }
                                            {/* {result.example[0] !== '' && result.example.length <= 1 &&
                                            <>
                                                <p><b>Esempio:</b></p>
                                                <p key={this.state.dictionarySearchResult?.indexOf(result)}>{result.example}</p>
                                            </>
                                        } */}
                                        </div>
                                    )
                                })
                            }
                            {this.state.dictionarySearchResult?.length === 0 && !this.state.isLoadingMeanings &&
                                <p><Trans>Non ci sono definizioni</Trans></p>
                            }
                            {/* {
                                this.state.isLoadingMeanings &&
                                <div>
                                    <IonSpinner
                                        color='light'
                                        className="dictionaryLoadingTtsSpinner"
                                    />
                                </div>
                            } */}
                        </div>
                    </IonRow>
                </IonGrid>
            </div>
        );
    }
}

export default Dictionary;