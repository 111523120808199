import { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { isPlatform } from '@ionic/core';
import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonButton, IonLoading, IonIcon, IonGrid, IonRow, IonCol,
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import PurchaseExport from '../components/PurchaseExport';
import PurchaseModeling from '../components/PurchaseModeling';
import PurchaseSessions from '../components/PurchaseSessions';
import PurchaseShare from '../components/PurchaseShare';
import { purchasesActions } from '../store/actions';
import { arrowForward } from "ionicons/icons";
import { functions } from '../../../firebaseConfig';
import keyWord from "../../../assets/icons/keywords.svg"; //!
import flashcards from "../../../assets/icons/flashcardsIcon.svg"; //!

import { store } from '../../../common/storeHandler';
import './Purchase.css';
import map from '../../../assets/icons/mapIcon.svg'
import genSumm from '../../../assets/icons/generateSummary.svg'; //!
import audio from '../../../assets/icons/speechdictionary.svg';
import colorpicker from '../../../assets/icons/eyedropper.svg';
import file from '../../../assets/icons/addPDFButton.svg'
import dictionary from '../../../assets/icons/dictionaryIos.svg'
import personalizedFont from '../../../assets/icons/personalizeFont.svg'
import { error } from 'console';






type Props = {
    history: any,

    purchaseError: () => void,
    paymentIsLoading: (isLoading: boolean) => void,
};

type State = {
    showModal: boolean,
    isLoadingStripe: boolean,

    couponId: string | undefined,

    reasyMonthlyIapProduct: IAPProduct | null,

    showCouponAppliedOnWeb: boolean,
    showHowToApplyDiscountCodeOnAndroid: boolean,
    showHowToApplyDiscountCodeOnIos: boolean,
};



class Purchase extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            isLoadingStripe: false,

            couponId: undefined,

            reasyMonthlyIapProduct: null,

            showCouponAppliedOnWeb: false,
            showHowToApplyDiscountCodeOnAndroid: false,
            showHowToApplyDiscountCodeOnIos: false,
        }

    }

    componentDidMount(): void {

        if (isPlatform("capacitor") || isPlatform("cordova")) {
            const reasyMonthlyIapProduct = store.get("ai.tech4all.reasy.full.monthly");
            this.setState({ reasyMonthlyIapProduct: reasyMonthlyIapProduct });
        }

    }

    render() {
        return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonBackButton defaultHref="/" />
                </IonButtons>
                <IonTitle>Acquista</IonTitle> {/* TO BE LOCALIZED */}
                {(isPlatform("capacitor") || isPlatform("cordova")) && (
                    <IonButtons slot="end">
                      <IonButton
                        className="purchaseIosDiscountHeaderButton"
                        fill="clear"
                        shape="round"
                        onClick={() => {
                          store.redeem();
                          this.props.paymentIsLoading(true);
                          this.props.history.push("/processing-payment");
                        }}
                      >
                        <IonIcon />
                      </IonButton>
                    </IonButtons>
                  )}
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonGrid className="purchaseMainGrid">
                <div className="purchaseMainDiv">
                  <div className="purchaseTitleDiv">
                    <div className="purchaseTitleInnerDiv">
                      <p className="purchaseTitleParagraph">Reasy</p>

                      <div style={{ display: "block" }}>
                        <b>
                          {this.state.reasyMonthlyIapProduct &&
                          this.state.reasyMonthlyIapProduct.price
                            ? this.state.reasyMonthlyIapProduct.price
                            : "€ 6,99"}{" "}
                          al mese
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="purchaseBenefitsDiv">
                    {/* <IonSlides
                                className='purchaseSlides'
                                options={{autoplay: { delay: 4000 }}}
                                pager={true}
                            >
                                <PurchaseModeling />

                                <PurchaseSessions />

                                <PurchaseExport />

                                <PurchaseShare />
                            </IonSlides> */}
                    <table className="custom-table">
                      <tbody>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={map}></IonIcon></td>
                          <td>
                            75 generazioni di mappe concettuali automatiche e personalizzabili
                          </td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={genSumm}></IonIcon></td>
                          <td>75 generazioni di riassunti automatici accessibili</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={keyWord}></IonIcon></td>
                          <td>75 generazioni di parole chiave</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={flashcards}></IonIcon></td>
                          <td>75 generazioni di flashcards</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={audio}></IonIcon></td>
                          <td>75 generazioni di audiolibri</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={dictionary}></IonIcon></td>
                          <td>Dizionario con definizioni e immagini</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={file}></IonIcon></td>
                          <td>Carica qualsiasi documento o immagine</td>
                        </tr>
                        {/* <tr>
                          <td><IonIcon className='tableIcons' icon={colorpicker}></IonIcon></td>
                          <td>Colorazione di lettere o parole</td>
                        </tr>
                        <tr>
                          <td><IonIcon className='tableIcons' icon={personalizedFont}></IonIcon></td>
                          <td>Personalizzazione del font</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  <div className="purchaseButtonsAndTermsDiv">
                    <IonButton
                      onClick={() => {
                        if (isPlatform("capacitor") || isPlatform("cordova")) {
                          store
                            .order("ai.tech4all.reasy.full.monthly")
                            .then((e: any) => {
                              console.log("[Purchase] purchase response:", e);
                              this.props.paymentIsLoading(true);
                              this.props.history.push("/processing-payment");
                            })
                            .error((e: any) => {
                              console.error("[Purchase] purchase error:", e);
                              this.props.purchaseError();
                              this.props.history.push("/processing-payment");
                            });
                        }
                      }}
                      className="purchaseButton"
                      size="large"
                    >
                      Acquista
                    </IonButton>

                    {(isPlatform("capacitor") || isPlatform("cordova"))  && (
                      <div
                        className="purchaseRestorePurchasesDiv"
                        onClick={() => {
                          store.refresh();
                        }}
                      >
                        Ripristina acquisti {/* TO BE LOCALIZED */}
                      </div>
                    )}


                    <div className="purchaseTermsDiv">
                      <p>
                        L'abbonamento viene rinnovato automaticamente alla fine
                        del periodo di fatturazione.
                        <br />
                        Per maggiori dettagli, leggere la nostra{" "}
                        <a
                          href="https://tech4all-ai.notion.site/Payment-policy-2017d47b36ea4e2ab72d0f2a1c0a55cc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Payment Policy
                        </a>{" "}
                        e la{" "}
                        <a
                          href="https://app.reasyedu.com/legal/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                      {(isPlatform("capacitor") || isPlatform("cordova")) &&
                        isPlatform("ios") && (
                          <p>
                            You can modify or cancel our subscription by going
                            to the user's iTunes Account Settings after
                            purchase.
                            <br />
                            Any unused portion of a free trial period, if
                            offered, will be forfeited when the user purchases a
                            subscription to that publication.
                            <br />
                            Payment will be charged to iTunes Account at
                            confirmation of purchase.
                          </p>
                        )}

                      {(isPlatform("capacitor") || isPlatform("cordova")) &&
                        isPlatform("android") && (
                          <p>
                            You can modify or cancel your subscription by going
                            to the user's Play Service Account Settings after
                            purchase.
                            <br />
                            Any unused portion of a free trial period, if
                            offered, will be forfeited when the user purchases a
                            subscription to that publication.
                            <br />
                            Payment will be charged to Play Service Account at
                            confirmation of purchase.
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </IonGrid>
            </IonContent>

            <IonLoading
              message={
                "Sarai reindirizzato/a alla pagina di pagamento"
              } /* TO LOCALIZE THIS */
              isOpen={this.state.isLoadingStripe}
            />
          </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        purchaseError: () => {
            dispatch(purchasesActions.error());
        },

        paymentIsLoading: (isLoading: boolean) => {
            dispatch(purchasesActions.loading(isLoading));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);