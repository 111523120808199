import { IonIcon, IonTabBar, IonTabButton } from '@ionic/react';
import { homeOutline, personOutline, settingsOutline } from 'ionicons/icons';
import React from 'react';
import { isTablet } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './MenuTabBar.css';


type Props = {
    profile: any;
    settings: any;
    home: any;

}

type State = {

}

class MenuTabBar extends React.Component<Props, State>{
    render() {
        return (
            <IonTabBar className={'menuTabBar'} slot="bottom" onClick={() => { console.log("tab bar principale") }}>

                <IonTabButton  className={'menuTabBarButton profileTabBar'} tab="shared" href="/profile" >
                    <IonIcon id="tabBarProfile" icon={this.props.profile === undefined ? personOutline : this.props.profile} />
                    {isTablet &&
                        <p className='ToolBarLabelTablet'><Trans>Profilo</Trans></p>
                    }
                </IonTabButton>

                <IonTabButton onClick={()=>{
                    var storedValue = localStorage.getItem('isPayWallClose');
                    // Check if the value exists in localStorage
                    var isPayWallClose = storedValue !== null ? JSON.parse(storedValue) : false;
                    if(isPayWallClose){
                        localStorage.setItem('isPayWallClose', JSON.stringify(false))
                        setTimeout(()=>window.location.reload(),200);
                    }

                }} className={'menuTabBarButton homeTabBar'} tab="starred" href="/files/my-files" >
                    <IonIcon id="tabBarHome" icon={this.props.home === undefined ? homeOutline : this.props.home} />
                    {isTablet &&
                        <p className='ToolBarLabelTablet'><Trans>Home</Trans></p>
                    }
                </IonTabButton>

                <IonTabButton className={'menuTabBarButton settingsTabBar'} tab="myfiles" href="/settings">
                    <IonIcon icon={this.props.settings === undefined ? settingsOutline : this.props.settings} />
                    {isTablet &&
                        <p className='ToolBarLabelTablet'><Trans>Configurazioni</Trans></p>
                    }
                </IonTabButton>


            </IonTabBar>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuTabBar);