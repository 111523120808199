import { SettingsTranslationsES } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsES } from '../../modules/user/ProfileTranslations';
import { MapsTranslationsES } from '../../modules/maps/MapsTranslations';
import { MenuTranslationsES } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsES } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsES } from '../../modules/files/FileTranslations';
import { PopoverTranslationsES } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsES } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsES } from '../../modules/onboarding/OnboardingTranslations';

const mergedObject = {
  ...SettingsTranslationsES,
  ...ProfileTranslationsES,
  ...MapsTranslationsES,
  ...FileTranslationsES,
  ...MenuTranslationsES,
  ...PopoverTranslationsES,
  ...SketchTranslationsES,
  ...AuthTranslationsES,

};

export const es = {
  mergedObject,
}