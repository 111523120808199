import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonRange,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToggle,
	IonToolbar,
	isPlatform,
} from "@ionic/react";
import { ref, update } from "firebase/database";
import {
	add,
	chevronBack,
	chevronDownOutline,
	chevronForward,
	chevronForwardOutline,
	closeOutline,
	ear,
	globeOutline,
	helpCircleOutline,
	informationCircleOutline,
	remove,
	removeCircleOutline,
	settings as settingsIcon,
	textOutline
} from "ionicons/icons";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Trans } from "react-i18next";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import { sendEvent } from "../../../common/amplitudeUtils";
import { fromQueryToObjOfParams } from "../../../common/queryUtils";
import { Step, handleJoyrideCallback, updateChoice } from "../../../common/utilsJoyride";
import { auth, database } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { loadPaywall } from "../../../loadPaywall";
import { setTheme, themes } from "../../../theme/theme";
import MenuTabBar from "../../menu/MenuTabBar";
import SideMenu from "../../menu/SideMenu";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import "../../user/pages/Profile.css";
import { Organization } from "../../user/store/reducers";
import { DocumentFontSettings, Highlight, settingsServices } from "../store/services";
import "./Settings.css";
import { hslToHex, saveColor, updateColor } from "./utilsColorPickerModal"; // Adjust the import path as needed

type Props = {
	history: any;
	settings: any;
	orgData: Organization | null;
};

type State = {
	highlightings: Highlight[];
	isHighlightingActive: boolean;
	documentFontSettings: DocumentFontSettings | null;
	ttsSettings: TTSSettings | null;
	text: boolean;

	highlightTargetText: string;
	showColorPicker: boolean;
	pickedColor: string;

	preferredPalette: string;
	///
	userUuid: string;

	showModalLanguage: boolean;
	paywallState: boolean;
	isColorPickerOpen: boolean;
	hue: number;
	saturation: number;
	brightness: number;
	previewColor: string;
	savedColors: string[];
	recentColors: string[];

	steps: Step[];
	tutorial: boolean;
	stepIndex: number;
};

class Settings extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			highlightings: [],
			isHighlightingActive: false,
			documentFontSettings: null,
			ttsSettings: null,

			highlightTargetText: "",
			showColorPicker: false,
			pickedColor: "#0079BF",

			preferredPalette: "",
			text: false,
			///
			userUuid: "",

			showModalLanguage: false,
			paywallState: false,
			isColorPickerOpen: false,
			hue: 0,
			saturation: 100,
			brightness: 50,
			previewColor: "#808080",
			savedColors: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
			recentColors: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],

			steps: [
				{
					target: '.fontSection',
					content: (
						<div>
							<p><Trans>Scegli il tipo di font, la sua dimensione e l'interlinea del testo</Trans></p>
						</div>
					),
					disableBeacon: true,
					disableScrollParentFix: true,
					placement: "auto",
				},
				{
					target: '.highlightSection',
					content: (
						<div>
							<p><Trans>Personalizza i colori di lettere o parole specifiche nel testo</Trans></p>
							{/* <video className='tutorialVideo' autoPlay controls controlsList="nodownload">
								<source
									src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/6.+Colorazione+lettere_parole.mp4"
									type="video/mp4"
								/>
								Your browser does not support the video tag.
							</video> */}

						</div>
					),
					disableBeacon: true,
					placement: "auto",
				},
				{
					target: '.previewSection',
					content: (
						<div>
							<p><Trans>Guarda l'anteprima delle configurazioni che hai scelto</Trans></p>

						</div>
					),
					disableBeacon: true,
					placement: "auto",
				},
				{
					target: '.ttsSection',
					content: <div>
						<p><Trans>Scegli la velocità e il tipo di voce della sintesi vocale</Trans></p>

					</div>,
					disableBeacon: true,
					placement: "auto",

				},
				{
					target: '.colorChange',
					content: <div>
						<p><Trans>Cambia i colori dello sfondo di Reasy</Trans></p>

					</div>,
					disableBeacon: true,
					placement: "auto",
				},


			],
			tutorial: false,
			stepIndex: 0,
		};
	}


	componentDidMount() {
		auth.onAuthStateChanged(async (userData) => {
			if (userData) {
				this.setState({ userUuid: userData.uid });
				await settingsServices
					.getDocumentViewingSettings(userData.uid)
					.then((documentViewingSettings) => {
						this.setState({ documentFontSettings: documentViewingSettings });
					})
					.catch((err) => {
						console.error("[Settings] error getting viewing settings", err);
					});
				await ttsServices
					.getTTSSettings(userData.uid)
					.then((ttsSettings) => {
						this.setState({ ttsSettings: ttsSettings });
					})
					.catch((err) => {
						console.log("[Settings] error getting TTS settings", err);
					});

				await settingsServices.getPreferredPalette(userData.uid).then((preferred) => {
					this.setState({ preferredPalette: preferred.preferredPalette });
				});
				await settingsServices.getColorSamples(userData.uid).then((colors) => {
					this.setState({ savedColors: colors.sampleColors }, () => {
						console.log(this.state.savedColors);
					});
				});

				await settingsServices
					.getHighlightings(userData.uid)
					.then((highlightings) => {
						this.setState({ highlightings: highlightings });
					})
					.catch((err) => {
						console.error("[Settings] error getting highlightings:", err);
					});

				await settingsServices
					.getHighlightingsActive(userData.uid)
					.then((isActive) => {
						this.setState({ isHighlightingActive: isActive });
					})
					.catch((err) => {
						console.error("[Settings] error getting highlightings active:", err);
					});



				// if (!isMobile)
				// 	await settingsServices.getUserTutorialChoice(this.state.userUuid, "tS").then((response) => {
				// 		this.setState({ tutorial: response });
				// 		if (response === true)
				// 			sendEvent({
				// 				"user_id": this.state.userUuid,
				// 				"event_type": "Tutorial showed",
				// 				"event_properties": {
				// 					"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
				// 					"page": "Settings",
				// 				},
				// 				"language": i18n.language,
				// 				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				// 				"app_version": appVersion,
				// 				"time": Date.now(),
				// 			});
				// 	});
				// const paywallShowOrHide = await loadPaywall(false);
				// this.setState({ paywallState: paywallShowOrHide });
			} else {
				this.setState({ userUuid: "" });
			}
		});

		if (this.props.history.location.search !== "") {
			const ltm = fromQueryToObjOfParams(this.props.history.location.search as string)["ltm"];
			this.setState({
				highlightTargetText: ltm,
			});
			(document.getElementById("lettersToMarkInput") as HTMLIonInputElement).focus();
		}
	}

	playAudio() {
		let gender = this.state.ttsSettings?.voiceType === "F" ? "Female" : "Male";
		let prosody_rate = this.state.ttsSettings?.prosodyRate === "slow" ? "Slow" : this.state.ttsSettings?.prosodyRate === "medium" ? "Medium" : "Fast";
		let language = i18n.language === "en" ? "EN" : i18n.language === "fr" ? "FR" : i18n.language === "es" ? "ES" : i18n.language === "de" ? "DE" : i18n.language === "ca" ? "ES" : "IT";
		let src = require("../../../assets/speech-samples/" + gender + prosody_rate + language + ".mp3");
		let audio = new Audio(src);
		audio.play();
	}

	getNextFontOption(direction: "right" | "left") {
		let font_list: any = ["open_dyslexic", "arial", "verdana", "georgia", "tahoma"];
		let currentFont = this.state.documentFontSettings?.font;
		if (currentFont) {
			let currentFontIndex = font_list.indexOf(currentFont);
			if (direction === "right") {
				if (currentFontIndex === 4) {
					return font_list[0];
				} else return font_list[currentFontIndex + 1];
			} else {
				if (currentFontIndex === 0) {
					return font_list[4];
				} else return font_list[currentFontIndex - 1];
			}
		}
	}

	handleFocusInput = () => {
		// Assuming you want to modify the CSS custom property of the IonInput or its container
		document.getElementById("lettersToMarkInput")?.style.setProperty("--highlight-color-focused", this.state.pickedColor);
	};
	saveFontSize = (newValue: number) => {
		settingsServices
			.editFontSize(this.state.userUuid, newValue)
			.then((documentViewingSettings) => {
				//console.log('[Settings] editing font size success', documentViewingSettings);
				this.setState({ documentFontSettings: documentViewingSettings });
			})
			.catch((err) => {
				//console.log('[Settings] error editing font size:', err);
			});
	};

	saveLineSpacing = (newValue: number) => {
		settingsServices
			.editLineLeading(this.state.userUuid, newValue)
			.then((documentViewingSettings) => {
				//console.log('[Settings] editing line leading success', documentViewingSettings);
				this.setState({ documentFontSettings: documentViewingSettings });
			})
			.catch((err) => {
				console.error("[Settings] error editing line leading:", err);
			});
	};
	changeAppLanguage(lang: string) {
		i18n.changeLanguage(lang);
		if (this.state.userUuid) {
			update(ref(database, `u/${this.state.userUuid}`), {
				l: lang,
			})
				.then(() => {
					window.location.reload();
				})
				.catch((err) => {
					console.error("[Profile] error updating user language:", err);
				});
		}
	}
	render() {
		return (
			<IonPage id="settingsPage" className="settingsMainPage">
				{!isMobile && (
					<IonIcon
						slot="end"
						className="settingsHelpIcon"
						style={{ fontSize: "45px" }}
						onClick={(e) => {
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
										"page": "Settings"
									},
									"language": i18n.language,
									"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
									"app_version": appVersion,
									"time": Date.now()
								})
							});
						}}
						icon={helpCircleOutline}
					></IonIcon>
				)}
				{!isMobile && (
					<SideMenu
						userDocuments={null}
						userUuid={this.state.userUuid}
						history={this.props.history}
						sideMenuSetting={{ settingsIcon: settingsIcon, color: "var(--callToAction-color)" }}
						sideMenuProfile={{ profileIcon: undefined, color: undefined }}
						sideMenuHome={{ homeIcon: undefined, color: undefined }}
						orgData={this.props.orgData}
					/>
				)}
				<IonButtons
					hidden={isMobile}
					id="sideMenuBtn"
					className="sideMenuBtn"
					slot="start"
					onClick={() => {
						if (window.location.pathname === "/settings") {
							document.getElementById("settingsItem")!.style.color = "var(--callToAction-color)";
							document.getElementById("settingsItem")!.style.fontWeight = "600";
						}
					}}
				>
					{!isMobile && (
						<div className="sideMenuSettings">
							<IonMenuButton className="sideMenuBtnBurger" menu="sideMenu" />
						</div>
					)}
				</IonButtons>

				<IonHeader mode="ios" className={"settingsHeaderTablet"} hidden={!isMobile}>
					<IonToolbar hidden={!isMobile} className="homeHeaderToolbar">
						{this.state.text && (
							<IonItem
								className="headerItemBackToPagePrinc"
								onClick={() => {
									this.setState({ text: false });
								}}
							>
								<IonIcon icon={chevronBack}></IonIcon>
								<IonLabel hidden={isMobile} style={{ fontSize: "128%", paddingLeft: "1vw", fontFamily: "nunito" }}>
									Configurazioni
								</IonLabel>
							</IonItem>
						)}
						<p className={isTablet ? "settingsHeaderTitleTablet" : "settingsHeaderTitle"}>
							{!this.state.text && <Trans>Configurazioni</Trans>}
							{this.state.text && <Trans>Testi</Trans>}
						</p>
						{(isMobile && this.state.text) && (
							<IonIcon
								slot="end"
								// className="helpIcon"
								style={{ fontSize: "35px" }}
								onClick={(e) => {
									this.setState({ tutorial: true }, () => {
										sendEvent({
											"user_id": this.state.userUuid,
											"event_type": "Tutorial opened",
											"event_properties": {
												"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
												"page": "Settings"
											},
											"language": i18n.language,
											"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
											"app_version": appVersion,
											"time": Date.now()
										})
									});
								}}
								icon={helpCircleOutline}
							></IonIcon>
						)}
					</IonToolbar>
				</IonHeader>

				<Joyride
					steps={this.state.steps}
					run={this.state.tutorial} // Set to true to start the tour automatically
					continuous={true} // Allow continuous navigation through the tour
					showProgress={true} // Display progress indicator
					showSkipButton={true} // Display skip button
					spotlightClicks={true}
					callback={(data) => handleJoyrideCallback.call(this, data, "Settings")}
					hideBackButton={false}
					locale={{
						skip: (
							<div className="skip" onClick={() => { updateChoice.call(this, "tutorialSettings") }}>
								<Trans>Salta</Trans>
							</div>
						),
						back: (
							<div className="nextTutorialBtn">
								<Trans>Precedente</Trans>
							</div>
						),
						next: (
							<span>
								<Trans>Avanti</Trans>
							</span>
						),
						last: (
							<div className="nextTutorialBtn"
								onClick={() => { updateChoice.call(this, "tutorialSettings") }}>
								<Trans>Ultimo</Trans>
							</div>
						),
					}}
					styles={{
						buttonNext: {
							fontSize: "12px",
							outline: "none",
						},
					}}
				/>

				{isMobile && !this.state.text && (
					<IonGrid class="gridTablet">
						<IonCard className="cardBgTablet">
							<IonCardContent>
								<div>
									<IonItem
										onClick={() => {
											this.setState({ showModalLanguage: true });
										}}
										className="itemSettingsTablet"
										lines="none"
									>
										<IonLabel className="labelSettingsTablet">
											<IonIcon className="iconSettingsTablet" slot="start" icon={globeOutline}></IonIcon>
											<Trans>Lingue</Trans>
										</IonLabel>

										<IonLabel className="fontstyle" style={{ textAlign: "right" }}>
											{i18n.language === "it"
												? "Italiano"
												: i18n.language === "en"
													? "English"
													: i18n.language === "fr"
														? "Français"
														: i18n.language === "es"
															? "Español"
															: i18n.language === "ca"
																? "Català"
																:i18n.language === "de"
																? "Deutsch"
																:i18n.language === "nl"
																? "Olandese":
																	"Czech"}
										</IonLabel>
										<IonIcon className="iconSettingsTablet" slot="end" icon={chevronDownOutline}></IonIcon>
									</IonItem>

									<IonModal
										isOpen={this.state.showModalLanguage}
										onDidDismiss={() => this.setState({ showModalLanguage: false })}
										className="settingsModalLanguageTablet"
									>
										<IonItem lines="none" className="modalTitle">
											<IonLabel className="settingsModalDescriptionTablet">
												<Trans>Seleziona una lingua</Trans>
											</IonLabel>
										</IonItem>
										<IonIcon
											className="settingsModalDescriptionIconTablet"
											icon={closeOutline}
											onClick={() => {
												this.setState({ showModalLanguage: false });
											}}
										></IonIcon>

										<div className="settingsModalLanguagesSelectionTablet">
											<IonItem
												onClick={() => {
													this.changeAppLanguage("it");
												}}
												className="settingsModalLanguagesItemTablet"
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Italiano</IonLabel>
											</IonItem>

											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("en");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">English</IonLabel>
											</IonItem>

											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("fr");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Français</IonLabel>
											</IonItem>

											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("es");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Español</IonLabel>
											</IonItem>

											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("ca");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Català</IonLabel>
											</IonItem>
											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("de");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Deutsch</IonLabel>
											</IonItem>
											<IonItem
												className="settingsModalLanguagesItemTablet"
												onClick={() => {
													this.changeAppLanguage("nl");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Olandese</IonLabel>
											</IonItem>
											<IonItem
												className="settingsModalLanguagesItemTablet"
												lines="none"
												style={{ "--inner-border-width": "0px" }}
												onClick={() => {
													this.changeAppLanguage("cz");
												}}
											>
												<IonLabel className="settingsModalLanguagesLabelTablet">Czech</IonLabel>
											</IonItem>
										</div>
									</IonModal>

									<IonItem
										className="itemSettingsTablet"
										style={{ marginTop: "20px" }}
										onClick={() => {
											this.setState({ text: true });
											// setTimeout(() => {
											// 	settingsServices.getUserTutorialChoice(this.state.userUuid, "tS").then((response) => {
											// 		this.setState({ tutorial: response }, () => {
											// 			if (response === true)
											// 				sendEvent({
											// 					"user_id": this.state.userUuid,
											// 					"event_type": "Tutorial showed",
											// 					"event_properties": {
											// 						"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
											// 						"page": "Settings"
											// 					},
											// 					"language": i18n.language,
											// 					"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
											// 					"app_version": appVersion,
											// 					"time": Date.now()
											// 				})
											// 		});
											// 	});
											// }, 1000);
										}}
									>
										<IonLabel className="labelSettingsTablet">
											<IonIcon className="iconSettingsTablet" slot="start" icon={textOutline}></IonIcon>
											<Trans>Testi</Trans>
										</IonLabel>
										<IonIcon className="iconSettingsTablet" slot="end" icon={chevronForwardOutline}></IonIcon>
									</IonItem>
								</div>
							</IonCardContent>
						</IonCard>
					</IonGrid>
				)}
				{(!(isMobile || isTablet) || this.state.text) && (
					<IonGrid className="settingsGrid" id="openSideMenu">
						<div hidden={isMobile} className="settingsHeaderDiv">
							<IonTitle hidden={isMobile} className="settingsHeader">
								<Trans>Configurazioni</Trans>
							</IonTitle>
						</div>
						<div className="settingsCard fontSection">
							<p className="settingsSectionParagraph">
								<Trans>Configurazioni font</Trans>
							</p>
							<IonCardContent mode="ios" className="fontCard">
								<IonList>
									<IonItem className="settingsDocumentViewingItem" lines="none">
										<div className="settingsDocumentViewingItemDiv">
											{/* <div className="tooltip">
												<p className="settingsDocumentViewingParagraph">
													<Trans>Font</Trans>
													<IonIcon className="tooltip" icon={informationCircleOutline}></IonIcon>
												</p>
												<div className="bottom">
													<Trans>L'app Reasy utilizza anche il carattere ad alta leggibilità </Trans>
													<a href="https://www.easyreading.it" target="_blank" rel="noopener noreferrer">
														EasyReading / Dyslexia Friendly ©
													</a>
													.
												</div>
											</div> */}

											<div className="settingsFontDiv">
												<IonIcon
													className="settingsFontArrowButton"
													onClick={() => {
														let fromButton = true;
														if (this.state.paywallState) loadPaywall(fromButton);
														else if (this.state.userUuid) {
															let fontToSet = this.getNextFontOption("left");
															settingsServices
																.editFont(this.state.userUuid, fontToSet)
																.then((documentViewingSettings) => {
																	this.setState({ documentFontSettings: documentViewingSettings });
																})
																.catch((err) => {
																	console.error("[Settings] Error changing font:", err);
																});
														}
													}}
													size="large"
													slot="start"
													icon={chevronBack}
												/>
												<p hidden={this.state.documentFontSettings?.font !== "open_dyslexic"}>OpenDyslexic</p>
												{/* <p hidden={this.state.documentFontSettings?.font !== "easy_reading"}>EasyReading</p> */}
												<p hidden={this.state.documentFontSettings?.font !== "arial"}>Arial</p>
												<p hidden={this.state.documentFontSettings?.font !== "verdana"}>Verdana</p>
												<p hidden={this.state.documentFontSettings?.font !== "georgia"}>Georgia</p>
												<p hidden={this.state.documentFontSettings?.font !== "tahoma"}>Tahoma</p>
												<IonIcon
													className="settingsFontArrowButton"
													onClick={() => {
														let fromButton = true;
														if (this.state.paywallState) loadPaywall(fromButton);
														else if (this.state.userUuid) {
															let fontToSet = this.getNextFontOption("right");
															settingsServices
																.editFont(this.state.userUuid, fontToSet)
																.then((documentViewingSettings) => {
																	this.setState({ documentFontSettings: documentViewingSettings });
																})
																.catch((err) => {
																	console.error("[Settings] Error changing font:", err);
																});
														}
													}}
													size="large"
													slot="end"
													icon={chevronForward}
												/>
											</div>
										</div>
									</IonItem>

									<IonItem className="settingsDocumentViewingItem" lines="none" style={{ zIndex: -1000 }}>
										<div className="settingsDocumentViewingItemDiv">
											<p className="settingsDocumentViewingParagraph">
												<Trans>Dimensione font</Trans>
											</p>
											<div className="settingsDocumentViewingButtonsDiv">
												<p className="settingsFontSizeParagraph">{this.state.documentFontSettings?.fontSize} px</p>
												<div className="settingsDocumentViewingRangeDiv">
													<IonButton
														className="settingsDocumentViewingRangeButton"
														fill="clear"
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
															else {
																const fontSizeInput: HTMLIonRangeElement = document.getElementById("settingsFontSizeInput") as HTMLIonRangeElement;
																if (fontSizeInput.value) {
																	// Convert the value to a number since it might be returned as a string
																	const newValue = Number(fontSizeInput.value) - 2;

																	// Ensure the new value is greater than 0 before attempting to update the state
																	if (newValue > 0) {
																		this.setState(
																			(prevState) => ({
																				documentFontSettings: {
																					...prevState.documentFontSettings,
																					fontSize: newValue,
																					font: prevState.documentFontSettings?.font || "defaultFont", // Fallback to 'defaultFont'
																					lineLeading: prevState.documentFontSettings?.lineLeading || 1.5, // Fallback to 1.5
																				},
																			}),
																			() => {
																				this.saveFontSize(newValue);
																			}
																		);
																	}
																}
															}
														}}
													>
														<IonIcon className="fontSizeIcons" size="large" icon={remove} />
													</IonButton>

													{/* <IonInput
													id='settingsFontSizeInput'
													className='settingsNumericInput'
													type='number'
													value={this.state.documentFontSettings ? this.state.documentFontSettings.fontSize : 30}
													debounce={300}
													onIonChange={(e) => {
														if (this.state.documentFontSettings && this.state.userUuid) {
															settingsServices.editFontSize(this.state.userUuid, Number(e.detail.value))
																.then(documentViewingSettings => {
																	//console.log('[Settings] editing font size success', documentViewingSettings);
																	this.setState({ documentFontSettings: documentViewingSettings });
																})
																.catch(err => {
																	//console.log('[Settings] error editing font size:', err);
																})
														}
													}}
												/> */}
													<IonRange
														id="settingsFontSizeInput"
														min={10}
														step={2}
														max={30}
														ticks={false}
														snaps
														value={this.state.documentFontSettings?.fontSize}
														debounce={300}
														onIonChange={(e) => {
															if (this.state.documentFontSettings && this.state.userUuid) {
																this.saveFontSize(Number(e.detail.value));
															}
														}}
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
														}}
													/>

													<IonButton
														className="settingsDocumentViewingRangeButton"
														fill="clear"
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
															else {
																const fontSizeInput: HTMLIonRangeElement = document.getElementById("settingsFontSizeInput") as HTMLIonRangeElement;
																if (fontSizeInput.value) {
																	// Convert the value to a number since it might be returned as a string
																	const newValue = Number(fontSizeInput.value) + 2;

																	// Ensure the new value is greater than 0 before attempting to update the state
																	if (newValue > 0) {
																		this.setState(
																			(prevState) => ({
																				documentFontSettings: {
																					...prevState.documentFontSettings,
																					fontSize: newValue,
																					font: prevState.documentFontSettings?.font || "defaultFont", // Fallback to 'defaultFont'
																					lineLeading: prevState.documentFontSettings?.lineLeading || 1.5, // Fallback to 1.5
																				},
																			}),
																			() => {
																				this.saveFontSize(newValue);
																			}
																		);
																	}
																}
															}
														}}
													>
														<IonIcon className="fontSizeIcons" size="large" icon={add} />
													</IonButton>
												</div>
											</div>
										</div>
									</IonItem>

									<IonItem className="settingsDocumentViewingItem" lines="none">
										<div className="settingsDocumentViewingItemDiv">
											<p className="settingsDocumentViewingParagraph">
												<Trans>Interlinea</Trans>
											</p>
											<div className="settingsDocumentViewingButtonsDiv">
												<p className="settingsFontSizeParagraph">{this.state.documentFontSettings?.lineLeading}</p>
												<div className="settingsDocumentViewingRangeDiv">
													<IonButton
														fill="clear"
														className="settingsDocumentViewingRangeButton"
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
															else {
																const lineLeadingInput: HTMLIonRangeElement = document.getElementById("settingsLineLeadingInput") as HTMLIonRangeElement;
																if (lineLeadingInput.value && (lineLeadingInput.value as number) - 5 > 0) {
																	lineLeadingInput.value = this.state.documentFontSettings!.lineLeading - 5;
																	if (lineLeadingInput.value) {
																		// Convert the value to a number since it might be returned as a string
																		const newValue = Number(lineLeadingInput.value) - 5;

																		// Ensure the new value is greater than 0 before attempting to update the state
																		if (newValue > 0) {
																			this.setState(
																				(prevState) => ({
																					documentFontSettings: {
																						...prevState.documentFontSettings,
																						fontSize: prevState.documentFontSettings?.fontSize || 16,
																						font: prevState.documentFontSettings?.font || "defaultFont", // Fallback to 'defaultFont'
																						lineLeading: newValue, // Fallback to 1.5
																					},
																				}),
																				() => {
																					this.saveLineSpacing(newValue);
																				}
																			);
																		}
																	}
																}
															}
														}}
													>
														<IonIcon size="large" className="fontSizeIcons" icon={remove} />
													</IonButton>

													{/* <IonInput
														id='settingsLineLeadingInput'
														className='settingsNumericInput'
														type='number'
														value={this.state.documentFontSettings ? this.state.documentFontSettings.lineLeading : 0}
														debounce={300}
														onIonChange={(e) => {
															if (this.state.documentFontSettings && this.state.userUuid) {
																settingsServices.editLineLeading(this.state.userUuid, Number(e.detail.value))
																	.then(documentViewingSettings => {
																		//console.log('[Settings] editing line leading success', documentViewingSettings);
																		this.setState({ documentFontSettings: documentViewingSettings });
																	})
																	.catch(err => {
																		console.error('[Settings] error editing line leading:', err);
																	})
															}
														}}
													/> */}
													<IonRange
														id="settingsLineLeadingInput"
														min={10}
														step={5}
														max={150}
														ticks={false}
														snaps
														value={this.state.documentFontSettings?.lineLeading}
														debounce={300}
														onIonChange={(e) => {
															if (this.state.documentFontSettings && this.state.userUuid) {
																this.saveLineSpacing(Number(e.detail.value));
															}
														}}
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
														}}
													/>

													<IonButton
														fill="clear"
														className="settingsDocumentViewingRangeButton"
														onClick={() => {
															let fromButton = true;
															if (this.state.paywallState) loadPaywall(fromButton);
															else {
																const lineLeadingInput: HTMLIonRangeElement = document.getElementById("settingsLineLeadingInput") as HTMLIonRangeElement;
																if (lineLeadingInput.value) {
																	// Convert the value to a number since it might be returned as a string
																	const newValue = Number(lineLeadingInput.value) + 5;

																	// Ensure the new value is greater than 0 before attempting to update the state
																	if (newValue > 0) {
																		this.setState(
																			(prevState) => ({
																				documentFontSettings: {
																					...prevState.documentFontSettings,
																					fontSize: prevState.documentFontSettings?.fontSize || 16,
																					font: prevState.documentFontSettings?.font || "defaultFont", // Fallback to 'defaultFont'
																					lineLeading: newValue, // Fallback to 1.5
																				},
																			}),
																			() => {
																				this.saveLineSpacing(newValue);
																			}
																		);
																	}
																}
															}
														}}
													>
														<IonIcon size="large" className="fontSizeIcons" icon={add} />
													</IonButton>
												</div>
											</div>
										</div>
									</IonItem>
								</IonList>
							</IonCardContent>
						</div>
						<div className="settingsCard highlightSection">
							{/* <p className='settingsSectionParagraph'>
								<Trans>Evidenziazioni</Trans>
							</p> */}
							<div>
								<div className="settingsHighlightingsToggleDiv">
									<p className="settingsHighlightingsToggleParagraph">
										<Trans>Colorazione di lettere o parole</Trans>
									</p>
									<IonToggle
										className="highlightToggle"
										checked={this.state.isHighlightingActive}
										onClick={(e) => {
											let fromButton = true;
											if (this.state.paywallState) loadPaywall(fromButton);
											else {
												settingsServices.toggleHighlightings(this.state.userUuid!, !this.state.isHighlightingActive);
												this.setState({ isHighlightingActive: !this.state.isHighlightingActive });
											}
										}}
									></IonToggle>
								</div>
								<div className="settingsHighlightDiv">
									<IonItem className="settingsHighlightItem" hidden={!this.state.isHighlightingActive}>
										{/* <IonLabel
											className='settingsHighlightPlaceholder'
											hidden={this.state.highlightTargetText === '' ? false : true}
										>
											<Trans style={{ textOverflow: 'ellipsis' }}>Lettere da colorare</Trans> 
										</IonLabel> */}

										<IonInput
											label={
												i18n.language === 'it' ? "Lettere da colorare" :
													i18n.language === 'en' ? "Characters to colour" :
														i18n.language === 'es' ? "Letras a destacar" :
															i18n.language === 'fr' ? "Surlignez des lettres" :
																i18n.language === 'de' ? "Buchstaben färben" :
																	i18n.language === 'ca' ? "Lletres per pintar" :
																		i18n.language === 'pt' ? "Letras para colorir" :
																			i18n.language === 'cz' ? "Písmena k vybarvení" :
																				i18n.language === 'nl' ? "Letters om te kleuren" : ''
											}
											labelPlacement="floating"
											id="lettersToMarkInput"
											style={{ textOverflow: "ellipsis", color: this.state.pickedColor }}
											onFocus={() => this.handleFocusInput()}
											onKeyUp={(e) => {
												const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
												const value = target.value; // Now you can access value
												this.setState({
													highlightTargetText: value ? value : "",
												});
											}}
											value={this.state.highlightTargetText}
										/>

										{/* <div
												slot='end'
												onClick={() => {
													this.setState({ showColorPicker: true })
												}}
												className='settingsColorDiv'
												style={{ background: this.state.pickedColor }}
											>
											</div> */}
										<span
											onClick={() => {
												this.setState({ isColorPickerOpen: true });
											}}
											className="colorPickerOpen"
										></span>
										<IonButton
											slot="end"
											className="settingsSaveHighlightingButton"
											disabled={this.state.highlightTargetText === ""}
											onClick={() => {
												let fromButton = true;
												if (this.state.paywallState) loadPaywall(fromButton);
												else {
													if (this.state.userUuid) {
														settingsServices
															.addHighlighting(this.state.userUuid, this.state.highlightTargetText, this.state.pickedColor)
															.then((highlight) => {
																const arrayCopy = this.state.highlightings.slice();
																arrayCopy.push(highlight);
																this.setState({
																	highlightings: arrayCopy,
																	highlightTargetText: "",
																});
															})
															.catch((err) => {
																console.error("[Settings] error adding highlighting:", err);
															});
													}
												}
											}}
										>
											<Trans>Salva</Trans>
										</IonButton>
									</IonItem>
								</div>
								{/* <div hidden={!this.state.isHighlightingActive} className='settingsColorPickerDiv'>
									<ChromePicker color={this.state.pickedColor} onChange={(e) => this.setState({ pickedColor: e.hex })} disableAlpha />
								</div> */}
								<IonList className="settingsHighlightList" hidden={!this.state.isHighlightingActive} lines="none">
									{this.state.highlightings.map((highlight) => {
										return (

											<IonItem key={highlight.uuid} className="settingsHighlightItem">
												<IonGrid className="settingsHighlightItemGrid">
													<IonRow style={{ color: highlight.color }} className="settingsHighlightItemGridRow">
														<b>{highlight.target}</b>
													</IonRow>

													<IonRow className="settingsHighlightItemGridColorRow">
														<div className="settingsColorDiv" style={{ background: highlight.color }}></div>
													</IonRow>

													<IonRow className="settingsHighlightItemGridRemoveRow">
														<IonButton
															fill="clear"
															color="danger"
															shape="round"
															onClick={() => {
																if (this.state.userUuid) {
																	settingsServices
																		.deleteHighlighting(this.state.userUuid, highlight.uuid)
																		.then(() => {
																			this.setState({
																				highlightings: this.state.highlightings.filter((highlightPref) => highlightPref.uuid !== highlight.uuid),
																			});
																		})
																		.catch((err) => {
																			console.error("[Settings] error deleting highlighting id:", highlight.uuid, "error:", err);
																		});
																}
															}}
														>
															<IonIcon icon={removeCircleOutline} />
														</IonButton>
													</IonRow>
												</IonGrid>
											</IonItem>
										);
									})}
								</IonList>
							</div>
						</div>

						<div className="previewSection">
							<p className="settingsDocumentViewingPreviewHeaderParagraph">
								<Trans>Anteprima</Trans>
							</p>
							<IonCard className="previewBox">
								<div className="settingsDocumentViewingItemPreviewDiv">
									<p
										id="previewParagraph"
										className="settingsDocumentViewingPreviewParagraph"
										style={{
											fontFamily: settingsServices.fromBackendFontNameToCssFontFamily(
												this.state.documentFontSettings ? this.state.documentFontSettings.font : "Arial"
											),
											fontSize: this.state.documentFontSettings ? `${this.state.documentFontSettings.fontSize}px` : "30px",
											lineHeight: this.state.documentFontSettings ? `${100 + this.state.documentFontSettings.lineLeading}%` : "100%",
										}}
									>
										<Trans>
											Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.
										</Trans>{" "}
										{/* TO BE LOCALIZED */}
									</p>
								</div>
							</IonCard>
						</div>

						<div className="ttsGrid">
							{this.state.ttsSettings && (
								<div className="settingsCard ttsSection">
									<p className="settingsDocumentViewingPreviewHeaderParagraph">
										<Trans>Sintesi vocale</Trans>
									</p>
									<IonCard className="ttsBox">
										<IonCardContent>
											<IonList className="ttsList" lines="none">
												<IonItem className="ttsItems">
													<div className="settingsDocumentViewingItemDivTablet">
														<p className={"settingsDocumentViewingParagraph"}>
															<Trans>Velocità di riproduzione</Trans>
														</p>
														<IonSelect
															id="playbackSpeedPicker"
															className="settingsTtsSelect"
															value={this.state.ttsSettings.prosodyRate}
															interface="alert"
															onIonChange={(e) => {
																let fromButton = true;
																if (this.state.paywallState) loadPaywall(fromButton);
																else {
																	if (e.detail.value === "") return;
																	if (this.state.userUuid) {
																		ttsServices
																			.setProsodyRate(this.state.userUuid, e.detail.value)
																			.then((ttsSettings) => this.setState({ ttsSettings: ttsSettings }))
																			.catch((err) => {
																				console.error("[Settings] Error changing voice prosody rate", err);
																			});
																	}
																}
															}}
														>
															<IonSelectOption value="slow">
																<Trans>Lento</Trans>
															</IonSelectOption>{" "}
															{/* TO BE LOCALIZED */}
															<IonSelectOption value="medium">
																<Trans>Medio</Trans>
															</IonSelectOption>{" "}
															{/* TO BE LOCALIZED */}
															<IonSelectOption value="fast">
																<Trans>Veloce</Trans>
															</IonSelectOption>{" "}
															{/* TO BE LOCALIZED */}
														</IonSelect>
													</div>
												</IonItem>
												<IonItem className="ttsItems">
													<div className="settingsDocumentViewingItemDivTablet">
														<p className="settingsDocumentViewingParagraph">
															<Trans>Genere</Trans>
														</p>
														<IonSelect
															id="genderPicker"
															className="settingsTtsSelect"
															value={this.state.ttsSettings.voiceType}
															interface="alert"
															onIonChange={(e) => {
																let fromButton = true;
																if (this.state.paywallState) loadPaywall(fromButton);
																else {
																	if (e.detail.value === "") return;
																	if (this.state.userUuid) {
																		ttsServices
																			.setVoiceType(this.state.userUuid, e.detail.value)
																			.then((ttsSettings) => this.setState({ ttsSettings: ttsSettings }))
																			.catch((err) => {
																				console.error("[Settings] Error changing voice gender", err);
																			});
																	}
																}
															}}
														>
															<IonSelectOption value="M">
																<Trans>Voce 1</Trans>
															</IonSelectOption>
															<IonSelectOption value="F">
																<Trans>Voce 2</Trans>
															</IonSelectOption>
														</IonSelect>
													</div>
												</IonItem>
												<IonItem className="ttsItems">
													<div className="settingsDocumentViewingItemDiv">
														<IonButton
															fill="clear"
															onClick={() => {
																let fromButton = true;
																if (this.state.paywallState) loadPaywall(fromButton);
																else this.playAudio();
															}}
														>
															<IonIcon className="settingVoicePreviewButtonIcon" icon={ear} />
															<Trans>Ascolta la voce</Trans> {/* TO BE LOCALIZED */}
														</IonButton>
													</div>
												</IonItem>
											</IonList>
										</IonCardContent>
									</IonCard>
								</div>
							)}
						</div>

						<div className="colorChange">
							<p className="settingsDocumentViewingPreviewHeaderParagraph">
								<Trans>Cambia Palette colori</Trans>
							</p>
							<IonList className="paletteList" mode="md">
								{/* change color theme */}
								<IonItem className="paletteItems">
									<IonSelect
										label="Palette"
										mode="md"
										interface="popover"
										placeholder={this.state.preferredPalette}
										onIonChange={(e) => {
											let fromButton = true;
											if (this.state.paywallState) loadPaywall(fromButton);
											else {
												setTheme(e.detail.value);
												if (this.state.userUuid) {
													console.log(this.state.userUuid);
													update(ref(database, `u/${this.state.userUuid}`), {
														t: e.detail.value,
													}).catch((err) => {
														console.error("[Profile] error updating user preferred palette:", err);
													});
												}
											}
										}}
									>
										{themes.map((theme, i = 0) => (
											<IonSelectOption value={theme}>{theme}</IonSelectOption>
										))}
									</IonSelect>
								</IonItem>
							</IonList>
						</div>
						{/* <div className="settingsLicensesButtonDiv licenseSection">
						<IonButton
							color="medium"
							onClick={() => {
								this.props.history.push('/legal/licenses');
							}}
							fill="clear"
							class="settingsLicensesButton"
						>
							<Trans>Licenze</Trans>
						</IonButton>
					</div> */}
					</IonGrid>
				)}
				{isMobile && <MenuTabBar profile={undefined} settings={settingsIcon} home={undefined} />}

				<IonModal className="colorPickerModal" isOpen={this.state.isColorPickerOpen} onDidDismiss={() => this.setState({ isColorPickerOpen: false })}>
					<div className="colorPickerBackIcon">
						<IonIcon
							className="colorpickerBack"
							onClick={() => {
								this.setState({ isColorPickerOpen: false });
							}}
							icon={chevronBack}
							style={{ cursor: "pointer" }}
						></IonIcon>
						<IonTitle className="colorPickerTitle">
							<Trans>Cambia colore</Trans>
						</IonTitle>
					</div>

					<IonContent className="colorPickerContainer">
						<div className="color-preview">
							<div className="color-preview-inner" style={{ backgroundColor: this.state.previewColor }}>
								{hslToHex(this.state.hue, this.state.saturation, this.state.brightness)}
							</div>
						</div>
						<IonGrid>
							<IonRow>
								<IonCol>
									<IonLabel className="colorPickerSlidersTxt">
										<Trans>Hue</Trans>
									</IonLabel>

									<IonRange
										className="hueRangeSlider"
										min={0}
										max={360}
										step={1}
										value={this.state.hue}
										onIonChange={(e) =>
											this.setState({ hue: e.detail.value as number }, () => {
												this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
											})
										}
										onIonBlur={() => {
											this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
										}}
										onMouseUp={() => {
											this.setState({ recentColors: saveColor(this.state.previewColor, this.state.recentColors) });
										}}
									></IonRange>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonLabel className="colorPickerSlidersTxt">
										<Trans>Saturation</Trans>
									</IonLabel>

									<IonRange
										className="saturationRangeSlider"
										min={0}
										max={100}
										step={1}
										value={this.state.saturation}
										onIonChange={(e) =>
											this.setState({ saturation: e.detail.value as number }, () => {
												this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
											})
										}
										onIonBlur={() => {
											this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
										}}
										onMouseUp={() => {
											this.setState({ recentColors: saveColor(this.state.previewColor, this.state.recentColors) });
										}}
									></IonRange>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonLabel className="colorPickerSlidersTxt">
										<Trans>Brightness</Trans>
									</IonLabel>

									<IonRange
										className="brightnessRangeSlider"
										min={0}
										max={100}
										step={1}
										value={this.state.brightness}
										onIonChange={(e) =>
											this.setState({ brightness: e.detail.value as number }, () => {
												this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
											})
										}
										onIonBlur={() => {
											this.setState({ previewColor: updateColor(this.state.hue, this.state.saturation, this.state.brightness) });
										}}
										onMouseUp={() => {
											this.setState({ recentColors: saveColor(this.state.previewColor, this.state.recentColors) });
										}}
									></IonRange>
								</IonCol>
							</IonRow>
						</IonGrid>

						<IonItemDivider className="colorPickerText">
							<Trans>Campioni</Trans>
						</IonItemDivider>
						<IonList className="colorSampleList">
							{this.state.savedColors.slice(-6).map((color, index) => (
								<div
									className="color-box"
									style={{ backgroundColor: color }}
									onClick={() => {
										this.setState({ pickedColor: color, isColorPickerOpen: false });
									}}
								></div>
							))}
						</IonList>
						<IonItemDivider className="colorPickerText">
							<Trans>Recenti</Trans>
						</IonItemDivider>
						<IonList className="colorSampleList">
							{this.state.recentColors.slice(-6).map((color, index) => (
								<div
									className="color-box"
									style={{ backgroundColor: color }}
									onClick={() => {
										this.setState({ pickedColor: color, isColorPickerOpen: false });
									}}
								></div>
							))}
						</IonList>
						<div className="colorPickerSaveButtonContainer">
							<IonButton
								className="colorPickerSaveButton"
								onClick={() => {
									this.setState({ pickedColor: this.state.previewColor, isColorPickerOpen: false });
									this.setState({ savedColors: saveColor(this.state.previewColor, this.state.savedColors) }, () => {
										if (this.state.userUuid) {
											console.log(this.state.userUuid);
											update(ref(database, `u/${this.state.userUuid}`), {
												cp: this.state.savedColors.slice(-6),
											}).catch((err) => {
												console.error("[Profile] error updating user preferred palette:", err);
											});
										}
									});
								}}
							>
								<Trans>Salva</Trans>
							</IonButton>
						</div>
					</IonContent>
				</IonModal>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
