import { userConstants } from './constants';
import { Dispatch } from 'redux';
import { Organization } from './reducers';

export const userActions = {
    setOrganization,
    setBannerLicenseRedeemed
};

export const actionCreator = {
    setOrganization: (org_data: Organization | null) => { return { type: userConstants.SET_ORGANIZATION, org_data } },
    setBannerLicenseRedeemed: (isRedeemed: boolean) => { return { type: userConstants.SET_BANNER_LICENSE_REDEEMED, isRedeemed } },
};


function setOrganization(org_data: Organization | null) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.setOrganization(org_data));
    };
}
function setBannerLicenseRedeemed(isRedeemed: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.setBannerLicenseRedeemed(isRedeemed));
    };
}