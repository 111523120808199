export const SessionsTranslationsEN = {
    "Le tue sessioni": "Your sessions",
    "Sto cercando le tue sessioni...": "Fetching your sessions...",
    "Cerca per titolo": "Search by title",

    "Oggi": "Today",
    "Ieri": "Yesterday",
    "Questa settimana": "This week",
    "Settimana scorsa": "Last week",
    "Questo mese": "This month",
    "Sessioni più vecchie": "Older sessions",

    "Che argomento stai studiando?": "What topic are you studying?",
    "Che nuovo argomento vuoi studiare?": "What topic would you like to study?",
    "Hai ancora": "You still have",
    "Sessioni disponibili ✨": "available Sessions ✨",
    "Sessioni disponibili questo mese ✨": "available Sessions this month✨",


    "L'Impero romano": "The Roman Empire",
    "La mitosi cellulare": "The cell mitosis",
    "La geografia dell'Italia": "The geography of Italy",
    "Astrofisica: Buchi neri": "Astrophysics: Black holes",

    'Esempio "Il Rinascimento italiano"': 'e.g. "The Italian Renaissance"', //search translation

    "Digita l'argomento per ottenere:": "Type in the topic to get:",
    "mappa concettuale": "conceptual map",
    "riassunto": "summary",
    "parole chiave": "keywords",
    "audiolibro": "audiobook",

    "oppure carica 📤 un documento": "or upload 📤 a file",

    "Torna indietro": "Go back",
    "Rimuovi risorsa": "Delete resource",
    "Annulla": "Cancel",
    "Mappa": "Map",
    "Riassunto": "Summary",
    "Parole chiave": "Keywords",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiobook",

    "Inizia a studiare": "Start studying ",

}
export const SessionsTranslationsIT = {
    "Le tue sessioni": "Le tue sessioni",
    "Sto cercando le tue sessioni...": "Sto cercando le tue sessioni...",
    "Cerca per titolo": "Cerca per titolo",

    "Oggi": "Oggi",
    "Ieri": "Ieri",
    "Questa settimana": "Questa settimana",
    "Settimana scorsa": "Settimana scorsa",
    "Questo mese": "Questo mese",
    "Sessioni più vecchie": "Sessioni più vecchie",

    "Che argomento stai studiando?": "Che argomento stai studiando?",
    "Che nuovo argomento vuoi studiare?": "Che nuovo argomento vuoi studiare?",

    "Hai ancora": "Hai ancora",
    "Sessioni disponibili ✨": "Sessioni disponibili ✨",
    "Sessioni disponibili questo mese ✨": "Sessioni disponibili questo mese ✨",

    "L'Impero romano": "L'impero romano",
    "La mitosi cellulare": "La mitosi cellulare",
    "La geografia dell'Italia": "La geografia dell'Italia",
    "Astrofisica: Buchi neri": "Astrofisica: Buchi neri",

    'Esempio "Il Rinascimento italiano"': 'Esempio "Il Rinascimento italiano"',

    "Digita l'argomento per ottenere:": "Digita l'argomento per ottenere:",
    "mappa concettuale": "mappa concettuale",
    "riassunto": "riassunto",
    "parole chiave": "parole chiave",
    "audiolibro": "audiolibro",

    "oppure carica 📤 un documento": "oppure carica 📤 un documento",

    "Torna indietro": "Torna indietro",
    "Rimuovi risorsa": "Rimuovi risorsa",
    "Annulla": "Annulla",
    "Mappa": "Mappa",
    "Riassunto": "Riassunto",
    "Parole chiave": "Parole chiave",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiolibro",

    "Inizia a studiare": "Inizia a studiare",
}