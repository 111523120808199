import { SettingsTranslationsFR } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsFR } from '../../modules/user/ProfileTranslations';
import { MapsTranslationsFR } from '../../modules/maps/MapsTranslations';
import { MenuTranslationsFR } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsFR } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsFR } from '../../modules/files/FileTranslations';
import { PopoverTranslationsFR } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsFR } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsFR } from '../../modules/onboarding/OnboardingTranslations';

const mergedObject = {
  ...FileTranslationsFR,
  ...AuthTranslationsFR,
  ...SettingsTranslationsFR,
  ...ProfileTranslationsFR,
  ...MapsTranslationsFR,
  ...MenuTranslationsFR,
  ...PopoverTranslationsFR,
  ...SketchTranslationsFR,
};

export const fr = {
  mergedObject,
}