import { Product } from "../../../common/Paywall/store/paywallUtils"
import { userConstants } from "./constants"

export interface User {
    id: string,
    is_superuser: boolean,
    email: string,
    org_name: string,
    is_staff: boolean,
    is_active: boolean,
    date_joined: string,
    last_login: string
}


export interface Organization {
    uuid: string,
    createdAt: number,
    createdBy: string,
    name: string,
    type: "school" | "publisher" | "company",
    products: Product[],
}
export interface Notification {
    id: string,
    title: string,
    body: string,
    date: number,
    url: string | null,
    expiryDate: number | null,
    type: "offer" | "announcement" | "reminder" | "other",
}

export interface UserStore {
    userData: User | null,
    organization_name: string | null
    organization: Organization | null | 'Private'
    licenseBannerRedeemed: boolean
    // are_notifications_active: boolean,
}

export interface Device {
    uuid: string,
    creationDate: number,
    deviceId?: string,
    isActive: boolean,
    name?: string,
    registrationToken: string,
    type: number,
}


const initialState: UserStore = {
    userData: null,
    organization_name: null,
    organization: null,
    licenseBannerRedeemed: false,
    // are_notifications_active: boolean,
}


const User = (state: any = initialState, action: any) => {
    switch (action.type) {
        case userConstants.SET_ORGANIZATION:
            return {
                ...state,
                organization: action.org_data || null, // Provide a fallback in case org_data is undefined
            };
        case userConstants.SET_BANNER_LICENSE_REDEEMED:
            console.log("SET IT TO: ", action.isRedeemed);

            return {
                ...state,
                licenseBannerRedeemed: action.isRedeemed, // Provide a fallback in case org_data is undefined
            };
        default:
            return state;
    }
};

export default User;