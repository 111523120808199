export const ProfileTranslationsEN = {
    "Profilo": "Profile",
    "Informazioni": "About you",
    "Email": "Email address",
    "Organizzazione": "Organisation",
    "Lingua dell'applicazione": "App Language",
    "Dispositivi registrati": "Devices",
    "Notifiche push": "Push notifications",
    "Assistenza": "Support",
    "Telefono": "Phone",
    "Esci": "Log out",
    "Elimina account": "Delete Account",
    "Gestisci abbonamento": "Manage Subscription",
    "Vuoi davvero eliminare il tuo account?": "Do you really want to delete your account?",
    "Inserisci la tua password": "Insert your password",
    "Riscatta un libro": "Redeem a book",
    "Inserisci il codice ISBN del libro": "Enter the ISBN code of the book",
    "Il codice non è valido": "The code is not valid",
    "Libro riscattato con successo": "Book redeemed successfully",
    "Riscatta": "Redeem",
    "Libro già riscattato": "Book already redeemed",

}
export const ProfileTranslationsIT = {
    "Profilo": "Profilo",
    "Informazioni": "Informazioni",
    "Email": "Email",
    "Organizzazione": "Organizzazione",
    "Lingua dell'applicazione": "Lingua dell'applicazione",
    "Dispositivi registrati": "Dispositivi registrati",
    "Notifiche push": "Notifiche push",
    "Assistenza": "Assistenza",
    "Telefono": "Telefono",
    "Esci": "Esci",
    "Elimina account": "Elimina account",
    "Gestisci abbonamento": "Gestisci abbonamento",
    "Vuoi davvero eliminare il tuo account?": "Vuoi davvero eliminare il tuo account?",
    "Inserisci la tua password": "Inserisci la tua password",
    "Riscatta un libro": "Riscatta un libro",
    "Inserisci il codice ISBN del libro": "Inserisci il codice ISBN del libro",
    "Il codice non è valido": "Il codice non è valido",
    "Libro riscattato con successo": "Libro riscattato con successo",
    "Riscatta": "Riscatta",
    "Libro già riscattato": "Libro già riscattato",
}
export const ProfileTranslationsES = {
    "Profilo": "Cuenta",
    "Informazioni": "Información",
    "Email": "Correo electrónico",
    "Organizzazione": "Organización",
    "Lingua dell'applicazione": "Idioma de la aplicación",
    "Dispositivi registrati": "Dispositivos registrados",
    "Notifiche push": "Notificaciones",
    "Assistenza": "Asistencia",
    "Telefono": "Teléfono",
    "Email": "Correo electrónico",
    "Esci": "Log out",
    "Elimina account": "Eliminar la cuenta",
    "Gestisci abbonamento": "Administrar suscripción",
    "Vuoi davvero eliminare il tuo account?": "¿Realmente quieres eliminar tu cuenta?",
    "Inserisci la tua password": "Introduzca su contraseña",
    "Riscatta un libro": "Redimir un libro",
    "Inserisci il codice ISBN del libro": "Introduzca el código ISBN del libro",
    "Il codice non è valido": "El código no es válido",
    "Libro riscattato con successo": "Libro canjeado con éxito",
    "Riscatta": "Canjear",
    "Libro già riscattato": "Libro ya canjeado",
}
export const ProfileTranslationsFR = {
    "Profilo": "Profil",
    "Informazioni": "Information",
    "Email": "Adresse e-mail",
    "Organizzazione": "Organisation",
    "Lingua dell'applicazione": "Langue de l'application",
    "Dispositivi registrati": "Dispositifs enregistrés",
    "Notifiche push": "Notifications push",
    "Assistenza": "Assistance",
    "Telefono": " Numéro de téléphone",
    "Email": "Adresse e-mail",
    "Esci": "Sortie",
    "Elimina account": "Supprimer le compte",
    "Disconnetti": "Se déconnecter",
    "Gestisci abbonamento": "Gérer l'abonnement",
    "Vuoi davvero eliminare il tuo account?": "Voulez-vous vraiment supprimer votre compte?",
    "Inserisci la tua password": "Insérez votre mot de passe",
    "Riscatta un libro": "Remise d'un livre",
    "Inserisci il codice ISBN del libro": "Saisir le code ISBN du livre",
    "Il codice non è valido": "Le code n'est pas valide",
    "Libro riscattato con successo": "Livre échangé avec succès",
    "Riscatta": "Racheter",
    "Libro già riscattato": "Livre déjà utilisé",
}
export const ProfileTranslationsDE = {
    "Profilo": "Profil",
    "Informazioni": "Informationen",
    "Email": "E-Mail",
    "Organizzazione": "Organisation",
    "Lingua dell'applicazione": "Sprache der Bewerbung",
    "Dispositivi registrati": "Registrierte Geräte",
    "Notifiche push": "Push-Benachrichtigungen",
    "Assistenza": "Unterstützung",
    "Telefono": "Telefon",
    "Esci": "Ausfahrt",
    "Elimina account": "Konto löschen",
    "Gestisci abbonamento": "Abonnement verwalten",
    "Vuoi davvero eliminare il tuo account?": "Möchten Sie Ihr Konto wirklich löschen?",
    "Inserisci la tua password": "Geben Sie Ihr Passwort ein",
    "Riscatta un libro": "Ein Buch einlösen",
    "Inserisci il codice ISBN del libro": "Geben Sie den ISBN-Code des Buches ein",
    "Il codice non è valido": "Der Code ist ungültig",
    "Libro riscattato con successo": "Buch erfolgreich eingelöst",
    "Riscatta": "Einlösen",
    "Libro già riscattato": "Buch bereits eingelöst",
}
export const ProfileTranslationsCA = {
    "Profilo": "Perfil",
    "Informazioni": "Informació",
    "Email": "Correu electrònic",
    "Organizzazione": "Organització",
    "Lingua dell'applicazione": "Idioma de l'aplicació",
    "Dispositivi registrati": "Dispositius registrats",
    "Notifiche push": "Notificacions push",
    "Assistenza": "Assistència",
    "Telefono": "Telèfon",
    "Esci": "Sortir",
    "Disconnetti": "Tancar sessió",
    "Elimina account": "Elimina el compte",
    "Gestisci abbonamento": "Gestiona la subscripció",
    "Vuoi davvero eliminare il tuo account?": "De veritat voleu suprimir el vostre compte?",
    "Inserisci la tua password": "Insereix la teva contrasenya",
    "Riscatta un libro": "Bescanviar un llibre",
    "Inserisci il codice ISBN del libro": "Introduïu el codi ISBN del llibre",
    "Il codice non è valido": "El codi no és vàlid",
    "Libro riscattato con successo": "El llibre s'ha bescanviat correctament",
    "Riscatta": "Redimir",
    "Libro già riscattato": "Llibre ja bescanviat",
}
export const ProfileTranslationsPT = {
    "Profilo": "Perfil",
    "Informazioni": "Informació",
    "Email": "Correu electrònic",
    "Organizzazione": "Organització",
    "Lingua dell'applicazione": "Idioma de l'aplicació",
    "Dispositivi registrati": "Dispositius registrats",
    "Notifiche push": "Notificacions push",
    "Assistenza": "Assistència",
    "Telefono": "Telèfon",
    "Esci": "Sortir",
    "Disconnetti": "Desconectar",
    "Elimina account": "Eliminar conta",
    "Gestisci abbonamento": "Gerenciar assinatura",
    "Vuoi davvero eliminare il tuo account?": "Você realmente deseja excluir sua conta?",
    "Inserisci la tua password": "Insira sua senha",
    "Riscatta un libro": "Resgatar um livro",
    "Inserisci il codice ISBN del libro": "Insira o código ISBN do livro",
    "Il codice non è valido": "O código não é válido",
    "Libro riscattato con successo": "Livro resgatado com sucesso",
    "Riscatta": "Resgatar",
    "Libro già riscattato": "Livro já resgatado",
}
export const ProfileTranslationsCZ = {
    "Profilo": "Profil",
    "Informazioni": "Informace",
    "Email": "E-mail",
    "Organizzazione": "Organizace",
    "Lingua dell'applicazione": "Jazyk aplikace",
    "Dispositivi registrati": "Registrovaná zařízení",
    "Notifiche push": "Notifikace push",
    "Assistenza": "Asistence",
    "Telefono": "Telefon",
    "Esci": "Odhlásit se",
    "Elimina account": "Smazat účet",
    "Gestisci abbonamento": "Spravovat předplatné",
    "Vuoi davvero eliminare il tuo account?": "Opravdu chcete smazat svůj účet?",
    "Inserisci la tua password": "Zadejte své heslo",
    "Riscatta un libro": "Vyměnit knihu",
    "Inserisci il codice ISBN del libro": "Zadejte kód ISBN knihy",
    "Il codice non è valido": "Kód není platný",
    "Libro riscattato con successo": "Kniha úspěšně vyměněna",
    "Riscatta": "Vyměnit",
    "Libro già riscattato": "Kniha již byla vyměněna",
}
export const ProfileTranslationsNL = {
    "Profilo": "Profiel",
    "Informazioni": "Informatie",
    "Email": "E-mail",
    "Organizzazione": "Organisatie",
    "Lingua dell'applicazione": "Taal van de applicatie",
    "Dispositivi registrati": "Geregistreerde apparaten",
    "Notifiche push": "Pushmeldingen",
    "Assistenza": "Ondersteuning",
    "Telefono": "Telefoon",
    "Esci": "Afmelden",
    "Elimina account": "Account verwijderen",
    "Gestisci abbonamento": "Abonnement beheren",
    "Vuoi davvero eliminare il tuo account?": "Wil je je account echt verwijderen?",
    "Inserisci la tua password": "Voer je wachtwoord in",
    "Riscatta un libro": "Een boek inwisselen",
    "Inserisci il codice ISBN del libro": "Voer de ISBN-code van het boek in",
    "Il codice non è valido": "De code is ongeldig",
    "Libro riscattato con successo": "Boek succesvol ingewisseld",
    "Riscatta": "Inwisselen",
    "Libro già riscattato": "Boek is al ingewisseld",
}