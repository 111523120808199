import axios from "axios";
import { TTSSettings } from "../../tts/store/reducers";
import { auth } from "../../../firebaseConfig";
import { franc } from 'franc'

let language = ""

export function getTTS(docEls: any[], text: string, settings: TTSSettings) {
    return new Promise<any>((resolve, reject) => {
        let langDetectText = text
        if (langDetectText.length <= 50) {
            langDetectText = ''
            docEls.forEach(el => {
                langDetectText = langDetectText.concat(el.text)
            })
        }

        let langTxt = document.getElementById("fileViewerTextDivContainer")?.textContent
        language = franc(langTxt ? langTxt.slice(0, 200) : langDetectText);
        const languageMappings: Record<string, string> = {
            ita: "it",
            spa: "es",
            eng: "en",
            fra: "fr",
            deu: "de",
            cat: "ca"
        };
        language = languageMappings[language] || language;
        // language = i18n.language

        console.log(language)

        let ttsSettings = {
            prosody_rate: settings.prosodyRate,
            voice_gender: settings.voiceType,
        }
        auth.currentUser?.getIdToken()
            .then(token => {
                axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/text-to-speech/`, {
                    "Authorization": `Bearer ${token}`,
                    text: text, language: language, settings: ttsSettings
                })
                    .then(response => {
                        console.log("[getTTS] response:", response.data.body);
                        if (response.data.body) {
                            resolve({
                                audio: `data:audio/mpeg;base64,${response.data.body.audio}`,
                                speech_marks: response.data.body.speech_marks
                            });
                        }
                        reject(response.data);
                    })
                    .catch(error => {
                        console.log('[getTTS] error:', error.response.data);
                        reject(error.response.data);
                    });
            })
        })
}   