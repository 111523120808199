import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonImg, IonInput, IonPage, IonPopover, IonSpinner, IonToolbar, isPlatform } from "@ionic/react";
import { doc, getDoc } from "firebase/firestore";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import React from "react";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import { sendEvent } from "../../../common/amplitudeUtils";
import { auth, firestore, remoteConfig } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { Organization } from "../../user/store/reducers";
import { Argument, Resource } from "../store/reducers";
import map3D from "../../../assets/icons/map3D.png";
import summary3D from "../../../assets/icons/summary3D.png";
import keywords3D from "../../../assets/icons/keywords3D.png";
import audio3D from "../../../assets/icons/audio3D.png";
import flashCards from "../../../assets/icons/flashCards.png";
import repeatIcon from "../../../assets/icons/repeatIcon.png";
import "./SessionDetails.css";
import { store } from "../../../common/store";
import { argumentServices, deleteSessionElement, getLocalStorageSessionState, restoreCreationWsSession, restoreWsSession, setLocalStorageSessionState } from "../store/services";
import { checkStatusBar, clearSessionState, getStudySession, isImageValid, uploadFile } from "../utils/utils";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import removeResourceIcon from "../../../assets/icons/removeResourceIcon.svg";
import { isIOS, isMobile } from "react-device-detect";
import { Trans } from "react-i18next";

type Props = {
	history: any;
	orgData: Organization | null;
};

type State = {
	userUuid: string | null;
	userArguments: Argument[];
	// userArguments: any[],
	remoteConfig: boolean;
	bearerToken: string;
	newArgument: string;
	isProcessingResources: boolean;
	argument: Argument | null;
	missingResources: string[];
	summarydocID: string;
	missingSummary: boolean;
	missingAudio: boolean;
	deleteElements: boolean;
	resource: Resource;
	isLoadingMap: boolean;
	isLoadingSummary: boolean;
	isLoadingKeywords: boolean;
	isLoadingFlashcards: boolean;
	isLoadingAudiobook: boolean;
	isLoading: boolean;
	audioLink: string;
	argumentID: string;
	showConfirmationPopover: boolean;
	deleting: boolean,

	showArgumentInputPopover: boolean;
	missingResourceArgumentString: string;
	selectedMissingResourceName: string;
	ttsSettings: TTSSettings;
};

class SessionDetails extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			userUuid: null,
			userArguments: [],
			remoteConfig: false,
			bearerToken: "",
			newArgument: "",
			isProcessingResources: false,
			argument: null,
			missingResources: [],
			summarydocID: "",
			missingSummary: false,
			missingAudio: false,
			audioLink: "",
			argumentID: "",
			deleteElements: false,
			resource: {
				uuid: "",
				type: "map",
				is_present: false,
			},
			showConfirmationPopover: false,

			isLoadingMap: false,
			isLoadingSummary: false,
			isLoadingKeywords: false,
			isLoadingFlashcards: false,
			isLoadingAudiobook: false,
			isLoading: false,
			deleting: false,

			showArgumentInputPopover: false,
			missingResourceArgumentString: "",
			selectedMissingResourceName: "",
			ttsSettings: {
				prosodyRate: "",
				voiceType: "",
			},
		};
	}

	componentDidMount() {
        console.log(this.props.history.location.state.availableSessions )
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue });
				}
			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged((userData) => {
			if (userData) {
				userData.getIdToken().then((response) => {
					this.setState({ bearerToken: response });
				});
				ttsServices.getTTSSettings(userData.uid).then((settings) => {
					this.setState({ ttsSettings: settings });
				});
				this.setState({ userUuid: userData.uid }, () => {
					if (this.props.history.location.state.argument) {
						console.log(this.props.history.location.state.argument);
						let argument = this.props.history.location.state.argument;
						argumentServices.getUserArguments(userData.uid, argument.uuid).then((response) => {
							const resources = response[0].resources;
							// Set summarydocID if the type is "summary"
							resources.forEach((el: any) => {
								if (el.type === "summary") {
									this.setState({ summarydocID: el.uuid });
								}
							});

							// Sort the resources
							const filteredAndSortedResources = resources
								.filter((resource: Resource) => resource.is_present)
								.sort((a: Resource, b: Resource) => {
									const order = ["map", "summary", "keywords", "audio", "flashCards"];
									return order.indexOf(a.type) - order.indexOf(b.type);
								});

							// Update the state with the sorted resources
							this.setState({
								argumentID: argument.uuid,
								argument: {
									...response[0], // Spread the other properties of response[0]
									resources: filteredAndSortedResources, // Assign the sorted resources
								},
							}, () => {
								this.searchForMissingResources(resources);
								this.handleSessionState("");
								this.handleSessionState("creation");
							});
						});
						/* ORDER THE ARRAY TO DISPLAY THE CARDS IN A CERTAIN ORDER */
					}
					// window.addEventListener('beforeunload', clearSessionState);

				});

			} else {
				this.setState({
					userUuid: null,
				});
			}
		});
	}
	componentWillUnmount(): void {
		window.removeEventListener('beforeunload', clearSessionState);

	}

	handleSessionState(sessionType: string) {
		let sessionKey = sessionType === "creation" ? "creationSessionState" : "sessionState";
		let storedState = localStorage.getItem(sessionKey);
		let localStorageState = storedState ? JSON.parse(storedState) : [false, null];

		if (localStorageState) {
			let sessionTypes = getLocalStorageSessionState(sessionType, this.state.argument?.uuid);
			console.log(`${sessionType === "creation" ? "CREATION " : ""}SESSION TYPES:`, sessionTypes);
			let openedConnections = new Set(); // Track opened connections to avoid duplicates

			if (sessionTypes.length > 0) {
				sessionTypes.forEach(session => {
					let sessionResourceType = sessionType === "creation" ? session[2] : session;
					let additionalData = sessionType === "creation" ? session.slice(3) : [];

					switch (sessionResourceType) {
						case "map":
							this.setState({ isLoadingMap: true });
							break;
						case "summary":
							this.setState({ isLoadingSummary: true });
							break;
						case "keywords":
							this.setState({ isLoadingKeywords: true });
							break;
						case "flashcards":
							this.setState({ isLoadingFlashcards: true });
							break;
						default:
							this.setState({ isLoadingAudiobook: true });
					}

					if (sessionType === "creation" && additionalData.length > 0) {
						let connectionIdentifier = additionalData[0]; // Assuming session[3] is unique for each connection

						if (!openedConnections.has(connectionIdentifier)) {
							console.log(connectionIdentifier); // Debugging information
							restoreCreationWsSession.call(
								this,
								connectionIdentifier, // session[3] in creation session
								this.state.bearerToken,
								store.getState().auth!.userData!.uid,
								session[1], // Assuming argID is at index 1
								sessionResourceType
							);
							openedConnections.add(connectionIdentifier); // Mark this connection as opened
						}
					}
				});

				if (sessionType !== "creation") {
					restoreWsSession.call(this);
				}
				console.log(store.getState().auth!.userData!);
			}
		}
	}
	watchForResources(uid: string, argumentid: string) {
		argumentServices.getUserArguments(uid, argumentid).then((response) => {
			const resources = response[0].resources;

			// Search for missing resources
			this.searchForMissingResources(resources);
			console.log("RESPONSE: ", response);

			// Set summarydocID if the type is "summary"
			resources.forEach((el: any) => {
				if (el.type === "summary") {
					this.setState({ summarydocID: el.uuid });
				}
			});

			// Sort the resources
			const filteredAndSortedResources = resources
				.filter((resource: Resource) => resource.is_present)
				.sort((a: Resource, b: Resource) => {
					const order = ["map", "summary", "keywords", "audio", "flashCards"];
					return order.indexOf(a.type) - order.indexOf(b.type);
				});

			// Update the state with the sorted resources
			this.setState({
				argument: {
					...response[0], // Spread the other properties of response[0]
					resources: filteredAndSortedResources, // Assign the sorted resources
				},
			});
		});
		/* ORDER THE ARRAY TO DISPLAY THE CARDS IN A CERTAIN ORDER */
	}
	searchForMissingResources(resources: Resource[]) {
		const allResourceTypes = ["map", "summary", "keywords", "audio", "flashCards"];
		// Create a set of present resource types for efficient lookup
		const presentResourceTypes = new Set(resources.map((resource) => resource.is_present === false || resource.type));// Create a set of missing resource types where is_present is false
		setLocalStorageSessionState(false, this.state.argumentID, presentResourceTypes, "")
		setLocalStorageSessionState(false, this.state.argumentID, presentResourceTypes, "creation", this.state.argument!.title)

		const missingResourceTypes = new Set(resources.filter((resource) => !resource.is_present).map((resource) => resource.type));

		// Find all missing resource types by filtering all types based on presence
		const missingResources = allResourceTypes.filter((type: any) => !presentResourceTypes.has(type));

		// Determine specific missing resources
		const missingSummary = !presentResourceTypes.has("summary");
		const missingAudio = !presentResourceTypes.has("audio");

		this.setState(
			{
				missingResources,
				missingSummary,
				missingAudio,
			}
		);
	}

	async mapClicked(resourceId: string) {
		await getDoc(doc(firestore, `m/${resourceId}/`)).then((documentSnapshot) => {
			if (documentSnapshot.exists()) {
				let state = false;
				localStorage.setItem("mapState", JSON.stringify(state));
				sendEvent({
					"user_id": this.state.userUuid,
					"event_type": "Map visited",
					"event_properties": {
						"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
						"document_uuid": resourceId,
						"document_name": this.state.argument?.title,
					},
					"language": i18n.language,
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
				this.props.history.push({
					pathname: `/maps/${resourceId}`,
					state: { argumentID: this.state.argument },
				});
			}
		});
	}
	async summaryClicked(resourceId: string) {
		await getDoc(doc(firestore, `s/${resourceId}/`)).then((documentSnapshot) => {
			if (documentSnapshot.exists()) {
				let state = false;
				// localStorage.setItem("mapState", JSON.stringify(state));
				sendEvent({
					"user_id": this.state.userUuid,
					"event_type": resourceId.startsWith("SUMMARY") ? "Summary visited" : "Keywords visited",
					"event_properties": {
						"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
						"document_uuid": resourceId,
						"document_name": this.state.argument?.title,
					},
					"language": i18n.language,
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
				this.props.history.push({
					pathname: `/summary/${resourceId}`,
					state: { argumentID: this.state.argument, availableSessions:this.props.history.location.state.availableSessions },
				});
			}
		});
	}
	async keywordsClicked(resourceId: string) { }
	async audiobookClicked(resourceId: string) {
		if (resourceId) {
			sendEvent({
				"user_id": this.state.userUuid,
				"event_type": "Audiobook visited",
				"event_properties": {
					"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
					"document_name": this.state.argument?.title,
				},
				"language": i18n.language,
				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				"app_version": appVersion,
				"time": Date.now(),
			});
			this.props.history.push({
				pathname: `/audiobook`,
				state: {
					argument: this.state.argument,
					audio: resourceId,
				},
			});
		}
	}
	async flashCardsClicked(resourceId: string) {
		if (resourceId) {
			sendEvent({
				"user_id": this.state.userUuid,
				"event_type": "Flashcards visited",
				"event_properties": {
					"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
					"document_name": this.state.argument?.title,
				},
				"language": i18n.language,
				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
				"app_version": appVersion,
				"time": Date.now(),
			});
			this.props.history.push({
				pathname: `/flashcards`,
				state: {
					argument: this.state.argument,
					flashcardUuid: resourceId,
				},
			});
		}
	}
	startResourceLoading(resourceName: string) {
		console.log(resourceName);
		resourceName === "map"
			? this.setState({ isLoadingMap: true })
			: resourceName === "summary"
				? this.setState({ isLoadingSummary: true })
				: resourceName === "keywords"
					? this.setState({ isLoadingKeywords: true })
					: resourceName === "flashCards"
						? this.setState({ isLoadingFlashcards: true })
						: this.setState({ isLoadingAudiobook: true });
	}

	generateMissingResource(resourceName: string) {
		if (auth.currentUser && auth.currentUser.uid && this.state.argument) {
			auth.currentUser.getIdToken().then(async (response) => {
				resourceName === "map"
					? this.setState({ isLoadingMap: true })
					: resourceName === "summary"
						? this.setState({ isLoadingSummary: true })
						: resourceName === "keywords"
							? this.setState({ isLoadingKeywords: true })
							: resourceName === "flashCards"
								? this.setState({ isLoadingFlashcards: true })
								: this.setState({ isLoadingAudiobook: true });
				// if (/* was not from file */)
				argumentServices.getSessionArguments
					.call(this, "", response, auth.currentUser!.uid, this.state.argument!.title, this.state.argument?.title, resourceName, this.state.argument!)
					.then((resp) => {
						console.log("generated new resource: ", resp);
						// let missingRes = this.state.missingResources
						// missingRes.splice(missingRes.indexOf(resourceName), 1)
						window.location.reload();
					})
					.catch((err) => {
						/* handle err */
					});
			});
		}
	}

	paletteColors = ["#C7DEFF", "#FFCCDC", "#BDBDFF", "#FCD9CA", "#C7F6D1"];
	render() {
		return (
			<IonPage className="sessionDetailsPage body">

				{this.state.argument && (
					<div className="sessionDetailsMainDiv">
						{(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}  ></IonToolbar>}

						<IonButton
							className="sessionsDetailsGoBackBtn"
							onClick={() => {
								this.props.history.push("/sessions");
							}}
						>
							<Trans>
								Torna indietro
							</Trans>
						</IonButton>
						<div className="sessionsHomeOnboardingTitle">
							<p className="sessionDetailTitle">"{this.state.argument?.title}"</p>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<IonButton
									className="sessionDetailRemoveButton"
									style={{ fontSize: ".4em", cursor: "pointer", borderRadius: "20px" }}
									onClick={() => {
										if (!this.state.deleteElements) {
											let elements = document.getElementsByClassName("sessionDetailsResourceCard");
											for (let i = 0; i < elements.length; i++) {
												if (elements[i].getAttribute("data-is-present")) {
													elements[i].classList.add("wobbleAnimation");
													(elements[i] as HTMLElement).style.padding = "0";
													(elements[i] as HTMLElement).style.paddingBottom = "15px";
													this.setState({ deleteElements: true });
												}
											}
										} else {
											this.setState({ deleteElements: false });
											let elements = document.getElementsByClassName("sessionDetailsResourceCard");
											for (let i = 0; i < elements.length; i++) {
												elements[i].classList.remove("wobbleAnimation");
												(elements[i] as HTMLElement).style.padding = "unset";
												(elements[i] as HTMLElement).style.paddingBottom = "15px";
											}
										}
									}}
								>
									{this.state.deleteElements ?
										i18n.t("Annulla") :
										i18n.t("Rimuovi risorsa")
									}
								</IonButton>
							</div>
						</div>

						<div className={"sessionDetailsResourcesGrid fullGrid"}>
							{this.state.argument.resources.map((resource, i) => {
								return (
									<IonCard
										className={"sessionDetailsResourceCard fullGridCard"}
										style={{ background: this.paletteColors[i] }}
										disabled={(this.state.isLoadingMap && resource.type === "map") ||
											(this.state.isLoadingSummary && resource.type === "summary") ||
											(this.state.isLoadingKeywords && resource.type === "keywords") ||
											(this.state.isLoadingFlashcards && resource.type === "flashCards") ||
											(this.state.isLoadingAudiobook && resource.type === "audio")}
										key={resource.uuid}
										id={`card-${resource.type}`}
										data-is-present={resource.is_present}
										onClick={() => {
											if (resource.type === "map") {
												this.mapClicked(resource.uuid);
											} else if (resource.type === "summary") {
												this.summaryClicked(resource.uuid);
											} else if (resource.type === "keywords") {
												this.summaryClicked(resource.uuid);
											} else if (resource.type === "audio") {
												this.audiobookClicked(resource.uuid);
											} else if (resource.type === "flashCards") {
												this.flashCardsClicked(resource.uuid);
											}
										}}
									>
										{/* Conditional rendering based on resource type */}
										{(this.state.isLoadingMap && resource.type === "map") ||
											(this.state.isLoadingSummary && resource.type === "summary") ||
											(this.state.isLoadingKeywords && resource.type === "keywords") ||
											(this.state.isLoadingFlashcards && resource.type === "flashCards") ||
											(this.state.isLoadingAudiobook && resource.type === "audio") ? (
											<IonSpinner className="delete-icon" />
										) : (
											<IonIcon
												hidden={!this.state.deleteElements}
												icon={removeResourceIcon}
												className="delete-icon"
												onClick={(event) => {
													event.stopPropagation();
													this.startResourceLoading(resource.type);
													this.setState({ resource: resource, showConfirmationPopover: true, deleting: true });
												}}
											/>
										)}
										<IonCardContent className="sessionDetailsResourceCardContent">
											<IonImg
												className="sessionDetailsResourceIcon"
												src={
													resource.type == "map"
														? map3D
														: resource.type == "summary"
															? summary3D
															: resource.type == "keywords"
																? keywords3D
																: resource.type == "audio"
																	? audio3D
																	: resource.type == "flashCards"
																		? flashCards
																		: ""
												}
											/>
										</IonCardContent>
										<IonCardTitle className="sessionDetailsResourceType">
											{i18n.t(
												resource.type == "map"
													? "Mappa"
													: resource.type == "summary"
														? "Riassunto"
														: resource.type == "keywords"
															? "Parole chiave"
															: resource.type == "audio"
																? "Audiolibro"
																: resource.type == "flashCards"
																	? "Flashcards"
																	: ""
											)}
										</IonCardTitle>
									</IonCard>
								);
							})}
							{this.state.missingResources.map((resourceName, i) => {
								return (
									<IonCard
										disabled={
											(resourceName === "map" && this.state.isLoadingMap) ||
											(resourceName === "summary" && this.state.isLoadingSummary) ||
											(resourceName === "keywords" && this.state.isLoadingKeywords) ||
											(resourceName === "flashCards" && this.state.isLoadingFlashcards) ||
											(resourceName === "audio" && this.state.isLoadingAudiobook)
										}
										className={"sessionDetailsResourceCard fullGridCard"}
										style={{ background: this.paletteColors[i] }}
										key={resourceName}
										id={`card-${resourceName}`}
										title="Rigenera"
										onClick={() => {
											if (!this.state.missingSummary && this.state.missingAudio) this.setState({ isLoadingAudiobook: true }, () => { setLocalStorageSessionState(true, this.state.argument!.uuid, "audio", "creation", this.state.argument?.title); getStudySession.call(this, resourceName, this.state.argument!) });
											else this.setState({ showArgumentInputPopover: true, selectedMissingResourceName: resourceName });
											// this.generateMissingResource(resourceName)
										}}
										onMouseOver={(e) => {
											document.getElementById(`resourceImg-${i}`)!.style.opacity = "1";
											(document.getElementById(`resourceImg-${i}`) as HTMLIonImgElement)!.src = repeatIcon;
										}}
										onMouseOut={(e) => {
											document.getElementById(`resourceImg-${i}`)!.style.opacity = ".2";
											(document.getElementById(`resourceImg-${i}`) as HTMLIonImgElement)!.src =
												resourceName == "map"
													? map3D
													: resourceName == "summary"
														? summary3D
														: resourceName == "keywords"
															? keywords3D
															: resourceName == "audio"
																? audio3D
																: resourceName == "flashCards"
																	? flashCards
																	: "";
										}}
									>
										<IonCardContent className="sessionDetailsResourceCardContent">
											<IonImg
												hidden={
													(resourceName === "map" && this.state.isLoadingMap) ||
													(resourceName === "summary" && this.state.isLoadingSummary) ||
													(resourceName === "keywords" && this.state.isLoadingKeywords) ||
													(resourceName === "flashCards" && this.state.isLoadingFlashcards) ||
													(resourceName === "audio" && this.state.isLoadingAudiobook)
												}
												id={`resourceImg-${i}`}
												className="sessionDetailsResourceIcon missingResourceImage"
												style={{ opacity: 0.2 }}
												src={
													resourceName == "map"
														? map3D
														: resourceName == "summary"
															? summary3D
															: resourceName == "keywords"
																? keywords3D
																: resourceName == "audio"
																	? audio3D
																	: resourceName == "flashCards"
																		? flashCards
																		: ""
												}
											/>
											{
												<IonSpinner
													hidden={
														(!this.state.isLoadingMap &&
															!this.state.isLoadingSummary &&
															!this.state.isLoadingKeywords &&
															!this.state.isLoadingAudiobook &&
															!this.state.isLoadingFlashcards) ||
														(resourceName === "flashCards" && !this.state.isLoadingFlashcards) ||
														(resourceName === "map" && !this.state.isLoadingMap) ||
														(resourceName === "audio" && !this.state.isLoadingAudiobook) ||
														(resourceName === "summary" && !this.state.isLoadingSummary) ||
														(resourceName === "keywords" && !this.state.isLoadingKeywords)
													}
													name="crescent"
												/>
											}
										</IonCardContent>
										<IonCardTitle className="sessionDetailsResourceType">
											{resourceName == "map"
												? "Mappa mancante"
												: resourceName == "summary"
													? "Riassunto mancante"
													: resourceName == "keywords"
														? "Parole chiave mancanti"
														: resourceName == "audio"
															? "Audiolibro mancante"
															: resourceName == "flashCards"
																? "Flashcards mancante"
																: ""}
										</IonCardTitle>
										<input
											type="file"
											id="argumentFile"
											style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
											onChange={(e) => {
												const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
												if (file) {
													if (file.size > 25 * 1024 * 1024) {
														/* FILE SIZE > 25MB */
														let errorMessage =
															i18n.language === "it"
																? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
																: i18n.language === "en"
																	? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
																	: i18n.language === "fr"
																		? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
																		: i18n.language === "de"
																			? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
																			: i18n.language === "ca"
																				? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
																				: i18n.language === "es"
																					? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
																					: "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
														alert(errorMessage);
														return;
													}

													let resourceName = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? "summary" : this.state.selectedMissingResourceName;
													// console.log("REGENERATE:", resourceName);
													if (this.state.argument && this.state.selectedMissingResourceName) uploadFile.call(this, file, this.state.userUuid, resourceName, this.state.argument!);
													this.setState({ showArgumentInputPopover: false, selectedMissingResourceName: "" });
													this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary")
														? this.setState({ isLoadingAudiobook: true, isLoadingSummary: true })
														: this.startResourceLoading(this.state.selectedMissingResourceName);
													let resourcesNames = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? ["audio", "summary"] : resourceName
													setLocalStorageSessionState(true, this.state.argument!.uuid, resourcesNames, "creation", this.state.argument?.title)
												}
											}}
											accept="image/png, image/jpg, application/pdf"
										></input>
									</IonCard>
								);
							})}
							{/*  <IonCard
                                className="sessionDetailsResourceCard"
                                onClick={() => {

                                }}>
                                <IonCardContent className="sessionDetailsResourceCardContent">
                                    <IonImg
                                        className="sessionDetailsResourceIcon"
                                    />
                                </IonCardContent>
                                <IonCardTitle className="sessionDetailsResourceType">

                                </IonCardTitle>
                            </IonCard> */}
						</div>
						<IonPopover
							isOpen={this.state.showArgumentInputPopover}
							onDidDismiss={() => this.setState({ showArgumentInputPopover: false, selectedMissingResourceName: "" })}
							className="argumentInputPopover"
						>
							<div className="argumentInputPopoverDiv">
								<p className="argumentInputPopoverTitle">Inserisci l' argomento {/* to localize this */}</p>
								<IonInput
									className="argumentInputPopoverInput"
									onInput={(e) => {
										if (e.currentTarget.value)
											this.setState({ missingResourceArgumentString: e.currentTarget.value.toString(), newArgument: e.currentTarget.value.toString() });
									}}
									debounce={200}
									placeholder="Inserisci l'argomento..."
								/>
								<IonButton
									className="argumentInputPopoverButton"
									disabled={this.state.missingResourceArgumentString.trim().length < 3}
									onClick={() => {
										// Add argument to the session
										let resourceName = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? "summary" : this.state.selectedMissingResourceName;
										console.log("REGENERATE:", resourceName);
										if (this.state.argument && this.state.selectedMissingResourceName) getStudySession.call(this, resourceName, this.state.argument);
										this.setState({ showArgumentInputPopover: false, selectedMissingResourceName: "" });
										this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary")
											? this.setState({ isLoadingAudiobook: true, isLoadingSummary: true })
											: this.startResourceLoading(this.state.selectedMissingResourceName);
										let resourcesNames = this.state.missingAudio && this.state.missingSummary && (this.state.selectedMissingResourceName === "audio" || this.state.selectedMissingResourceName === "summary") ? ["audio", "summary"] : resourceName
										setLocalStorageSessionState(true, this.state.argument!.uuid, resourcesNames, "creation", this.state.argument?.title)

									}}
								>
									Conferma {/* TO LOCALIZE THIS */}
								</IonButton>
								<p
									onClick={() => {
										/* HANDLE FILE UPLOAD */
										document.getElementById("argumentFile")?.click();
									}}
									className="sessionsHomeUploadParagraph"
								>
									oppure carica 📤 un documento
								</p>
							</div>
						</IonPopover>
						<IonPopover
							isOpen={this.state.showConfirmationPopover}
							onDidDismiss={() => {
								this.state.resource.type === 'map' ? this.setState({ isLoadingMap: false }) :
									this.state.resource.type === 'summary' ? this.setState({ isLoadingSummary: false }) :
										this.state.resource.type === 'keywords' ? this.setState({ isLoadingKeywords: false }) :
											this.state.resource.type === 'flashCards' ? this.setState({ isLoadingFlashcards: false }) :
												this.setState({ isLoadingAudiobook: false })
								this.setState({
									resource: {
										uuid: "",
										type: "summary",
										is_present: false,
									},
									showConfirmationPopover: false,
								});
							}}
							className="argumentInputPopover"
						>
							<div className="deleteConfimrationPopoverDiv">
								<p className="deleteConfimrationPopoverTitle">Vuoi davvero rimuovere l'elemento? {/* to localize this */}</p>
								<div className="deleteConfimrationPopoverBtns">
									<IonButton
										className="deleteConfimrationPopoverButton"
										onClick={() => {
											this.setState({ showConfirmationPopover: false });
											deleteSessionElement.call(this);
											console.log(this.state.argument?.uuid)
											setLocalStorageSessionState(true, this.state.argument!.uuid, this.state.resource.type.toLowerCase(), "")
										}}
									>
										Conferma {/* TO LOCALIZE THIS */}
									</IonButton>
									<IonButton
										className="argumentDismissBtn"
										onClick={() => {
											this.setState({ showConfirmationPopover: false })
										}}
									>
										Annulla {/* TO LOCALIZE THIS */}
									</IonButton>
								</div>
							</div>
						</IonPopover>
					</div>
				)}
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetails);
